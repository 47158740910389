<template>
  <v-dialog v-model="verRecuentoPTU" persistent max-width="950">
    <v-card class="rounded-lg">
      <v-card-title class="primary white--text pb-5" :class="$vuetify.breakpoint.name === 'xs' ? 'subtitle-2' : 'subtitle-1'">
        Revisión de días para PTU ({{ $store.getters.getUser['Folio'] }}) 
        <v-spacer></v-spacer>
        <v-icon dark @click="$emit('closeRecuentoPTU')">
          mdi-close-thick
        </v-icon>
      </v-card-title>
      <v-card-text class="backgroud-color-card-ptu pt-5">
        <v-card
          width="100%"
          min-height="80"
          class="neumorphism-card-ptu rounded-xl pa-2"
        >
          <v-data-table
            hide-default-footer
            :headers="headers"
            :items="items"
            dense
            style="background-color: transparent;"
            :loading="loadingData"
            loading-text="Obteniendo información..."
            no-data-text="Sin información registrada."
          >
            <template v-slot:item.dias_ejercicio="{ item }">
              <div class="font-weight-bold">
                {{ item.dias_ejercicio }}
              </div>
            </template>
            <template v-slot:item.total="{ item }">
              <v-chip small class="elevation-0" dark color="pink darken-3">
                {{ item.total }}
              </v-chip>
            </template>
            <template v-slot:item.dias_periodo="{ item }">
              <v-chip small class="elevation-0" dark color="teal darken-1">
                {{ item.dias_periodo }}
              </v-chip>
            </template>
            <template v-slot:item.comentario="{ item }">
                {{ item.comentario == '' ? 'Entra a reparto de PTU.' : item.comentario }}
            </template>
          </v-data-table>
        </v-card>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios'
import { deleteToken } from '../../../../../store/Funciones/funciones';
export default {
  name: "persona-recuento-dias-ptu",
  props: ["verRecuentoPTU"],
  data() {
    return {
      headers: [
        // {text: "Nombre", value: "nombre"},
        {
          text: "Días de ejercicio",
          value: "dias_ejercicio",
          align: "center",
          sortable: false,
        },
        {
          text: "Faltas injustificadas",
          value: "faltas",
          align: "center",
          sortable: false,
        },
        {
          text: "Permisos sin goce",
          value: "permisos_sin_goce",
          align: "center",
          sortable: false,
        },
        {
          text: "Incapacidad",
          value: "incapacidad",
          align: "center",
          sortable: false,
        },
        {
          text: "Permisos con goce",
          value: "permisos_con_goce",
          align: "center",
          sortable: false,
        },
        {
          text: "Total de inasistencias",
          value: "total",
          align: "center",
          sortable: false,
        },
        {
          text: "Días del periodo",
          value: "dias_periodo",
          align: "center",
          sortable: false,
        },
        {
          text: "Comentario",
          value: "comentario",
          align: "center",
          sortable: false,
        },
      ],
      items: [],
      loadingData : true
    };
  },
  computed: {},
  mounted() {this.getReguentoPTU()},
  methods: {
    getReguentoPTU(){
      let params = {
        Opcion: 27,
        token: localStorage.getItem('token')
      }
      axios.post(`${this.$store.getters.getHost}/General/ws-general.php`, params)
      .then((response) => {
        this.loadingData = false;
        if(response.data.status == 'EXPRESSION'){
          deleteToken()
        } else if(response.data.status == 'OK'){
          this.items = response.data.ptu
        } 
      })
    }
  },
};
</script>

<style>
.backgroud-color-card-ptu {
  background-color: #e8e8e8 !important;
  /* border-bottom-left-radius: 22px !important; */
}
.neumorphism-card-ptu {
  border-radius: 22px;
  background: #e0e0e0 !important;
  box-shadow: 10px 10px 20px #bebebe, -10px -10px 20px #ffffff !important;
}
</style>
