import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        data: undefined,
        host: process.env.VUE_APP_API_SERVER,
        hostNode: process.env.VUE_APP_API_NODE_PROD,
        hostProveedor: process.env.VUE_APP_API_ZENTRIC_PROD,
        snackError: 'Error',
        menuList: [],
        menuTree: [],
        isMultisistema: null,
        user: {
            "Id_Usuario": null,
            "Nombre": "Nombre",
            "Correo": "Correo",
            "Nickname": "Bienvenido",
            "Folio": "Folio",
            "EstatusPass": "EstatusPass",
            "IdGrupo": "IdGrupo",
            "Foto": "/assets/img/placeholder_user.svg",
            "Puesto": "Puesto",
            "Departamento": "Departamento",
            "compania": "Compania",
        }
    },
    getters: {
        getSnackError: (state) => state.snackError,
        getUser: (state) => state.user,
        getMenuList: (state) => state.menuList,
        getMenuTree: (state) => state.menuTree,
        getHost: (state) => state.host,
        getHostNode: (state) => state.hostNode,
        getHostProveedor: (state) => state.hostProveedor,
        isMultisistema: (state) => state.isMultisistema
    },
    mutations: {
        setSnackError(state, value) {
            state.snackError = value
        },
        setUser(state, value) {
            state.user = value
        },
        setMenuList(state, value) {
            state.menuList = value
        },
        setMenuTree(state, value) {
            state.menuTree = value
        },
        setMultisistema(state, value) {
            state.isMultisistema = value
        },
        setTest(state, value) {
            state.data = value
        }
    },
    actions: {
        async retrieveMenu({
            commit
        }) {
            try {
                let params = {
                    Opcion: 4,
                    correo: localStorage.getItem("correo"),
                    token: localStorage.getItem('token')
                }

                let response = await axios.post(this.state.host + '/Login/ws-login.php', params)
                commit('setTest', response)
                commit('setMenuList', response.data.msj.modules)
                commit('setMenuTree', response.data.msj.tree)
                commit('setMultisistema', response.data.msj.isMultisistema)
            } catch {
                commit('setSnackError', 'Tu sesión expiró');
            }
        },
        async retrieveUser({
            commit
        }) {
            try {
                let params = {
                    Opcion: 3,
                    token: localStorage.getItem('token')
                }
                await axios.post(this.state.host + '/Login/ws-login.php', params)
                    .then(response => {
                        let user = response.data.datosLogin[0];

                        commit('setUser', user);
                        localStorage.setItem('correo', user["Correo"]);
                        localStorage.setItem('folio', user["Folio"]);
                        localStorage.setItem('GrupoPago', user["GrupoPago"]);
                        localStorage.setItem('gTrabajo', user["gTrabajo"]);

                    }, () => {
                        commit('setSnackError', 'Tu sesión expiró');
                        window.location.replace("/#/Login");
                    })
            } catch {
                commit('setSnackError', 'Tu sesión expiró');
                window.location.replace("/#/Login");
            }
        }
    }
})

Vue.mixin({
    computed: {
        $user: function() {
            return this.$store.state.user;
        },
        $menuList: function() {
            return this.$store.state.menuList;
        },
    },
    mounted: function() {
        if (this.$store && this.$route) {
            if (localStorage.getItem('token') && this.$route.meta.requiresAuth) {
                const missing = (this.$store.state.user['Nickname'] == "Bienvenido");
                if (missing && !this.$store.state.user.loading) {
                    this.$store.state.user.loading = true;
                    this.$store.dispatch("retrieveUser");
                    let lang = localStorage.getItem('lang');
                    this.$i18n.locale = lang;
                    this.$vuetify.lang.current = lang;
                }
            }
        }
    }
});

export default store