<template>
  <v-dialog v-model="dialog" width="800" persistent>
    <v-card class="rounded-lg">
      <v-card-title class="subtitle-1 primary white--text">
        Nos gustaria saber su nivel de satisfacción en la atención que se le
        ofreció
        <v-spacer></v-spacer>
        <v-btn icon dark @click="$emit('closed', 'Cancelar')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-container>
        <v-row class="d-flex justify-center">
          <v-rating
            v-model="rating"
            color="yellow darken-3"
            background-color="grey darken-1"
            size="64"
            hover
          ></v-rating>
        </v-row>
        <v-row class="d-flex justify-center font-italic">
          <div v-if="rating == 1">Malo</div>
          <div v-else-if="rating == 2">Regular</div>
          <div v-else-if="rating == 3">Bien</div>
          <div v-else-if="rating == 4">Muy bien</div>
          <div v-else-if="rating == 5">¡Excelente!</div>
        </v-row>
      </v-container>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="teal darken-1"
          class="rounded-lg elevation-0 font-weight-bold"
          dark
          small
          @click="validar()"
          :loading="loadingTabla"
        >
          Enviar
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-sheet id="snack-bar-error-incidencias">
      <snack-bar-error-incidencias
        :muestraBarra="muestraBarra"
        :texto="textoBarra"
        :color="colorBarra"
        @cierraBarra="muestraBarra = false"
      />
    </v-sheet>
  </v-dialog>
</template>
<script>
import axios from "axios";
import SnackBarErrorIncidencias from "../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";
import { deleteToken } from "../../../../../store/Funciones/funciones";

export default {
  name: "evaluacion-respuesta-aclaracion",
  //   components: {},
  data: () => ({
    muestraBarra: false,
    textoBarra: "",
    colorBarra: "",
    loadingTabla: false,
    respuestaIncidenciaCancelada: "",
    respuestaIncidenciasAutorizadas: "",
    rating: 5,
    rutaBack: "",
  }),
  components: {
    SnackBarErrorIncidencias,
  },
  props: ["dialog", "IDAclaracion", "ruta"],

  mounted() {},
  methods: {
    validar() {
      let params = {};
      if (this.ruta == "inconformidad") {
        params = {
          Opcion: 8,
          token: localStorage.getItem("token"),
          id: this.IDAclaracion,
          rating: this.rating,
        };
        this.rutaBack = "/Nominas/AdmInconformidades/ws-inconformidades.php";
      } else {
        params = {
          Opcion: 16,
          token: localStorage.getItem("token"),
          id: this.IDAclaracion,
          rating: this.rating,
        };
        this.rutaBack = "/nominas/ws-nominas.php";
      }

      this.EnviarEvaluacion(params, this.rutaBack);
    },
    EnviarEvaluacion(params, ruta) {
      this.loadingTabla = true;
      axios
        .post(`${this.$store.getters.getHost}` + ruta, params)
        .then((response) => {
          if (response.data.status.trim() == "OK") {
            this.textoBarra = "Gracias por su evaluación";
            this.colorBarra = "success";
            this.muestraBarra = true;
            this.loadingTabla = false;

            return new Promise((resolve) => {
              setTimeout(() => {
                resolve(this.$emit("closed", "OK"));
              }, 1500);
            });
          } else if (response.data.status.trim() == "ERROR") {
            this.textoBarra = "Algo salió mal";
            this.colorBarra = "error";
            this.muestraBarra = true;
          } else if (response.data.status.trim() == "EXPSESSION") {
            deleteToken();
          }
        })
        .catch(function (e) {
          let result = e.toString().includes("401");
          if (result) {
            window.location.reload();
          } else {
            alert(e);
          }
        });
    },
  },
};
</script>
<style lang="sass" scoped></style>
