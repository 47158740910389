<!-- https://vuetifyjs.com/en/components/navigation-drawers/#mini-variant -->
<template>
  <div>
    <v-navigation-drawer v-model="drawer" id="nav-left-menu" class="rounded-0" color="#fdfdfd" width="350px" temporary
      absolute>
      <!-- User data space -->
      <v-card flat class="rounded-0" color="primary" dark @click="toHomePage">
        <v-list-item class="px-2" link>
          <v-list-item-avatar>
            <v-img :src="$store.getters.getUser['Foto']"></v-img>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              {{ $store.getters.getUser["Nombre"] }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ "Urrea Herramientas Profesionales" }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <p class="text-right caption my-0 mr-1">
          Tiempo restante: {{ formattedTime }}
        </p>
        <!-- <Countdown :time="timeRemaining" @end="tiempoAgotado"></Countdown> -->
      </v-card>

      <v-divider></v-divider>

      <!-- Menu list -->
      <v-card flat class="rounded-0" color="transparent" id="navigation-menu">
        <v-subheader>{{ $t("Navegación") }}</v-subheader>
        <v-list shaped dense id="left-menu" v-if="$store.getters.getMenuTree && !ocultarMenu">
          <HeaderMenuCollapsed v-for="node in $store.getters.getMenuTree.nodes" :key="node.name" :submenu="node"
            :level="0" :url="'/' + node.url" />
        </v-list>
      </v-card>

      <v-bottom-navigation dark flat>
        <v-btn block value="logout" color="primary" @click="cierraSesionUser">
          <span>{{ $t("Salir") }}</span>
          <v-icon>mdi-logout</v-icon>
        </v-btn>
      </v-bottom-navigation>
    </v-navigation-drawer>
    <v-dialog v-model="cierraSesion" max-width="550">
      <v-card class="rounded-lg">
        <v-card-text>
          <p class="text-center font-weight-bold py-5 mb-0" style="font-size: 1.2rem">
            Tu sesíon esta por terminar.
            <br />
            {{ formattedTime }}
          </p>
        </v-card-text>
        <v-card-actions style="display: flex; justify-content: center">
          <v-btn class="rounded-lg" depressed color="teal darken-2" dark @click="updateTokenLife">
            No cerrar sesión
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <formulario-Info-Personal v-if="openAlertaDocumentos" :dialog="openAlertaDocumentos" :mensaje="mensajeDoc"
      :mensajeBtn="mensajeBtn" :datosInfo="datosInfo" @cierraAlertaDocumentos="openAlertaDocumentos = false" />
    <avisogptw v-if="openAvisoGPTW" :dialog="openAvisoGPTW" @close="cerrarAviso">
    </avisogptw>
  </div>
</template>
<script>
import axios from "axios";
import HeaderMenuCollapsed from "./HeaderMenuCollapsed";
import { deleteToken } from "../store/Funciones/funciones";
import formularioInfoPersonal from "../views/generated/General/MiEmpresa/MiDocumentacion/components/formularioInfoPersonal.vue";
import avisogptw from "./AvisoGPTW.vue";
// import Countdown from "vue-simple-countdown";
export default {
  name: "nav-left-menu",
  data() {
    return {
      activeMenu: "",
      menuTree: {},
      drawer: false,
      totalTime: 0, // tiempo total en milisegundos (15 minutos)
      timeRemaining: 0, // tiempo restante en milisegundos
      intervalId: null, // ID del intervalo para detener el temporizador
      timerRunning: false, // indica si el temporizador está en ejecución
      cierraSesion: false,
      datosInfo: [],
      GrupoPago: [2, 3, 4, 5, 6, 7, 10],
      enArreglo: false,
      ocultarMenu: false,
      openAlertaDocumentos: false,
      mensajeDoc: "",
      mensajeBtn: "",

      openAvisoGPTW: false,
    };
  },
  props: [],
  computed: {
    formattedTime() {
      const minutes = Math.floor((this.timeRemaining / 1000 / 60) % 60)
        .toString()
        .padStart(2, "0");
      const seconds = Math.floor((this.timeRemaining / 1000) % 60)
        .toString()
        .padStart(2, "0");
      return `${minutes}m ${seconds}s`;
    },
  },
  watch: {
    timeRemaining() {
      this.verificarTiempo();
    },
  },
  components: {
    HeaderMenuCollapsed,
    formularioInfoPersonal,
    avisogptw,
    // Countdown,
  },
  async mounted() {
    this.activeMenu = this.getActiveMenu();
    this.menuTree = this.$store.getters.getMenuTree;
    this.timeRemaining = await this.getTokenLife();
    // console.log(this.timeRemaining);
    // this.validarGrupoPago();
    this.startTimer();
    this.validarInfoPersonal();
    // this.validarEncuestaGPTW(); //inhabilitada 13 de diciembre 2024
  },
  methods: {
    verificarTiempo() {
      // Verifica si faltan 15 segundos o menos
      if (this.timeRemaining <= 15) {
        // Aquí puedes activar tu diálogo o realizar alguna acción específica.
        console.log("¡Faltan 15 segundos o menos!");
      }
    },
    validarInfoPersonal() {
      let params = {
        Opcion: 20,
        token: localStorage.getItem("token"),
      };
      axios
        .post(
          `${this.$store.getters.getHost}/RelacionesLaborales/DocumentacionEmpleados/ws-carga-documentos.php`,
          params
        )
        .then(async (response) => {
          if (response.data.status == "EXPSESSION") {
            deleteToken();
          } else {
            this.datosInfo = response.data.data;
            if (
              response.data.data["FormCompletado"] == 0 &&
              response.data.data["grupoPago"] == true &&
              response.data.data["vigilante"] == 2
            ) {
              this.openAlertaDocumentos = true;
              this.mensajeDoc =
                "Para poder continuar por favor actualice sus datos y cargue los documentos solicitados";
              this.mensajeBtn = "Actualizar";
            } else if (
              response.data.data["FormCompletado"] > 0 &&
              response.data.data["Pendiente"] > 0 &&
              response.data.data["grupoPago"] == true &&
              response.data.data["vigilante"] == 2
            ) {
              this.openAlertaDocumentos = true;
              this.mensajeDoc =
                "Para poder continuar por favor cargue los documentos solicitados";
              this.mensajeBtn = "Cargar";
            }
          }
        });
    },

    validarEncuestaGPTW() {
      let folio = this.$store.getters.getUser["Folio"];
      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      return new Promise((res) => {
        axios
          .get(
            `${this.$store.getters.getHostNode}/api/get-encuesta-pendiente-gptw/${folio}`,
            {
              headers: headers,
            }
          )
          .then((response) => {
            if (response.data.status == "EXPSESSION") {
              deleteToken();
            } else {
              res(response.data.data);
              this.openAvisoGPTW = response.data.data;
            }
          })
          .finally(() => {
            this.loadingInfo = false;
          });
      });
    },

    cerrarAviso() {
      this.openAvisoGPTW = false;
    },

    getActiveMenu() {
      return this.$route.path.split("/")[1];
    },
    getMinifiedName(text) {
      return text.replace(/[^A-Z]/gm, "");
    },
    getCatalogDict(catalog, key) {
      return dictCatalog[catalog][key];
    },

    startTimer() {
      this.intervalId = setInterval(async () => {
        // console.log(this.timeRemaining)
        this.timeRemaining -= 1000;
        if (this.timeRemaining <= 180000 && this.timeRemaining > 0) {
          this.cierraSesion = true;
        } else if (
          this.timeRemaining === 300000 ||
          this.timeRemaining === 190000
        ) {
          this.timeRemaining = await this.getTokenLife();
        } else if (this.timeRemaining <= 0) {
          this.stopTimer();
          deleteToken();
        }
      }, 1000);
      this.timerRunning = true;
    },

    cierraSesionUser() {
      deleteToken();
    },

    stopTimer() {
      clearInterval(this.intervalId);
      this.timerRunning = false;
    },

    resetTimer() {
      clearInterval(this.intervalId);
      this.timeRemaining = 0;
      this.timeRemaining = this.totalTime;
      this.timerRunning = false;
    },

    getTokenLife() {
      let params = {
        Opcion: 20,
        token: localStorage.getItem("token"),
      };

      return new Promise((res, rej) => {
        axios
          .post(`${this.$store.getters.getHost}/General/ws-general.php`, params)
          .then((response) => {
            if (response.data.status == "OK") {
              res(response.data.tokenLife * 1000);
              // this.startTimer()
            } else {
              rej(0);
            }
          });
      });
    },

    updateTokenLife() {
      let params = {
        Opcion: 21,
        token: localStorage.getItem("token"),
      };

      axios
        .post(`${this.$store.getters.getHost}/General/ws-general.php`, params)
        .then(async (response) => {
          if (response.data.status == "OK") {
            this.timeRemaining = await this.getTokenLife();
            this.cierraSesion = false;
          } else {
            deleteToken();
          }
        });
    },

    toHomePage() {
      let path = this.$store.getters.isMultisistema
        ? "/DHGeneral/Inicio"
        : "Inicio";
      this.$router.push(path);
    },

    tiempoAgotado() {
      deleteToken();
    },
  },
};

const dictCatalog = {
  locations: {
    UHP: "Urrea Herramientas Profesionales",
  },
};
</script>
<style lang="sass">
.v-navigation-drawer__content
  display: flex
  flex-direction: column
  scrollbar-width: none
  &::-webkit-scrollbar
    display: none

#nav-left-menu
  height: 100%
  border-right: 2px solid #eeeeee
  z-index: 11

  .v-img
    background-color: #ffffff

#navigation-menu
  flex-grow: 1
</style>
