<template>
  <v-dialog
    v-model="dialog"
    content-class="fix-border-dialog"
    max-width="400px"
    transition="dialog-bottom-transition"
    persistent
  >
    <v-card class="rounded-xl">
      <v-card-title class="text-subtitle-2 primary white--text">
        Aclaración de nómina
        <v-spacer></v-spacer>
        <v-icon small dark @click="$emit('cierraFormAclaraciones', 'recargar')"
          >mdi-close</v-icon
        >
      </v-card-title>
      <v-card-text class="pt-5">
        <v-form ref="form" v-model="valid" lazy-validation>
          <label class="overline">periodo</label>
          <v-combobox
            v-model="periodo"
            :items="selectNominas"
            label="Seleccione periodo"
            hide-selected
            outlined
            class="rounded-lg"
            clearable
            dense
            small-chips
            item-text="end_date"
            :loading="loadingPeriodos"
          ></v-combobox>
          <label v-if="check == false" class="overline">motivo</label>
          <v-combobox
            v-model="motivo"
            v-if="check == false"
            :items="motivosAclaraciones"
            label="Seleccione el motivo"
            outlined
            class="rounded-lg"
            hide-selected
            clearable
            dense
            small-chips
            item-text="nombre"
            item-value="ID"
          ></v-combobox>
          <v-checkbox
            v-model="check"
            label="Otro"
            v-on:change="validarSeleccion()"
          ></v-checkbox>
          <label v-if="check == true" class="overline">motivo</label>
          <v-text-field
            v-model="nuevoMotivo"
            v-if="check == true"
            label="¿Cúal es el motivo?"
            outlined
            class="rounded-lg"
            dense
            type="text"
            required
          ></v-text-field>
          <label class="overline">comentario</label>
          <v-textarea
            v-model="comentario"
            label="¿Cúal es su duda?"
            dense
            outlined
            class="rounded-lg"
            :rows="2"
            type="text"
            required
          ></v-textarea>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="pa-0">
        <v-btn
          class="rounded-xl rounded-t-0 elevation-0 font-weight-bold"
          x-large
          text
          block
          v-if="comentario && (motivo || nuevoMotivo) && periodo"
          color="teal darken-1"
          dark
          @click="guardarAclaracion()"
          :loading="loading"
        >
          <v-icon left>mdi-check-circle-outline</v-icon> Guardar
        </v-btn>
        <v-btn v-else class="rounded-xl rounded-t-0 elevation-0" x-large block disabled>
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-sheet id="snack-bar-error-incidencias">
      <snack-bar-error-incidencias
        :muestraBarra="muestraBarra"
        :texto="textoBarra"
        :color="colorBarra"
        @cierraBarra="muestraBarra = false"
      />
    </v-sheet>
  </v-dialog>
</template>

<script>
import axios from "axios";
import SnackBarErrorIncidencias from "../../views/generated/General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";
import { deleteToken } from "../../store/Funciones/funciones";
export default {
  name: "aclaraciones-nomina",
  data: () => ({
    textoBarra: "",
    colorBarra: "",
    muestraBarra: false,
    loading: false,
    loadingPeriodos: true,
    valid: true,
    periodo: "",
    motivo: "",
    motivosAclaraciones: [],
    nuevoMotivo: "",
    check: false,
    comentario: "",
    nominas: [],
    selectNominas: [],
  }),
  components: { SnackBarErrorIncidencias },
  props: ["dialog", ""],
  created() {},
  async mounted() {
    this.getMotivosAclaraciones();
    this.selectNominas = await this.getPeriodosZentric();
  },
  methods: {
    guardarAclaracion() {
      if (this.check == true && !this.nuevoMotivo) {
        this.textoBarra = "Escriba nuevo motivo ";
        this.colorBarra = "error";
        this.muestraBarra = true;
      } else {
        let params = {
          Opcion: 10,
          token: localStorage.getItem("token"),
          periodo:
            this.periodo.end_date.substring(0, 4) + "0" + this.periodo.period,
          motivo: this.motivo["ID"],
          nomMotivo: this.motivo["nombre"],
          nuevoMotivo: this.nuevoMotivo,
          comentario: this.comentario,
        };
        this.loading = true;
        axios
          .post(`${this.$store.getters.getHost}/Nominas/ws-nominas.php`, params)
          .then((response) => {
            if (response.data.status == "OK") {
              this.textoBarra = "Se ha registrado correctamente";
              this.colorBarra = "success";
              this.muestraBarra = true;
              this.$refs.form.reset();
              this.check = false;
            } else if (response.data.status == "ERROR") {
              this.textoBarra = "Ha ocurrido un error";
              this.colorBarra = "error";
              this.muestraBarra = true;
            } else if (response.data.status.trim() == "PERIODOVACIO") {
              this.textoBarra = "Seleccione un periodo o recargue la pagina";
              this.colorBarra = "error";
              this.muestraBarra = true;
            } else if (response.data.status.trim() == "RESPONSABLEVACIO") {
              this.textoBarra =
                "Solicita que te asignen un responsable para continuar";
              this.colorBarra = "error";
              this.muestraBarra = true;
            } else if (response.data.status.trim() == "EXISTE") {
              this.textoBarra =
                "Ya existe una aclaración con el periodo seleccionado y sin resolver, espera a que sean resueltos para solicitar una nueva.";
              this.colorBarra = "error";
              this.muestraBarra = true;
            }
            this.loading = false;
          });
      }
    },

    validarSeleccion() {
      if (this.check) {
        this.motivo = "";
      } else {
        this.nuevoMotivo = "";
      }
    },

    getPeriodosZentric() {
      const params = {
        grupoPago: ["1", "8", "9", "10"].includes(
          localStorage.getItem("GrupoPago")
        )
          ? 5704
          : 5709,
      };

      return new Promise((res) => {
        axios
          .post(`${this.$store.getters.getHostNode}/api/getPeriodosGp`, params)
          .then((response) => {
            if (
              response.data.status == "EXPSESSION" ||
              response.data.message === "Token inválido"
            ) {
              deleteToken();
            } else {
              res(response.data.Periodos);
            }
          })
          .finally(() => {
            this.loadingPeriodos = false;
          });
      });
    },

    getNominasTimbradas() {
      let params = {
        Opcion: 4,
        token: localStorage.getItem("token"),
        formulario: 1,
        gPago: localStorage.getItem("GrupoPago"),
      };
      axios
        .post(`${this.$store.getters.getHost}/nominas/ws-nominas.php`, params)
        .then((response) => {
          if (response.data.status.trim() == "EXPSESSION") {
            deleteToken();
          } else {
            var data = response.data.nominasMostrar;
            this.nominas = data;
            this.nominas.map((elemento) => {
              if (elemento["FIN_CADPER"] == "0 Q") {
                this.selectNominas.push({
                  per_fecfin: "Utilidades (PTU)",
                  HIS_KEYPER: elemento["HIS_KEYPER"],
                });
              } else if (elemento["FIN_CADPER"] == "220") {
                this.selectNominas.push({
                  per_fecfin: "Aguinaldo",
                  HIS_KEYPER: elemento["HIS_KEYPER"],
                });
              } else if (elemento["FIN_CADPER"] == "230") {
                this.selectNominas.push({
                  per_fecfin: "Fondo de ahorro",
                  HIS_KEYPER: elemento["HIS_KEYPER"],
                });
              } else {
                this.selectNominas.push({
                  per_fecfin:
                    elemento["per_fecini"] + " AL " + elemento["per_fecfin"],
                  HIS_KEYPER: elemento["HIS_KEYPER"],
                });
              }
            });
          }
        })
        .catch(function(e) {
          let result = e.toString().includes("401");
          if (result) {
            window.location.reload();
          } else {
            alert(e);
          }
        });
    },
    getMotivosAclaraciones() {
      let params = {
        Opcion: 9,
        token: localStorage.getItem("token"),
      };
      axios
        .post(`${this.$store.getters.getHost}/Nominas/ws-nominas.php`, params)
        .then((response) => {
          if (response.data.status == "OK") {
            this.motivosAclaraciones = response.data.data;
          }
        });
    },
  },
};
</script>
