<template>
  <v-dialog
    v-model="dialogCargaArchivos"
    transition="scale-transition"
    content-class="fix-border-dialog"
    persistent
    max-width="800"
    scrollable
  >
    <v-card class="rounded-xl">
      <v-card-title>
        <v-row>
          <v-col cols="11">
            <p style="color: #0068ac" class="text-left font-weight-bold pb-0">
              Documentación requerida
            </p>
          </v-col>
          <v-col class="pa-0" cols="1">
            <div class="text-right">
              <v-icon
                class="mt-0"
                color="pink darken-4"
                @click="$emit('cierraVentana')"
              >
                mdi-close-circle
              </v-icon>
            </div>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-data-table
          dense
          hide-default-footer
          :items="tiposDocs"
          :headers="headers"
          :loading="loadingTable"
          :mobile-breakpoint="0"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <div v-if="estatusColaborador == 3 && item.idDoc != null">
              <v-btn
                color="teal darken-2"
                rounded
                depressed
                small
                dark
                @click="openFile(item)"
              >
                Ver
              </v-btn>
            </div>
            <div v-else-if="estatusColaborador == 3 && item.idDoc == null">
              No hay documentos guardados
            </div>
            <div v-else-if="estatusColaborador != 3">
              <v-btn
                color="primary"
                v-if="item.idDoc == null"
                rounded
                depressed
                small
                dark
                @click="openUploadFile(item.idTipo, item.documento)"
              >
                cargar
              </v-btn>
              <div v-else>
                <v-btn
                  class="mr-1"
                  color="pink darken-4"
                  rounded
                  depressed
                  small
                  dark
                  @click="openUploadFile(item.idTipo, item.documento)"
                >
                  editar
                </v-btn>
                <v-btn
                  color="teal darken-2"
                  rounded
                  depressed
                  small
                  dark
                  @click="openFile(item)"
                >
                  Ver
                </v-btn>
              </div>
              <v-btn
                v-if="item.idTipo == 13"
                color="pink darken-2"
                rounded
                depressed
                small
                text
                @click="dialogActas = true"
              >
                <v-icon small>mdi-help</v-icon>
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-dialog v-model="dialogActas" max-width="700px" persistent>
      <v-card>
        <v-card-title class="text-subtitle-2 primary white--text">
          Acta(s) de nacimiento para su(s) hijo(s).
          <v-spacer></v-spacer>
          <v-icon small dark @click="dialogActas = false">mdi-close</v-icon>
        </v-card-title>
        <v-card-text>
          Se les solicita la carga de estos documentos para los eventos que
          Urrea realiza como: <br />
          *Dia de niños <br />
          *Pequeños genios. <br />
          Favor de subir en un solo archivo las actas de nacimiento en caso de
          ser más de uno.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" dark small @click="dialogActas = false">
            Entendido
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <CargaArchivo
      v-if="muestraUpload"
      :dialogUpload="muestraUpload"
      :tipo="tipoArch"
      :doc="nombreDocumento"
      :empleado="empleado"
      :estatusColaborador="estatusColaborador"
      @cierraUpload="reloadData()"
    />
  </v-dialog>
</template>

<script>
import axios from "axios";
import { deleteToken } from "../../../../../../store/Funciones/funciones";

import CargaArchivo from "./CargaArchivo.vue";

export default {
  name: "muestra-archivos-por-cargar",
  components: { CargaArchivo },
  props: ["dialogCargaArchivos", "emplid", "estatusColaborador", "esAdmin"],
  data() {
    return {
      loadingInfo: true,
      muestraUpload: false,
      loadingTable: true,
      dialogActas: false,
      tipoArch: "",
      nombreDocumento: "",
      tiposDocs: [],
      errors: [],
      headers: [
        {
          text: "ID",
          align: "center",
          sortable: false,
          value: "idTipo",
        },
        {
          text: "Documento",
          align: "left",
          sortable: false,
          value: "documento",
        },
        {
          text: "Acciones",
          align: "left",
          sortable: false,
          value: "actions",
        },
      ],
    };
  },
  computed: {},
  mounted() {
    this.getTipoDocs();
    console.log(this.esAdmin);
  },
  methods: {
    getTipoDocs() {
      this.tiposDocs.length = 0;
      let params = {
        Opcion: 3,
        token: localStorage.getItem("token"),
        empleado: this.emplid,
        estatusColaborador: this.estatusColaborador,
        esAdmin: this.esAdmin,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/RelacionesLaborales/DocumentacionEmpleados/ws-carga-documentos.php`,
          params
        )
        .then((response) => {
          this.loadingTable = false;
          if (response.data.status == "VACIO") {
            this.noFileData = true;
          } else if (response.data.status == "error") {
            this.errors = response.data.detalles;
          } else if (response.data.status == "OK") {
            this.tiposDocs = response.data.docs;
          } else if (response.data.status == "EXPRESSION") {
            deleteToken();
          }
        })
        .catch(function (e) {
          alert("Ocurrió un error: " + e);
        });
    },
    openUploadFile(tipo, archivo) {
      this.tipoArch = tipo;
      this.nombreDocumento = archivo;

      this.empleado = this.emplid;

      this.muestraUpload = true;
    },
    reloadData() {
      this.muestraUpload = false;
      this.tiposDocs.length = 0;
      this.loadingTable = true;
      this.getTipoDocs();
    },
    openFile(item) {
      let params = {
        Opcion: 9,
        token: localStorage.getItem("token"),
        empleado: item.empleado,
        filename: item.archivo,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/RelacionesLaborales/DocumentacionEmpleados/ws-carga-documentos.php`,
          params
        )
        .then((response) => {
          if (response.data.status == "OK") {
            let win = window.open(response.data.file, "Download");
            win.focus();
          } else if (response.data.status == "EXPRESSION") {
            deleteToken();
          }
        })
        .catch(function (e) {
          alert("Ocurrió un error: " + e);
        });
    },
  },
};
</script>
