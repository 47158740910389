<template>
  <v-dialog v-model="dialog" scrollable max-width="500px" persistent>
    <template>
      <v-card>
        <v-toolbar color="primary" dark>
          <v-row>
            <v-col cols="3" class="pt-7">
            <v-img
            :src="require('@/assets/img/chat.png')"
            height="41px"
            width="40px"
            ></v-img>
          </v-col>
            <v-col cols="9" style="margin-top: 5px">
              <h3>¡Hola! </h3>
              <p>Chatea con nosotros</p>
              
            </v-col>
          </v-row>
          <v-spacer> </v-spacer>
              <v-btn text dark @click="$emit('cierraAlu')">
                <v-icon>mdi-close</v-icon>
              </v-btn>
        </v-toolbar>
        <v-card-text>
          <div id="webchat" role="main"></div>
        </v-card-text>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import axios from "axios";
import {deleteToken} from '../store/Funciones/funciones'

export default {
  name: "persona-alu",
  data: () => ({
    user: "",
  }),
  components: {},
  props: ["dialog"],
  created() {
    this.saveMonitoreo();
  },
  mounted() {
    // this.getId();
  },
  methods: {
    saveMonitoreo() {
      let params = {
        Opcion: 10,
        pantalla: "ALU Interno",
        token: localStorage.getItem("token"),
      };
      axios
        .post(`${this.$store.getters.getHost}/General/ws-general.php`, params)
        .then((response) => {
          // console.log("monitoreo guardado. " + response.data)
          console.log(response.data);
        });
    },
    loadALU() {
      window.WebChat.renderWebChat(
        {
          directLine: window.WebChat.createDirectLine({
            // token: "VLL9wVWdpks.w4ocUmH-PgOWGgFWvg3vAh2JLzJ_GfGXCcLTVZFgSTw",
            token: "-CgAToik_7k.xCBOlKxrUojqLoguFuncO6M_nHIDUJBfbYJnLbAeRBo"
          }) /* PRODUCCIÓN */,
          userID: this.user,
          username: this.user,
          locale: "es-MX",
        },
        document.getElementById("webchat")
      );
    },
    getId() {
      let params = {
        Opcion: 9,
        token: localStorage.getItem("token"),
      };
      axios
        .post(`${this.$store.getters.getHost}/General/ws-general.php`, params)
        .then((response) => {
          if (response.data.status == "EXPSESSION") {
            deleteToken();
          } else {
            var data = response.data;
            this.user = data.toString();
            // this.loadALU();
          }
        })
        .catch(function (e) {
          let result = e.toString().includes("401");
          if (result) {
            window.location.reload();
          } else {
            alert(e);
          }
        });
    },
  },
};
</script>