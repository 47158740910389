<template>
  <v-snackbar
    v-model="snackbar"
    absolute
    top
    color="rgba(0,120,180,1)"
    elevation="24"
  >
    {{ text }}
    <template v-slot:action="{ attrs }">
      <v-btn
        color="white"
        text
        v-bind="attrs"
        @click="snackbar = false"
        backgro="blue lighten-4"
      >
        Cerrar
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: "snackbar-loading-process",
  components: {},
  props: ["text", "snackbar"],
  methods: {},
};
</script>