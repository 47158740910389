<!-- https://vuetifyjs.com/en/components/dialogs/ -->
<template>

  <v-dialog
    id="notices-dialog"
    v-model="dialog"
    max-width="80%"
    scrollable
  >
    <!-- persistent -->

    <v-carousel
      v-model="activeSlideIdx"
      :continuous="false"
      :show-arrows="true"
      hide-delimiter-background
      delimiter-icon="mdi-minus"
      height="100%"
    >

      <template v-slot:prev="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
        >Ver anterior</v-btn>
      </template>
      <template v-slot:next="{ on, attrs }">
        <v-btn
          color="primary"
          v-bind="attrs"
          v-on="on"
        >
          Ver siguiente
        </v-btn>
      </template>

      <v-carousel-item
        v-for="(slide, i) in noticias"
        :key="i"
      >

        <v-card
          class="d-flex flex-column"
          height="100%"
          tile>
          <template slot="progress">
            <v-progress-linear
              color="deep-purple"
              height="10"
              indeterminate
            ></v-progress-linear>
          </template>

          <v-img
            height="50vh"
            :src="'https://picsum.photos/110'+i+'/300'"
          ></v-img>

          <div>
            <div class="text-overline ms-4 my-4">{{slide.tipo}}</div>
              <v-divider class="mx-4"></v-divider>
            <v-card-title>{{slide.titulo}}</v-card-title>

            <v-card-text>
              <v-row
                align="center"
                class="mx-0"
              >
                <div class="grey--text ms-4">
                  {{slide.subtitulo}}
                </div>
              </v-row>
              <div class="my-4 text-subtitle-1">
                {{slide.fechaEvento}}
              </div>
              
              <div>Lorem ipsum dolor sit amet consectetur adipisicing elit. Possimus nihil dicta quis quae doloribus suscipit, nam illum facilis culpa atque sunt quos corporis consectetur qui dolorum accusamus fuga? Consectetur, perferendis. Lorem ipsum dolor sit amet consectetur adip. Lorem ipsum dolor sit amet consectetur.</div>
            </v-card-text>

            <v-card-actions>
              <v-btn v-if="slide.link"
                color="primary"
                text
                :href="slide.link"
                target="_blank"
              >
                {{slide.linkTitle}}
                {{activeSlideIdx}} / {{noticias.length}}
              </v-btn>
              <v-btn v-if="(activeSlideIdx+1 >= noticias.length)"
                color="primary"
                dialog = false
                @click="dialog = false"
              >
                {{ 'Ir al portal' }}
              </v-btn>
            </v-card-actions>
          </div>

        </v-card>
      </v-carousel-item>

    </v-carousel>
  </v-dialog>
</template>
<script>
export default {
  name: 'persona-noticias-dialog',
  data: () => ({
    dialog: false,
    activeSlideIdx: 0
  }),
  props: {
    'dialogVisible': Boolean,
    'noticias': { type: Array },
  },
  computed: {
    // intDialogVisible: () => this.dialog = this.dialogVisible
  },
  components: {
    
  },
  mounted() {
  },
  methods: {

  }
}

</script>
<style lang="sass">

  .v-dialog--scrollable

    .text-overline.ms-4.my-4
      margin-bottom: 0 !important

</style>
