<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      hide-overlay
      persistent
      min-width="300"
      max-width="550"
    >
      <v-card class="background rounded-lg" dark>
        <v-card-text>
          <p class="text-center" style="font-weight: bolder;">{{ text }}</p>
          <v-progress-linear
            indeterminate
            height="5"
            striped
            color="white"
            class="rounded-pill mt-2 mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "loading-bar",
  components: {},
  props: ["text", "dialog"],
  methods: {},
};
</script>

<style>
.background {
  background: linear-gradient(90deg, rgb(0, 120, 195), #ffff) !important;
  background-size: 400% 400% !important;
  animation: anim 2.5s infinite ease-in-out !important;
}

@keyframes anim {
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}
</style>
