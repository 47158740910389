<template>
  <div>
    <v-dialog v-model="dialog" max-width="450px" persistent scrollable content-class="fix-border-dialog">
      <v-card :loading="loadingWindow" class="rounded-xl">
        <v-card-title v-show="!loadingWindow" class="subtitle-2 primary white--text">
          <h3 class="overline">Mi nómina {{ currentYear }}</h3>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="$emit('cierraMiNomina')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <persona-mi-nomina-zentric v-if="openMiNominaZentric" @cargandopdf="cargandopdf" @numNominas="numNominas" />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="px-5 py-3 center-item-in-div-column">
              <v-btn class="rounded-lg elevation-0 mb-2" color="teal darken-1" dark block outlined
                @click="openAdmonAclaraciones = true" :loading="loading">
                <v-icon left>mdi-eye</v-icon>
                Ver mis aclaraciones
              </v-btn>
              <v-btn class="rounded-lg elevation-0 ml-0" color="teal darken-1" dark block
                @click="openFormAclaraciones = true" :loading="loading">
                <v-icon left>mdi-plus</v-icon>
                Solicitar aclaración
              </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <loading-bar :dialog="muestraLoading" text="Calculando nómina, un momento..." />

    <mi-nomina-form-aclaraciones v-if="openFormAclaraciones" :dialog="openFormAclaraciones"
      @cierraFormAclaraciones="openFormAclaraciones = false" />
    <mi-nomina-admon-aclaraciones v-if="openAdmonAclaraciones" :dialog="openAdmonAclaraciones"
      @cierraAdmonAclaraciones="openAdmonAclaraciones = false" />
  </div>
</template>

<script>
import axios from "axios";
import jsPDF from "jspdf";
import "jspdf-autotable";
import loadingBar from "../views/generated/DesarrolloOrganizacional/components/loadingBar.vue";
import MiNominaFormAclaraciones from "./componentsNomina/MiNominaFormAclaraciones.vue";
import MiNominaAdmonAclaraciones from "./componentsNomina/MiNominaAdmonAclaraciones.vue";
import PersonaMiNominaZentric from "./PersonaMiNominaZentric.vue";
import { deleteToken } from "../store/Funciones/funciones";

export default {
  name: "persona-alu",
  data: () => ({
    openMiNominaZentric: true,
    numNominasZentric: null,
    muestraLoading: false,
    loadingWindow: false,
    periodoActual: "",
    openFormAclaraciones: false,
    openAdmonAclaraciones: false,
    loading: false,
  }),
  components: {
    loadingBar,
    MiNominaFormAclaraciones,
    MiNominaAdmonAclaraciones,
    PersonaMiNominaZentric,
  },
  props: ["dialog"],
  created() {
    this.saveMonitoreo();
  },
  mounted() {
    this.currentYear = this.setCurrentYear();
  },
  methods: {
    saveMonitoreo() {
      let params = {
        Opcion: 10,
        pantalla: "Inicio portal DH 2.0",
        token: localStorage.getItem("token"),
      };
      axios
        .post(`${this.$store.getters.getHost}/General/ws-general.php`, params)
        .then((response) => {
          console.log(response.data);
        });
    },
    getPDF() {
      this.muestraLoading = false;
      var doc = new jsPDF({
        format: "government-letter",
        unit: "mm",
        orientation: "l",
      });

      doc.setFontSize(8);
      doc.setTextColor(0, 85, 136);
      doc.setFont("helvetica");
      doc.setTextColor(255, 0, 0);
      doc.text(10, 7, "*Recibo informativo favor de acudir a firmar la nómina");
      doc.text(220, 7, "RECIBO DE NÓMINA");
      doc.text(120, 185, "EMPRESA");

      doc.autoTable(
        {
          head: this.headersFilaDatosEmpresa,
          body: this.dataFilaDatosEmpleado,
          startY: 10,
          styles: {
            fontSize: 5,
          },
          headStyles: this.head_styles,
          bodyStyles: this.body_styles,
        }

        // { margin: { top: 25 } }
      );

      doc.autoTable(
        {
          head: this.headersFilaDatosEmpleado,
          body: this.dataFilaDatosPeriodo,
          startY: 20,
          styles: {
            fontSize: 5,
          },
          headStyles: this.head_styles,
          bodyStyles: this.body_styles,
        }
        // { margin: { top: 25 } }
      );

      doc.autoTable({
        head: this.headersConceptosDePago,
        body: this.matrixDeduccionesPercepciones,
        startY: 30,
        styles: {
          fontSize: 5,
        },
        headStyles: this.head_styles,
        bodyStyles: this.body_styles,
      });

      doc.autoTable({
        head: this.headersTotales,
        body: this.bodyTotales,
        startY: this.matrixDeduccionesPercepciones.length * 5 + 53,
        styles: {
          fontSize: 5,
        },
        headStyles: this.head_styles,
        bodyStyles: this.body_styles,
      });

      var bodySeis = [
        [
          { content: "DENOMINACION", rowSpan: 4 },
          { content: 200 },
          { content: 10 },
          { content: 50 },
          { content: 20 },
          { content: 10 },
          { content: 5 },
          { content: "RECIBÍ DE:" },
          {
            content: "__________________________________________________",
            rowSpan: 3,
          },
        ],
        [
          { content: "" },
          { content: "" },
          { content: "" },
          { content: "" },
          { content: "" },
          { content: "" },
          {
            content:
              "LA CANTIDAD INDICADA QUE CUBRE A LA FECHA EL IMPORTE DE MI SALARIO, SEPTIMO DIA, TIEMPO EXTRA Y TODAS LAS PERCEPCIONES Y PRESTACIONES A QUE TENGO DERECHO SIN QUE SE ME ADEUDE CANTIDAD ALGUNA POR OTRO CONCEP",
            rowSpan: 3,
          },
          { content: "Firma del trabajador" },
        ],
        [
          { content: 2 },
          { content: 1 },
          { content: 0.5 },
          { content: 0.2 },
          { content: 0.1 },
          { content: 0.05 },
        ],
        [
          { content: "" },
          { content: "" },
          { content: "" },
          { content: "" },
          { content: "" },
          { content: "" },
          { content: "Firma del trabajador" },
        ],
      ];

      doc.autoTable({
        body: bodySeis,
        startY: this.matrixDeduccionesPercepciones.length * 5 + 15 + 53,
        styles: {
          fontSize: 5,
        },
        headStyles: this.head_styles,
        bodyStyles: this.body_styles,
        columnStyles: {
          8: {
            valign: "bottom",
            cellWidth: 60,
            fontStyle: "bold",
            halign: "center",
          },
        },
      });

      doc.save("nomina-" + this.periodoActual + ".pdf");
      doc = null;
      this.limpiaVariables();
    },
    getNominasTimbradas() {
      let params = {
        Opcion: 4,
        token: localStorage.getItem("token"),
        gPago: localStorage.getItem("GrupoPago"),
      };
      axios
        .post(`${this.$store.getters.getHost}/nominas/ws-nominas.php`, params)
        .then((response) => {
          if (response.data.status.trim() == "EXPSESSION") {
            deleteToken();
          } else {
            this.openMiNominaZentric = true;
            var data = response.data.nominasMostrar;
            this.nominas = data;
            this.loadingWindow = false;
            this.loading = false;
          }
          // this.validaRespuestaModificacion();
        })
        .catch(function (e) {
          let result = e.toString().includes("401");
          if (result) {
            window.location.reload();
          } else {
            alert(e);
          }
        });
    },
    limpiaVariables() {
      this.responseUserDataNomina.length = 0;
      this.dataFilaDatosEmpleado.length = 0;
      this.dataFilaDatosPeriodo.length = 0;
      this.responsePeriodDataNomina.length = 0;
      this.responsePercepciones.length = 0;
      this.responseDeducciones.length = 0;
      this.responseTotalesDias.length = 0;
      this.matrixDeduccionesPercepciones.length = 0;
      this.bodyTotales.length = 0;
      this.periodoActual = "";
      this.efectivo = 0;
      this.totalPercepciones = 0;
      this.totalDeducciones = 0;
    },
    getNominaPDF(periodo) {
      // alert("alto");
      if (!("existePs" in periodo)) {
        this.textoLoading = "Estámos cargando tu información...";
        this.muestraLoading = true;
        this.periodoActual = periodo.HIS_KEYPER;
        this.getUserDataNom(periodo);
      } else {
        if (periodo.existePs <= 0 && periodo.existeDh <= 0) {
          alert(
            "Lo sentimos, la prenomina del periodo " +
            periodo.HIS_KEYPER +
            " aún no está disponible, si no hasta el día " +
            periodo.disponible +
            "."
          );
        }
        this.textoLoading = "Cargando tu información de prenómina...";
        this.muestraLoading = true;
        this.periodoActual = periodo.HIS_KEYPER;
        this.getUserDataNom(periodo);
      }
    },
    setCurrentYear() {
      var currentTime = new Date();
      var year = currentTime.getFullYear();
      return year;
    },
    getUserDataNom(periodo) {
      let params = {
        Opcion: 2,
        token: localStorage.getItem("token"),
        periodo: this.periodoActual,
      };
      axios
        .post(`${this.$store.getters.getHost}/nominas/ws-nominas.php`, params)
        .then((response) => {
          var data = response.data.dataEmp;
          this.responseUserDataNomina = data;
          this.llenaDatosTabla(1, periodo);
        })
        .catch(function (e) {
          let result = e.toString().includes("401");
          if (result) {
            window.location.reload();
          } else {
            alert(e);
          }
        });
    },
    getFechasPeriodo(periodo) {
      let params = {
        Opcion: 3,
        token: localStorage.getItem("token"),
        periodo: this.periodoActual,
      };
      axios
        .post(`${this.$store.getters.getHost}/nominas/ws-nominas.php`, params)
        .then((response) => {
          var data = response.data.datosPeriodo;
          this.responsePeriodDataNomina = data;
          this.llenaDatosTabla(2, periodo);
        })
        .catch(function (e) {
          let result = e.toString().includes("401");
          if (result) {
            window.location.reload();
          } else {
            alert(e);
          }
        });
    },
    getPercepciones(periodo) {
      let params =
        "existePs" in periodo
          ? {
            Opcion: 5,
            token: localStorage.getItem("token"),
            periodo: this.periodoActual,
            existePs: periodo.existePs,
            existeDh: periodo.existeDh,
          }
          : {
            Opcion: 5,
            token: localStorage.getItem("token"),
            periodo: this.periodoActual,
          };
      axios
        .post(`${this.$store.getters.getHost}/nominas/ws-nominas.php`, params)
        .then((response) => {
          var data = response.data.dataPercepciones;
          this.responsePercepciones = data;
          this.llenaDatosTabla(3, periodo);
        })
        .catch(function (e) {
          let result = e.toString().includes("401");
          if (result) {
            window.location.reload();
          } else {
            alert(e);
          }
        });
    },
    getDeducciones(periodo) {
      let params =
        "existePs" in periodo
          ? {
            Opcion: 8,
            token: localStorage.getItem("token"),
            periodo: this.periodoActual,
            existePs: periodo.existePs,
            existeDh: periodo.existeDh,
          }
          : {
            Opcion: 8,
            token: localStorage.getItem("token"),
            periodo: this.periodoActual,
          };
      axios
        .post(`${this.$store.getters.getHost}/nominas/ws-nominas.php`, params)
        .then((response) => {
          var data = response.data.dataDeducciones;
          this.responseDeducciones = data;
          this.llenaDatosTabla(4, periodo);
        })
        .catch(function (e) {
          let result = e.toString().includes("401");
          if (result) {
            window.location.reload();
          } else {
            alert(e);
          }
        });
    },
    getDiasCotizados(periodo) {
      let params =
        "existePs" in periodo
          ? {
            Opcion: 6,
            token: localStorage.getItem("token"),
            periodo: this.periodoActual,
            existePs: periodo.existePs,
            existeDh: periodo.existeDh,
          }
          : {
            Opcion: 6,
            token: localStorage.getItem("token"),
            periodo: this.periodoActual,
          };
      axios
        .post(`${this.$store.getters.getHost}/nominas/ws-nominas.php`, params)
        .then((response) => {
          var data = response.data.dataDiasCotizados;
          this.responseTotalesDias = data;

          this.getTotalEfectivo(periodo);
        })
        .catch(function (e) {
          let result = e.toString().includes("401");
          if (result) {
            window.location.reload();
          } else {
            alert(e);
          }
        });
    },
    llenaDatosTabla(opc, periodo) {
      switch (opc) {
        case 1:
          var arrayAux = [
            99,
            1,
            this.responseUserDataNomina["IMS_RAZSOC"],
            this.responseUserDataNomina["EMP_NOMEMP"],
            this.responseUserDataNomina["EMP_REGRFC"],
          ];
          this.dataFilaDatosEmpleado.push(arrayAux);
          this.getFechasPeriodo(periodo);
          break;
        case 2:
          var arrayAux2 = [
            "04",
            this.responseUserDataNomina["EMP_KEYDEP"],
            this.responseUserDataNomina["EMP_KEYEMP"],
            "",
            "",
            parseFloat(this.responseUserDataNomina["EMP_SALDIA"]).toFixed(3),
            "",
            "",
            this.responsePeriodDataNomina[0]["NUMPER"],
            this.responsePeriodDataNomina[0]["PER_FECINI"],
            this.responsePeriodDataNomina[0]["PER_FECFIN"],
            this.responseUserDataNomina["EMP_REGIMS"],
          ];
          this.dataFilaDatosPeriodo.push(arrayAux2);
          this.getPercepciones(periodo);
          break;
        case 3:
          for (var x = 0; x < this.responsePercepciones.length; x++) {
            this.matrixDeduccionesPercepciones.push([
              "",
              "",
              "",
              "",
              "",
              "",
              "",
            ]);
            for (var y = 0; y < 3; y++) {
              if (y === 0)
                this.matrixDeduccionesPercepciones[x][y] =
                  this.responsePercepciones[x]["con_keycon"];
              else if (y === 1)
                this.matrixDeduccionesPercepciones[x][y] =
                  this.responsePercepciones[x]["con_descon"];
              else if (y === 2)
                this.matrixDeduccionesPercepciones[x][y] =
                  new Intl.NumberFormat("es-MX", {
                    style: "currency",
                    currency: "MXN",
                  }).format(
                    Number.parseFloat(
                      this.responsePercepciones[x]["his_import"],
                      10
                    )
                  );
            }
          }
          this.getDeducciones(periodo);
          break;
        case 4:
          for (var i = 0; i < this.responseDeducciones.length; i++) {
            if (this.matrixDeduccionesPercepciones[i] == undefined)
              this.matrixDeduccionesPercepciones.push([
                "",
                "",
                "",
                "",
                "",
                "",
                "",
              ]);
            for (var j = 3; j < 7; j++) {
              if (j === 3)
                this.matrixDeduccionesPercepciones[i][j] =
                  this.responseDeducciones[i]["clave"];
              else if (j === 4)
                this.matrixDeduccionesPercepciones[i][j] =
                  this.responseDeducciones[i]["concepto"];
              else if (j === 5)
                this.matrixDeduccionesPercepciones[i][j] =
                  new Intl.NumberFormat("es-MX", {
                    style: "currency",
                    currency: "MXN",
                  }).format(
                    Number.parseFloat(this.responseDeducciones[i]["monto"], 10)
                  );
              else if (j === 6)
                this.matrixDeduccionesPercepciones[i][j] =
                  new Intl.NumberFormat("es-MX", {
                    style: "currency",
                    currency: "MXN",
                  }).format(
                    Number.parseFloat(this.responseDeducciones[i]["saldo"], 10)
                  );
            }
          }
          this.getDiasCotizados(periodo);
          break;
        case 5:
          var auxTotales = [
            { content: this.responseTotalesDias["701"] },
            { content: this.responseTotalesDias["702"] },
            { content: this.responseTotalesDias["703"] },
            { content: this.responseTotalesDias["704"] },
            { content: this.responseTotalesDias["705"] },
            { content: this.getTotalPercepciones() },
            { content: this.getTotalDeducciones() },
            { content: this.getTotalNeto() },
            { content: this.getDespensa() },
            {
              content: new Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(Number.parseFloat(this.efectivo)),
            },
          ];
          this.bodyTotales.push(auxTotales);
          this.getPDF();
          break;
      }
    },
    getTotalPercepciones() {
      this.responsePercepciones.forEach((element) => {
        this.totalPercepciones =
          this.totalPercepciones + Number.parseFloat(element["his_import"]);
      });
      let result = new Intl.NumberFormat("es-MX", {
        style: "currency",
        currency: "MXN",
      }).format(this.totalPercepciones);
      return result;
    },
    getTotalDeducciones() {
      this.responseDeducciones.forEach((element) => {
        this.totalDeducciones =
          this.totalDeducciones + Number.parseFloat(element["monto"]);
      });
      return new Intl.NumberFormat("es-MX", {
        style: "currency",
        currency: "MXN",
      }).format(this.totalDeducciones);
    },
    getTotalNeto() {
      let total = this.totalPercepciones - this.totalDeducciones;
      return new Intl.NumberFormat("es-MX", {
        style: "currency",
        currency: "MXN",
      }).format(total);
    },
    getDespensa() {
      let despensa = 0;
      this.responsePercepciones.forEach((element) => {
        if (element["con_keycon"] == 282) {
          despensa = Number.parseFloat(element["his_import"]);
        }
      });

      return new Intl.NumberFormat("es-MX", {
        style: "currency",
        currency: "MXN",
      }).format(despensa);
    },

    getTotalEfectivo(periodo) {
      let params =
        "existePs" in periodo
          ? {
            Opcion: 7,
            token: localStorage.getItem("token"),
            periodo: this.periodoActual,
            existePs: periodo.existePs,
          }
          : {
            Opcion: 7,
            token: localStorage.getItem("token"),
            periodo: this.periodoActual,
          };
      axios
        .post(`${this.$store.getters.getHost}/nominas/ws-nominas.php`, params)
        .then((response) => {
          var data = response.data.dataTotalEfectivo[0].his_import;
          //(data);
          this.efectivo = data;
          this.llenaDatosTabla(5, periodo);
        })
        .catch(function (e) {
          let result = e.toString().includes("401");
          if (result) {
            window.location.reload();
          } else {
            alert(e);
          }
        });
    },

    cargandopdf(valor) {
      this.muestraLoading = valor;
    },
    numNominas(valor) {
      this.numNominasZentric = valor;
    },
  },
};
</script>
