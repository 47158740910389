<!-- https://vuetifyjs.com/en/components/snackbars/#variants -->
<template>
  <v-snackbar
    v-model="snackbar"
    timeout="3000"
    color="error"
    shaped
  >
    {{ $store.getters.getSnackError }}
    <template v-slot:action="{ attrs }">
      <v-btn
        text
        v-bind="attrs"
        @click="snackbar = false"
      >
        Cerrar
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import store from './../store/index';
export default  {
  name: 'error-snackbar',
  data: () => ({
    snackbar: false
  }),
  mounted() {
    this.onEror()
  },
  methods: {
    onEror() {
      store.subscribe((mutation) => {
        if(mutation.type === "setSnackError" && this.snackbar == false) {
          this.snackbar = true;
        }
      });
    }
  }
}
</script>