<template>
  <v-container>
    <v-row class="mt-5">
      <v-col class="text-center" cols="12">
        <h1 class="text-h2 font-weight-thin mb-4">
          <v-img
            :src="require('@/assets/img/en-mantenimiento.jpg')"
            max-height="150"
            max-width="250"
            class="mx-auto"
          ></v-img>
        </h1>
        <h1 class="text-h4 font-weight-thin mb-4">¡Nos descubriste...!</h1>
        <h4 class="subheading">
          En estos momentos estamos trabajando en este módulo.
        </h4>
        <h5 class="subheading">
          Pero descuida, lo habilitaremos lo mas pronto posible.
          <v-icon>mdi-emoticon-excited-outline</v-icon>
        </h5>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: "mantenimiento-modulo",
  components: {},
  props: [],
  data() {
    return {};
  },
  computed: {},
  mounted() {},
};
</script>