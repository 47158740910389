<template>
<h1>
    hola mundo
</h1>
</template>

<script>
import axios from "axios";
import { formatDate } from "../../../../../store/Funciones/funciones";
//import DialogComentarioRechazo from "../../../AdmnVacantes/components/DialogComentarioRechazo.vue";
//import ConfirmDialogReclutamiento from "../../../AdmnVacantes/components/ConfirmDialogReclutamiento.vue";
//import SnackBar from "../../MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";

export default {
  name: "persona-autoriza-tiempoextra",
  props: ["showAuthTE"],
  components: {
   // DialogComentarioRechazo,
    //ConfirmDialogReclutamiento,
    //SnackBar,
  },
  data() {
    return {
      headers: [
        {
          value: "id",
          text: "id",
          class:
            "text-uppercase font-weight-bold blue-grey--text text--darken-1",
        },
        {
          value: "Nombre",
          text: "Nombre",
          class:
            "text-uppercase font-weight-bold blue-grey--text text--darken-1",
        },
        {
          value: "Razon",
          text: "Razon",
          class:
            "text-uppercase font-weight-bold blue-grey--text text--darken-1",
        },
        {
          value: "Emplid",
          align: "center",
          text: "Solicitante",
          class:
            "text-uppercase font-weight-bold blue-grey--text text--darken-1",
        },
        {
          value: "actions",
          align: "center",
          text: "",
          class:
            "text-uppercase font-weight-bold blue-grey--text text--darken-1",
        },
      ],
      expanded: [],
      visitas: [],
      detalleVacante: {},
      loadingTabla: true,
      muestraDialogComentarioRechazo: false,
      showConfirm: false,
      loadingDetalle: false,
      muestraDetalle: false,
      muestraBarra: false,
      question: "¿Realmente deseas autorizar esta visita?",
      colorBarra: "",
      textoBarra: "",
      comentarioAux: "",
    };
  },
  async mounted() {
    this.visitas = await this.getVisitasPendientesPorAutrizar();
  },
  methods: {
    fixDate(date) {
      return formatDate(date);
    },
    muestraSnackBar(texto, color) {
      this.colorBarra = color;
      this.textoBarra = texto;
      this.muestraBarra = true;
    },
    async getVisitasPendientesPorAutrizar() {
      const params = {
        Opcion: 56,
        token: localStorage.getItem("token"),
      };

      return new Promise((resolve, reject) => {
        axios
          .post(
            `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-RegistroVisitantes.php`,
            params
          )
          .then((response) => {
            if (response.data.status == "OK") {
              resolve(response.data.respuesta);
            } else {
              resolve([]);
            }
          })
          .catch((error) => {
            reject(error);
          })
          .finally(() => {
            this.loadingTabla = false;
          });
      });
    },
    openDetails(id) {
      const params = {
        Opcion: 4,
        token: localStorage.getItem("token"),
        id: id,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/Reclutamiento/ws-admin-reclu.php`,
          params
        )
        .then((response) => {
          if (response.data.status != "EXPSESSION") {
            this.detalleVacante = response.data.detalle;
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loadingDetalle = false;
        });
    },
    formatMXN(cadena) {
      return cadena.toLocaleString("es-MX", {
        style: "currency",
        currency: "MXN",
      });
    },
    ocultaDialogComentarioRechazo(comentario) {
      this.muestraDialogComentarioRechazo = false;
      if (comentario != null) {
        this.loadingTabla = true;
        this.setEstatusVisitante(comentario);
      }
    },
    openReject(item) {
      (this.tokenAux = item.token),
        (this.idAux = item.id),
        (this.estatusAux = 2);
      this.muestraDialogComentarioRechazo = true;
    },
    openConfirm(item) {
      (this.tokenAux = item.token),
        (this.idAux = item.id),
        (this.estatusAux = 1);
      this.showConfirm = true;
    },
    cierraConfirm(respuesta) {
      if (respuesta) {
        this.showConfirm = false;
        this.loadingTabla = true;
        this.setEstatusVisitante();
      } else {
        this.showConfirm = false;
      }
    },
    setEstatusVisitante(comentario = "") {
      let params = {
        Opcion: 39,
        token: localStorage.getItem('token'),
        token_visita: this.tokenAux,
        idVisita: this.idAux,
        estatus: this.estatusAux,
        comentario: comentario,
        password: this.tokenAux,
        esAdmin: true,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-RegistroVisitantes.php`,
          params
        )
        .then(async (response) => {
          if (response.data.status != "EXPSESSION") {
            if (response.data.respuesta.status_tran == "OK") {
              this.muestraSnackBar(response.data.respuesta.message, "primary");
              this.visitas = await this.getVisitasPendientesPorAutrizar();
              if (this.visitas.length == 0) {
                this.$emit("validaEstadoVis", 0);
              }
            } else {
              this.muestraSnackBar(
                response.data.respuesta.message,
                "pink darken-3"
              );
            }
          } else {
            this.muestraSnackBar("ERROR. No se pudo validar", "pink darken-3");
          }
        })
        .catch((e) => {
          this.muestraSnackBar(e, "pink darken-3");
        })
        .finally(() => {
          this.loadingTabla = false;
        });
    },
  },
};
</script>