import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import axios from 'axios'
import VueAnalytics from 'vue-analytics'

import '@babel/polyfill'
import 'mutationobserver-shim'

Vue.config.productionTip = false;

Vue.use(VueAnalytics, {
    id: '',
    router
})

import {
    initialize,
    errors
} from './helpers/RequestInterceptor';

initialize(axios, store);
errors(axios, store);

new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: h => h(App)
}).$mount('#app')