<template>
  <v-dialog
    v-if="ruta == 'notificacion' && !moduloError"
    v-model="dialog"
    fullscreen
    transition="dialog-bottom-transition"
    persistent
  >
    <v-card>
      <v-card-title class="text-subtitle-2 primary white--text">
        Lista de solicitudes realizadas
        <v-spacer></v-spacer>
        <v-icon
          small
          dark
          @click="$emit('cierraReporteInconformidades', 'cancelar')"
          >mdi-close</v-icon
        >
      </v-card-title>
      <v-card-text class="pt-5">
        <v-data-table
          :headers="headers"
          :items="inconformidades"
          :search="buscar"
          :loading="loadingInfo"
          loading-text="Cargando solicitudes"
          class="elevation-1"
          dense
          mobile-breakpoint="0"
          :footer-props="{ 'items-per-page-text': 'Elementos por página' }"
          no-data-text="No se encontraron solicitudes"
          item-key="id"
        >
          <template v-slot:top>
            <v-toolbar flat class="rounded-lg rounded-b-0 rounded-t-0">
              <v-text-field
                v-model="buscar"
                :label="$t('DH360.findTextBox')"
                class="mt-7 rounded-lg"
                dense
                clearable
                outlined
                solo-inverted
                prepend-inner-icon="mdi-table-search"
              ></v-text-field>
              <v-spacer></v-spacer>
            </v-toolbar>
          </template>

          <template v-slot:item.nombre="{ item }">
            <div align="left">{{ item.nombre }}</div>
          </template>
          <template v-slot:item.diasTranscurridos="{ item }">
            <div v-if="item.diasTranscurridos > 1">
              {{ item.diasTranscurridos }} Días
            </div>
            <div v-else-if="item.diasTranscurridos == 1">
              {{ item.diasTranscurridos }} Día
            </div>
            <div v-else-if="item.diasTranscurridos == 0">Hoy</div>
          </template>
          <template v-slot:item.motivo="{ item }">
            <div align="left">{{ item.motivo }}</div>
          </template>
          <template v-slot:item.departamento="{ item }">
            <div align="left">{{ item.departamento }}</div>
          </template>
          <template v-slot:item.comentario="{ item }">
            <div
              v-if="item.muestraDesc == false"
              align="left"
              class="text-truncate"
              style="max-width: 200px; cursor: pointer"
              @click="mostrarMas(item)"
            >
              {{ item.comentario }}
            </div>
            <div
              v-else
              align="left"
              @click="mostrarMas(item)"
              style="cursor: pointer"
              class="text-justify"
            >
              {{ item.comentario }}
            </div>
          </template>
          <template v-slot:item.estatus="{ item }">
            <div v-if="item.estatus == 1" class="d-flex justify-center">
              <v-chip-group column>
                <v-btn x-small rounded elevation="1" color="red" dark>
                  Abierta
                </v-btn>
              </v-chip-group>
            </div>
            <div v-else-if="item.estatus == 2" class="d-flex justify-center">
              <v-chip-group column>
                <v-btn
                  x-small
                  rounded
                  elevation="1"
                  color="light-green darken-1"
                  dark
                >
                  Por calificar
                </v-btn>
              </v-chip-group>
            </div>
            <div v-else-if="item.estatus == 3" class="d-flex justify-center">
              <v-chip-group column>
                <v-btn x-small rounded elevation="1" color="teal darken-1" dark>
                  Cerrada
                </v-btn>
              </v-chip-group>
            </div>
          </template>
          <template v-slot:item.nivelSatisfaccion="{ item }">
            <div class="d-flex justify-center">
              <v-chip-group column v-if="item.estatus == 2">
                <v-btn
                  v-if="
                    item.nivelSatisfaccion == 0 ||
                    item.nivelSatisfaccion == 'Pendiente' ||
                    item.nivelSatisfaccion == null
                  "
                  x-small
                  rounded
                  elevation="1"
                  color="red"
                  dark
                  @click="openEvaluacionDialog(item)"
                >
                  <v-icon x-small>mdi mdi-star-outline</v-icon>
                </v-btn>
                <v-btn
                  v-else
                  x-small
                  rounded
                  elevation="1"
                  color="teal darken-4"
                  dark
                >
                  <v-icon x-small>mdi-star-check</v-icon>
                  {{ item.nivelSatisfaccion }}
                </v-btn>
              </v-chip-group>
              <v-chip-group column v-else>
                <v-btn x-small rounded elevation="1" color="grey darken-1" dark>
                  <v-icon x-small>mdi mdi-star-check</v-icon>
                  <div v-if="item.nivelSatisfaccion != 'Pendiente'">
                    {{ item.nivelSatisfaccion }}
                  </div>
                </v-btn>
              </v-chip-group>
            </div>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-tooltip top color="indigo lighten-4">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="indigo darken-4"
                  v-bind="attrs"
                  v-on="on"
                  @click="dialogDetalles(item)"
                >
                  mdi-details
                </v-icon>
              </template>
              <label class="text-uppercase font-weight-bold white--text"
                >Detalles</label
              >
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
      <v-divider></v-divider>
    </v-card>

    <v-dialog
      v-model="openDetalles"
      max-width="600px"
      transition="dialog-top-transition"
      persistent
    >
      <v-card>
        <v-card-title class="text-subtitle-2 primary white--text">
          Detalles de solicitud
          <v-spacer></v-spacer>
          <v-icon
            small
            dark
            @click="
              openDetalles = false;
              e6 = 1;
            "
            >mdi-close</v-icon
          >
        </v-card-title>
        <v-card-text class="pt-5">
          <v-stepper v-model="e6" vertical>
            <v-stepper-step :complete="e6 > 1" step="1" editable>
              Detalles de solicitud
              <small>Consulta para despues responder</small>
            </v-stepper-step>

            <v-stepper-content step="1">
              <v-card class="pa-5 mb-5 elevation-2">
                <v-row>
                  <v-col cols="12" xs="12" sm="12" md="10" lg="10" xl="10">
                    <div>
                      <label class="font-weight-bold">Colaborador:</label>
                      {{ item.EMPLID }}
                    </div>
                    <div>
                      <label class="font-weight-bold">Nombre:</label>
                      {{ item.nombre }}
                    </div>
                    <div>
                      <label class="font-weight-bold">Motivo:</label>
                      {{ item.motivo }}
                    </div>
                  </v-col>
                  <v-col
                    class="d-flex align-center justify-center"
                    cols="12"
                    xs="12"
                    sm="12"
                    md="2"
                    lg="2"
                    xl="2"
                  >
                    <div>
                      <v-tooltip top color="blue lighten-3">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="blue"
                            dark
                            fab
                            @click="openEvidencia(item, 'evidencia')"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon small>mdi-image-search-outline</v-icon>
                          </v-btn>
                        </template>
                        <span class="white---text"> Ver evidencia </span>
                      </v-tooltip>
                    </div>
                  </v-col>
                </v-row>
              </v-card>
              <v-row>
                <v-col
                  cols="12"
                  xs="12"
                  sm="12"
                  md="12"
                  lg="12"
                  xl="12"
                  class="d-flex justify-space-around mb-5"
                >
                  <div align="center">
                    <label class="font-weight-bold">Fecha solicitud:</label
                    ><br />
                    {{ item.fechaInconformidad }}
                  </div>
                  <div align="center">
                    <label class="font-weight-bold">Fecha registro:</label
                    ><br />
                    {{ item.fechaRegistro }}
                  </div>
                </v-col>
              </v-row>
              <v-card class="pa-5 mb-5">
                <v-row>
                  <v-col
                    cols="12"
                    xs="12"
                    sm="12"
                    md="10"
                    lg="10"
                    xl="10"
                    class="text-justify"
                  >
                    <div>
                      <label class="font-weight-bold blue--text"
                        >Comentario:</label
                      >
                      {{ item.comentario }}
                    </div>
                    <div v-if="item.estatus > 1">
                      <label class="font-weight-bold green--text"
                        >Respuesta:</label
                      >
                      {{ item.respuestaAdmon }}
                    </div>
                  </v-col>
                  <v-col
                    class="d-flex align-center justify-center"
                    cols="12"
                    xs="12"
                    sm="12"
                    md="2"
                    lg="2"
                    xl="2"
                  >
                    <div v-if="item.estatus > 1">
                      <v-tooltip top color="green lighten-3">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="green"
                            dark
                            fab
                            @click="openEvidencia(item, 'respuesta')"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon small>mdi-image-search-outline</v-icon>
                          </v-btn>
                        </template>
                        <span class="white---text">
                          Ver evidencia respuesta
                        </span>
                      </v-tooltip>
                    </div>
                  </v-col>
                </v-row>
              </v-card>
            </v-stepper-content>
          </v-stepper>
        </v-card-text>
      </v-card>
    </v-dialog>
    <SnackBar
      v-if="muestraBarra"
      :muestraBarra="muestraBarra"
      :texto="textoBarra"
      :color="colorBarra"
      @cierraBarra="muestraBarra = false"
    />
    <evaluacion-respuesta-aclaracion
      v-if="evaluarRespuestaAclaracion"
      :dialog="evaluarRespuestaAclaracion"
      :IDAclaracion="this.ID"
      ruta="inconformidad"
      @closed="refrescar"
    ></evaluacion-respuesta-aclaracion>
    <inconformidades-evidencia
      v-if="verEvidencia"
      :dialog="verEvidencia"
      :item="item"
      :tipoEvidencia="tipoEvidencia"
      @cierraDialogEvidencia="cerrarDialog"
    ></inconformidades-evidencia>
  </v-dialog>
  <v-container v-else-if="moduloError">
    <mantenimiento-modulo></mantenimiento-modulo>
  </v-container>
</template>

<script>
import axios from "axios";
import SnackBar from "../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";
import { deleteToken } from "../../../../../store/Funciones/funciones";
import EvaluacionRespuestaAclaracion from "../../../Nominas/Aclaraciones/components/EvaluacionRespuestaAclaracion.vue";
import InconformidadesEvidencia from "../../../../../components/componentsInconformidades/InconformidadesEvidencia.vue";
import MantenimientoModulo from "../../../../../components/MantenimientoModulo.vue";
export default {
  name: "lista-inconformidades-colaborador",
  components: {
    SnackBar,
    EvaluacionRespuestaAclaracion,
    InconformidadesEvidencia,
    MantenimientoModulo,
  },
  props: ["dialog", "ruta"],
  data: () => ({
    textoBarra: "",
    colorBarra: "",
    muestraBarra: false,

    headers: [
      { text: "N.P.", value: "EMPLID", align: "center", sortable: true },
      { text: "Nombre", value: "nombre", align: "center", sortable: true },
      {
        text: "Motivo",
        value: "motivo",
        align: "center",
        sortable: true,
      },
      {
        text: "Departamento",
        value: "departamento",
        align: "center",
        sortable: true,
      },
      {
        text: "fecha Registro",
        value: "fechaRegistro",
        align: "center",
        sortable: true,
      },
      {
        text: "Dias transcurridos",
        value: "diasTranscurridos",
        align: "center",
        sortable: true,
      },
      {
        text: "Nivel de satisfacción",
        value: "nivelSatisfaccion",
        align: "center",
      },
      {
        text: "Estatus",
        value: "estatus",
        align: "center",
      },
      {
        text: "Detalles",
        value: "actions",
        align: "center",
      },
    ],
    inconformidades: [],
    headers_report_to_export: {
      EMPLID: { title: "N.P." },
      nombre: { title: "Nombre" },
      DeptoColaborador: { title: "Departamento de colaborador" },
      motivo: { title: "Motivo" },
      departamento: { title: "Departamento de inconformidad" },
      comentario: { title: "Comentario" },
      fechaInconformidad: { title: "Fecha Inconformidad" },
      fechaRegistro: { title: "Fecha Registro" },
      diasTranscurridos: { title: "Días transcurridos" },
      fechaRespuesta: { title: "Fecha de respuesta" },
      emplidRespuesta: { title: "N.P. de quien responde" },
      nameEmplidRespuesta: { title: "Nombre de quien responde" },
      DeptoResponde: { title: "Departamento de quien responde" },
      nivelSatisfaccion: { title: "Nivel de satisfacción" },
    },
    buscar: "",
    loadingInfo: true,
    loading: false,
    muestraDesc: false,
    openDetalles: false,
    item: "",
    e6: 1,
    evaluarRespuestaAclaracion: false,
    loadingReporte: true,
    verEvidencia: false,
    tipoEvidencia: "",

    moduloError: false,
  }),
  created() {},
  mounted() {
    this.getInconformidades();
    this.saveMonitoreo();
  },
  methods: {
    saveMonitoreo() {
      let params = {
        Opcion: 10,
        pantalla: "Reporte inconformidades",
        token: localStorage.getItem("token"),
      };
      axios
        .post(`${this.$store.getters.getHost}/General/ws-general.php`, params)
        .then((response) => {
          console.log(response.data);
        });
    },
    refrescar(response) {
      if (response == "OK") {
        this.inconformidades = [];
        this.loadingInfo = true;
        this.getInconformidades();
      }
      this.evaluarRespuestaAclaracion = false;
    },
    openEvaluacionDialog(item) {
      this.evaluarRespuestaAclaracion = true;
      this.ID = item.id;
    },
    getInconformidades() {
      this.loadingInfo = true;
      this.loadingReporte = true;
      let params = {
        Opcion: 2,
        ruta: this.ruta,
        token: localStorage.getItem("token"),
      };
      axios
        .post(
          `${this.$store.getters.getHost}/Nominas/AdmInconformidades/ws-inconformidades.php`,
          params
        )
        .then((response) => {
          this.loadingReporte = false;
          this.loadingInfo = false;
          if (response.data.status == "OK") {
            this.inconformidades = response.data.inconformidad;
          } else if (response.data.status == "VACIO") {
            this.textoBarra = "Sin registros hasta el momento.";
            this.colorBarra = "primary";
            this.muestraBarra = true;
          } else if (response.data.status == "EXPRESSION") {
            deleteToken();
          } else if (response.data.status == "ERROR" || !response.data.status) {
            this.moduloError = true;
          }
        });
    },
    mostrarMas(item) {
      if (item.muestraDesc == true) {
        item.muestraDesc = false;
      } else {
        item.muestraDesc = true;
      }
    },
    dialogDetalles(item) {
      this.item = item;
      this.openDetalles = true;
    },
    openEvidencia(item, tipoEvidencia) {
      this.item = item;
      this.verEvidencia = true;
      this.tipoEvidencia = tipoEvidencia;
    },
    cerrarDialog(response) {
      console.log(response);
      this.verEvidencia = false;
    },
  },
};
</script>
