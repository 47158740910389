<template>
  <div>
    <div v-if="esDialog">
      <v-dialog v-model="dialog" fullscreen persistent>
        <v-card class="mx-5">
          <v-toolbar flat color="primary" dark>
            <v-toolbar-title>Carga de documentos</v-toolbar-title>
          </v-toolbar>
          <div v-if="documentos.length == 0">
            <div
              class="d-flex flex-column justify-space-between align-center pa-0"
            >
              <v-img
                transition="scroll-y-transition"
                max-width="300"
                :src="require('@/assets/icons/Documentacion/box_empty.png')"
              >
              </v-img>
            </div>
            <h2 class="text-center blue-grey--text text--lighten-2 mb-5">
              No hay documentación cargada aún.
            </h2>
          </div>
          <div v-else>
            <v-row>
              <v-col
                cols="12"
                xl="4"
                lg="4"
                md="4"
                sm="6"
                xs="12"
                v-for="n in documentos"
                :key="n.idTipo"
                class="d-flex child-flex"
              >
                <v-hover v-slot="{ hover }">
                  <v-card
                    :elevation="hover ? 16 : 2"
                    class="rounded-xl"
                    @click="openFile(n.archivo, n.tipo_archivo)"
                  >
                    <div
                      class="text-center my-2 font-weight-bold text-uppercase blue-grey--text text--darken-1"
                    >
                      {{ n.documento }}
                    </div>
                    <div v-if="n.tipo_archivo == 'image'">
                      <v-img
                        class="my-3"
                        :src="
                          require('@/assets/icons/Documentacion/image-icon.png')
                        "
                        contain
                        max-height="125"
                      >
                      </v-img>
                    </div>
                    <div v-else>
                      <v-img
                        v-if="n.extension == 'pdf'"
                        class="my-3"
                        :src="
                          require('@/assets/icons/Documentacion/pdf-icon.png')
                        "
                        contain
                        max-height="125"
                      >
                      </v-img>
                      <v-img
                        v-else
                        class="my-3"
                        :src="require('@/assets/icons/Documentacion/paper.png')"
                        contain
                        max-height="125"
                      >
                      </v-img>
                    </div>
                    <v-card-title class="py-2 center-item-in-div">
                      <v-icon color="cyan" class="mx-1">mdi-eye</v-icon>
                      <div class="cyan--text overline">ver documento</div>
                    </v-card-title>
                  </v-card>
                </v-hover>
              </v-col>
            </v-row>
          </div>
          <div class="text-center mt-5" transition="scroll-y-transition">
            <v-hover v-slot="{ hover }">
              <v-btn
                :color="hover ? 'blue lighten-1' : 'primary'"
                :elevation="hover ? 16 : 2"
                :dark="hover ? false : true"
                large
                rounded
                @click="openArchivosEdit()"
              >
                <v-icon left large>mdi-upload</v-icon>
                cargar ó editar documentación
              </v-btn>
            </v-hover>
          </div>
        </v-card>
        <CargaArchivos
          v-if="muestraCargaArchivos"
          :dialogCargaArchivos="muestraCargaArchivos"
          :emplid="emplid"
          :estatusColaborador="1"
          esAdmin="2"
          @cierraVentana="reloadInformation()"
        />
        <SnackBarErrorIncidencias
          v-if="muestraBarra"
          :muestraBarra="muestraBarra"
          :texto="textoBarra"
          :color="colorBarra"
          @cierraBarra="muestraBarra = false"
        />
      </v-dialog>
    </div>
    <div v-else>
      <v-card elevation="0">
        <v-toolbar flat color="primary" dark>
          <v-toolbar-title>Carga de documentos</v-toolbar-title>
        </v-toolbar>
        <div v-if="documentos.length == 0">
          <div
            class="d-flex flex-column justify-space-between align-center pa-0"
          >
            <v-img
              transition="scroll-y-transition"
              max-width="300"
              :src="require('@/assets/icons/Documentacion/box_empty.png')"
            >
            </v-img>
          </div>
          <h2 class="text-center blue-grey--text text--lighten-2 mb-5">
            No hay documentación cargada aún.
          </h2>
        </div>
        <div v-else>
          <v-row>
            <v-col
              cols="12"
              xl="4"
              lg="4"
              md="4"
              sm="6"
              xs="12"
              v-for="n in documentos"
              :key="n.idTipo"
              class="d-flex child-flex"
            >
              <v-hover v-slot="{ hover }">
                <v-card
                  :elevation="hover ? 16 : 2"
                  class="rounded-xl"
                  @click="openFile(n.archivo, n.tipo_archivo)"
                >
                  <div
                    class="text-center my-2 font-weight-bold text-uppercase blue-grey--text text--darken-1"
                  >
                    {{ n.documento }}
                  </div>
                  <div v-if="n.tipo_archivo == 'image'">
                    <v-img
                      class="my-3"
                      :src="
                        require('@/assets/icons/Documentacion/image-icon.png')
                      "
                      contain
                      max-height="125"
                    >
                    </v-img>
                  </div>
                  <div v-else>
                    <v-img
                      v-if="n.extension == 'pdf'"
                      class="my-3"
                      :src="
                        require('@/assets/icons/Documentacion/pdf-icon.png')
                      "
                      contain
                      max-height="125"
                    >
                    </v-img>
                    <v-img
                      v-else
                      class="my-3"
                      :src="require('@/assets/icons/Documentacion/paper.png')"
                      contain
                      max-height="125"
                    >
                    </v-img>
                  </div>
                  <v-card-title class="py-2 center-item-in-div">
                    <v-icon color="cyan" class="mx-1">mdi-eye</v-icon>
                    <div class="cyan--text overline">ver documento</div>
                  </v-card-title>
                </v-card>
              </v-hover>
            </v-col>
          </v-row>
        </div>
        <div class="text-center mt-5" transition="scroll-y-transition">
          <v-hover v-slot="{ hover }">
            <v-btn
              :color="hover ? 'blue lighten-1' : 'primary'"
              :elevation="hover ? 16 : 2"
              :dark="hover ? false : true"
              large
              rounded
              @click="openArchivosEdit()"
            >
              <v-icon left large>mdi-upload</v-icon>
              cargar ó editar documentación
            </v-btn>
          </v-hover>
        </div>
      </v-card>
      <CargaArchivos
        v-if="muestraCargaArchivos"
        :dialogCargaArchivos="muestraCargaArchivos"
        :emplid="emplid"
        :estatusColaborador="1"
        esAdmin="2"
        @cierraVentana="reloadInformation()"
      />
      <SnackBarErrorIncidencias
        v-if="muestraBarra"
        :muestraBarra="muestraBarra"
        :texto="textoBarra"
        :color="colorBarra"
        @cierraBarra="muestraBarra = false"
      />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { deleteToken } from "../../../../../../store/Funciones/funciones";
import CargaArchivos from "./MuestraArchivosPorCargar.vue";
import SnackBarErrorIncidencias from "../../../MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";
export default {
  name: "carga-documentos-personales",
  components: { CargaArchivos, SnackBarErrorIncidencias },
  props: ["dialog", "mensaje", "mensajeBtn", "datosInfo"],
  data() {
    return {
      textoBarra: "",
      colorBarra: "",
      muestraBarra: false,
      timeout: 2000,

      documentos: [],
      muestraCargaArchivos: false,
      esDialog: false,
      emplid: "",
    };
  },
  computed: {},
  mounted() {
    this.getCompania();
    this.validaRuta();
  },
  methods: {
    validaRuta() {
      const cadena = this.$route.path;
      const ruta = cadena.split("/");
      this.esDialog = ruta[2] == "Inicio" ? true : false;
    },

    getCompania() {
      if (
        ["00100", "CANDIDATO"].includes(this.$store.getters.getUser["compania"])
      ) {
        this.getDocs();
      } else {
        this.bloqueo = true;
      }
    },

    getDocs() {
      let params = {
        Opcion: 1,
        token: localStorage.getItem("token"),
        // empleado: this.$store.getUser["Folio"]
      };
      axios
        .post(
          `${this.$store.getters.getHost}/RelacionesLaborales/DocumentacionEmpleados/ws-carga-documentos.php`,
          params
        )
        .then((response) => {
          this.loadingInfo = false;
          if (response.data.status == "VACIO") {
            this.noFileData = true;
          } else if (response.data.status == "error") {
            this.errors = response.data.detalles;
          } else if (response.data.status == "OK") {
            this.documentos = response.data.docs;
          } else if (response.data.status == "EXPRESSION") {
            // alert(response.data.status)
            deleteToken();
          }
        });
    },

    openArchivosEdit() {
      this.emplid = this.$store.getters.getUser["Folio"];
      this.muestraCargaArchivos = true;
    },

    reloadInformation() {
      this.muestraCargaArchivos = false;
      this.documentos.length = 0;
      this.loadingInfo = true;
      this.getDocs();
      this.revisarDocPendiente();
    },

    async openFile(file, type) {
      let params = {
        Opcion: 9,
        token: localStorage.getItem("token"),
        empleado: this.$store.getters.getUser["Folio"],
        filename: file,
      };

      try {
        const response = await axios.post(
          `${this.$store.getters.getHost}/RelacionesLaborales/DocumentacionEmpleados/ws-carga-documentos.php`,
          params
        );

        if (response.data.status === "OK") {
          let arrayDataFile = response.data.file.split(",");
          const blob = await this.base64ToBlob(
            arrayDataFile[1],
            arrayDataFile[0]
          );
          const fileUrl = URL.createObjectURL(blob);
          const link = document.createElement("a");
          if (type != "image") {
            link.href = fileUrl;
            link.download = "documento.pdf";
            link.click();
          } else {
            link.href = fileUrl;
            link.download = "documento.jpg";
            link.click();
          }
        } else if (response.data.status === "EXPRESSION") {
          deleteToken();
        } else {
          this.textoBarra =
            "Parece que el archivo está dañado o no existe. Se recomienda reemplazar.";
          this.colorBarra = "pink darken-3";
          this.muestraBarra = true;
        }
      } catch (error) {
        console.error("Ocurrió un error:", error);
        alert("Ocurrió un error al descargar el archivo.");
      }
    },

    async base64ToBlob(base64, contentType = "") {
      // let arrayBase64 = base64.split(",")
      const byteCharacters = atob(base64);
      const byteArrays = [];
      for (let offset = 0; offset < byteCharacters.length; offset += 512) {
        const slice = byteCharacters.slice(offset, offset + 512);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
      return new Blob(byteArrays, {
        type: contentType,
      });
    },

    cleanBase64String(base64String) {
      return base64String.replace(/[^A-Za-z0-9+/=]/g, "");
    },

    revisarDocPendiente() {
      let params = {
        Opcion: 20,
        token: localStorage.getItem("token"),
      };
      axios
        .post(
          `${this.$store.getters.getHost}/RelacionesLaborales/DocumentacionEmpleados/ws-carga-documentos.php`,
          params
        )
        .then(async (response) => {
          if (response.data.status == "EXPSESSION") {
            deleteToken();
          } else {
            const cadena = this.$route.path;
            const ruta = cadena.split("/");
            if (ruta[2] == "Inicio") {
              if(response.data.data["FormCompletado"] > 0 && response.data.data["Pendiente"] == 0){
                this.textoBarra = "Gracias por completar su documentación";
                this.colorBarra = "success";
                this.muestraBarra = true;
                this.fakePromise();
              }
            }
          }
        });
    },

    fakePromise() {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(this.$emit("cierraAlertaDocumentos"));
        }, 3000);
      });
    },
  },
};
</script>
