<template>
  <v-dialog
    v-model="dialog"
    max-width="600px"
    transition="dialog-top-transition"
    persistent
  >
    <v-card>
      <v-card-title class="text-subtitle-2 primary white--text">
        Evidencia de inconformidad
        <v-spacer></v-spacer>
        <v-icon small dark @click="$emit('cierraDialogEvidencia', 'cancelar')"
          >mdi-close</v-icon
        >
      </v-card-title>
      <v-card-text class="pt-5">
        <div v-show="loading" class="text-center">
          <v-progress-circular
            color="primary"
            indeterminate
            :size="70"
            :width="7"
          ></v-progress-circular>
          <h2 class="text-center blue-grey--text text--lighten-2 py-5 mb-5">
            Por favor espere... Cargando evidencia...
          </h2>
        </div>
        <v-carousel v-if="!loading && items.length > 0">
          <v-carousel-item
            v-for="(item, i) in items"
            :key="i"
            :src="item.src"
            reverse-transition="fade-transition"
            transition="fade-transition"
          ></v-carousel-item>
        </v-carousel>
        <div align="center" v-else-if="!loading && items.length == 0">
            No se encuentran evidencias cargadas en esta inconformidad 
            <v-icon>mdi-emoticon-sad-outline</v-icon>
        </div>
      </v-card-text>
    </v-card>
    <snackbar
      v-if="muestraBarra"
      :muestraBarra="muestraBarra"
      :texto="textoBarra"
      :color="colorBarra"
      @cierraBarra="muestraBarra = false"
    />
  </v-dialog>
</template>

<script>
import snackbar from "../../views/generated/DesarrolloOrganizacional/components/snackbar.vue";
import axios from "axios";
import { deleteToken } from "../../store/Funciones/funciones";
export default {
  name: "inconformidades-evidencia",
  components: { snackbar },
  props: ["dialog", "item", "tipoEvidencia"],
  data: () => ({
    textoBarra: "",
    colorBarra: "",
    muestraBarra: false,

    loading: true,
    items: [],
  }),
  created() {},
  mounted() {
    this.getEvidencia();
  },
  methods: {
    getEvidencia() {
      let params = {
        Opcion: 9,
        token: localStorage.getItem("token"),
        id: this.item["id"],
        tipoEvidencia:this.tipoEvidencia,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/Nominas/AdmInconformidades/ws-inconformidades.php`,
          params
        )
        .then((response) => {
          this.loading = false;

          console.log(response.data);
          if (response.data.status == "OK") {
            response.data.evidencia.map((element) => {
              console.log(element);
              this.items.push({
                src:
                  this.$store.getters.getHost +
                  "/ArchivosCargadosEnPortal2/Inconformidades/" +
                  element.emplid +
                  "/" + this.tipoEvidencia + "/" +
                  element.evidencia,
              });
            });
          } else if (response.data.status == "VACIO") {
            this.textoBarra = "Sin evidencia.";
            this.colorBarra = "primary";
            this.muestraBarra = true;
          } else if (response.data.status == "EXPRESSION") {
            deleteToken();
          }
        });
    },
  },
};
</script>
