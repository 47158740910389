<template>
  <v-dialog v-model="dialog" max-width="500px" persistent>
    <template>
      <v-card :loading="loadingColaboradores" class="rounded-lg">
        <v-toolbar dark color="primary">
          <v-toolbar-title>Premia principios</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="$emit('cierraAsignacionValores')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text height="700px">
          <v-row v-show="asignacionesNoPermitidas">
            <v-col cols="12" style="display: flex; justify-content: center">
              <p style="color: darkred; font-weight: bold">
                Lo sentimos, pero no puedes realizar más de 3 asignaciones por
                mes
              </p>
            </v-col>
          </v-row>
          <v-row v-if="valores.length == 0">
            <v-col cols="12" style="display: flex; justify-content: center">
              <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
            </v-col>
          </v-row>
          <v-row style="margin-top: 5px">
            <v-col class="d-flex child-flex" cols="4" v-for="(valor, index) in valores" :key="index">
              <v-hover>
                <template v-slot:default="{ hover }">
                  <v-img :class="`elevation-${hover ? 24 : 6}`" class="mx-auto pa-6 transition-swing rounded-lg"
                    :src="require('@/assets/icons/' + valor.image)" aspect-ratio="1"
                    @click="valorSeleccionado(valor.titulo)">
                  </v-img>
                </template>
              </v-hover>
            </v-col>
            <v-row v-show="!asignacionesNoPermitidas" style="height: 50px">
              <v-col cols="3" class="valores-titulo-box" style="height: 50px">
                Principio:
              </v-col>
              <v-col cols="9" style="height: 50px">
                <v-text-field disabled :value="valorSeleccionadoString" class="valores-contenido-box"></v-text-field>
              </v-col>
            </v-row>
            <v-row v-show="!asignacionesNoPermitidas" style="height: 50px">
              <v-col cols="3" class="valores-titulo-box" style="height: 50px">
                A quien:
              </v-col>
              <v-col cols="9">
                <v-autocomplete style="height: 50px" v-model="aQuien" :items="colaboradoresNombre"
                  :loading="loadingColaboradores" :disabled="loadingColaboradores"
                  v-on:change="buscaArea()"></v-autocomplete>
              </v-col>
            </v-row>
            <v-row v-show="!asignacionesNoPermitidas" style="height: 50px">
              <v-col cols="3" class="valores-titulo-box" style="height: 50px">
                Área:
              </v-col>
              <v-col cols="9" style="height: 50px">
                <v-text-field v-model="area" :value="areaColaborador" disabled></v-text-field>
              </v-col>
            </v-row>
            <v-row v-show="!asignacionesNoPermitidas">
              <v-col cols="3" class="valores-titulo-box"> ¿Por qué?: </v-col>
              <v-col cols="9">
                <v-textarea v-model="razon" :rules="reasonRules" :counter="350" :disabled="loadingBotonGuardar"
                  rows="1"></v-textarea>
              </v-col>
            </v-row>
          </v-row>
        </v-card-text>
        <v-card-actions v-show="!asignacionesNoPermitidas">
          <v-spacer></v-spacer>
          <v-btn color="teal darken-1" class="rounded-lg" dark @click="validaInformacion()"
            :loading="loadingBotonGuardar" :disabled="asignacionesNoPermitidas">
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-sheet id="snack-bar-error-incidencias">
        <snack-bar-error-incidencias :muestraBarra="muestraBarra" :texto="textoBarra" :color="colorBarra"
          @cierraBarra="muestraBarra = false" />
      </v-sheet>
    </template>
  </v-dialog>
</template>

<script>
import axios from "axios";
import SnackBarErrorIncidencias from "../views/generated/General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";
import { deleteToken } from '../store/Funciones/funciones'
export default {
  name: "persona-asignacion-de-valores",
  created() {
    this.saveMonitoreo();
  },
  mounted() {
    this.getValoresAsignados();
    this.getValoresValoraciones();
  },
  components: {
    SnackBarErrorIncidencias,
  },
  data: () => ({
    muestraBarra: false,
    textoBarra: "",
    colorBarra: "pink",
    loadingBotonGuardar: false,
    valores: [],
    reasonRules: [
      (v) => !!v || "El campo es requerido",
      (v) => v.length <= 350 || "No puedes poner más de 350 caracteres.",
    ],
    valorSeleccionadoString: "",
    aQuien: "",
    colaboradores: [],
    colaboradoresNombre: [],
    respuestaSetValor: 0,
    respuestaCountValoresAsignados: [],
    counAsignacionesPorMes: "",
    loadingColaboradores: false,
    asignacionesNoPermitidas: false,
    areaColaborador: "",
    valorado: {},
    area: "",
    razon: "",
  }),
  props: ["dialog"],
  methods: {
    saveMonitoreo() {
      let params = {
        Opcion: 10,
        pantalla: "Asignacion de valores",
        token: localStorage.getItem('token'),
      };
      axios
        .post(`${this.$store.getters.getHost}/General/ws-general.php`, params)
        .then((response) => {
          console.log("monitoreo guardado. " + response.data);
        });
    },
    valorSeleccionado(valor) {
      this.valorSeleccionadoString = valor;
    },
    getValoresAsignados() {
      let params = {
        Opcion: 4,
        token: localStorage.getItem("token"),
      };
      axios
        .post(`${this.$store.getters.getHost}/Valores/ws-valores.php`, params)
        .then((response) => {
          if (response.data.status == "EXPSESSION") {
            deleteToken();
          } else {
            var data = response.data.valoresMes;
            this.respuestaCountValoresAsignados = data;
            this.asignaContador();
          }
        })
        .catch(function (e) {
          let result = e.toString().includes("401");
          if (result) {
            window.location.reload();
          } else {
            alert(e);
          }
        });
    },
    asignaContador() {
      this.counAsignacionesPorMes =
        this.respuestaCountValoresAsignados[0]["ValoresAsignados"];
      if (this.counAsignacionesPorMes >= 3) {
        this.asignacionesNoPermitidas = true;
      } else {
        this.loadingColaboradores = true;
        this.getColaboradores();
      }
    },
    getColaboradores() {
      let params = {
        Opcion: 7,
        token: localStorage.getItem("token"),
      };
      axios
        .post(`${this.$store.getters.getHost}/Valores/ws-valores.php`, params)
        .then((response) => {
          this.colaboradores = response.data.colaboradores;
          this.colaboradores.map((elemento) => {
            this.colaboradoresNombre.push(elemento["NAME"]);
          });
          this.loadingColaboradores = false;
        })
        .catch(function (e) {
          let result = e.toString().includes("401");
          if (result) {
            window.location.reload();
          } else {
            alert(e);
          }
        });
    },
    buscaArea() {
      let result = this.colaboradores.filter(
        (colaboralor) => colaboralor["NAME"] == this.aQuien
      );
      this.valorado = result[0];
      this.area = result[0]["Area"];
      this.areaColaborador = result[0]["Area"];
    },
    validaInformacion() {
      let validaValor = this.valores.some(
        (valor) => valor["titulo"] == this.valorSeleccionadoString
      );

      if (
        validaValor &&
        this.aQuien != "" &&
        this.area != "" &&
        this.razon != ""
      ) {
        this.loadingBotonGuardar = true;
        let params = {
          Opcion: 5,
          valorado: this.valorado,
          razon: this.razon,
          valor: this.valorSeleccionadoString,
          token: localStorage.getItem("token"),
        };

        axios
          .post(`${this.$store.getters.getHost}/Valores/ws-valores.php`, params)
          .then((response) => {
            this.respuestaSetValor = response.data.respuesta;
            this.validaRespuesta(this.respuestaSetValor);
          })
          .catch(function (e) {
            let result = e.toString().includes("401");
            if (result) {
              window.location.reload();
            } else {
              alert(e);
            }
          });
      } else {
        this.textoBarra = "ERROR. Debes llenar todos los campos.";
        this.muestraBarra = true;
      }
    },
    validaRespuesta(respuesta) {
      this.aQuien = "";
      this.area = "";
      this.razon = "";
      this.loadingBotonGuardar = false;
      if (respuesta > 0) {
        this.textoBarra = "Valor asignado con éxito.";
        this.colorBarra = "success";
        this.muestraBarra = true;
        this.getValoresAsignados();
      } else {
        this.textoBarra = "ERROR. No pudimos guardar tu información";
        this.colorBarra = "error";
        this.muestraBarra = true;
      }
    },
    async getValoresValoraciones() {
      let params = {
        Opcion: 1,
        token: localStorage.getItem("token"),
      };
      try {
        let response = await axios.post(`${this.$store.getters.getHost}/Valores/ws-valores.php`, params);
        this.valores = response.data.datosValores;
      } catch (error) {
        let errorMessage = "Ha ocurrido un error al cargar los valores.";
        if (error.response && error.response.status === 401) {
          window.location.reload();
        } else {
          alert(errorMessage);
        }
      }
    },
  },
};
</script>
<style>
.valores-header {
  background-color: rgba(0, 120, 180, 1);
  color: white;
  display: flex;
  justify-content: center;
}

.v-card.on-hover.theme--dark {
  background-color: rgba(#fff, 0.8);
}

.valores-titulo-box {
  display: flex;
  justify-content: right;
  margin-top: 26px;
  font-weight: bold;
  color: rgb(0, 120, 180);
  font-size: 1.5em;
}

.valores-contenido-box {
  color: #2f435e;
  font-weight: bold;
}
</style>