<template>
  <div>
    <div v-if="loading" class="center-item-in-div-column pt-5">
      <v-progress-circular :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
      <label class="overline">cargando información de nóminas...</label>
    </div>
    <v-list v-else nav dense>
      <v-list-item-group color="primary">
        <template v-if="nominas.length > 0">
          <v-list-item v-for="(nomina, i) in nominas" :key="i" @click="getNominaPDF(nomina)" target="_blank">
            <v-list-item-icon>
              <v-icon v-if="nomina.type === 2">mdi-file-plus-outline</v-icon>
              <v-icon v-else-if="nomina.type === 1 && nomina.stamped">mdi-file-star-outline</v-icon>
              <v-icon v-else>mdi-file-cloud-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ formatDate(nomina.end_date) }} - {{ getNominaText(nomina) }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list-item-group>
      <v-dialog v-model="dialogZentric" hide-overlay persistent width="300">
        <v-card color="primary" dark>
          <v-card-text>
            Obteniendo nomina, un momento...
            <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-list>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "persona-alu",
  data: () => ({
    muestraLoadingZentric: false,
    textoLoading: "",
    nominas: [],
    loadingWindow: true,
    openFormAclaraciones: false,
    openAdmonAclaraciones: false,
    loading: true,
    currentYear: null,
    textoLoadingZentric: "",
    dialogZentric: false,
  }),
  components: {},
  props: ["dialog"],
  created() {
    this.saveMonitoreo();
  },
  mounted() {
    this.currentYear = this.setCurrentYear();
    this.getPeriodos();
    // console.log('Nominas zentric');
  },
  methods: {
    //UX
    setCurrentYear() {
      var currentTime = new Date();
      var year = currentTime.getFullYear();
      return year;
    },
    formatDate(date) {
      const [yyyy, mm, dd] = date.split("-");
      return `${dd}/${mm}/${yyyy}`;
    },

    saveMonitoreo() {
      let params = {
        Opcion: 10,
        pantalla: "Mis nominas zentric",
        token: localStorage.getItem("token"),
      };
      axios
        .post(`${this.$store.getters.getHost}/General/ws-general.php`, params)
        .then((response) => {
          console.log(response.data);
        });
    },

    //ZENTRIC
    getPeriodos() {
      let data = {
        token: localStorage.getItem("token"),
        //tokenZentric: localStorage.getItem("tokenZentric"),
        GrupoPago: localStorage.getItem("GrupoPago"),
      };
      axios
        .post(`${this.$store.getters.getHostNode}/api/prerecibos-list`, data)
        .then((response) => {
          if (response.data.status == "OK") {
            this.loadingWindow = false;
            this.loading = false;
            if (
              response.data.data != "No se encontraron datos." &&
              response.data.data
            ) {
              this.nominas = response.data.data;
              this.$emit("numNominas", this.nominas.length);
            }
          }
        })
        .catch(function (e) {
          let result = e.toString().includes("401");
          if (result) {
            window.location.reload();
          } else {
            alert(e);
          }
        });
    },
    getNominaPDF(periodo) {
      this.dialogZentric = true;
      // this.$emit("cargandopdf", true);
      let data = {
        token: localStorage.getItem("token"),
        periodo: periodo,
      };
      axios
        .post(`${this.$store.getters.getHostNode}/api/prerecibos-pdf`, data)
        .then((response) => {
          if (response.data.status == "OK") {
            this.getNominaPDFZentric(
              periodo,
              response.data.user,
              response.data.token
            );
          }
        })
        .catch(function (e) {
          let result = e.toString().includes("401");
          if (result) {
            window.location.reload();
          } else {
            alert(e);
          }
        });
    },
    getNominaPDFZentric(periodo, user, tokenZentric) {
      let data;
      let url;
      if (periodo.stamped) {
        url = "https://api.zentric.mx/stampings/generate-receipt/";
        data = {
          payroll: periodo.payroll,
          year: periodo.year,
          period: periodo.period,
          pks: [periodo.idtimbrado],
          stamped: true,
        };
      } else {
        url = "https://api.zentric.mx/stampings/generate-prereceipt/";
        data = {
          payroll: periodo.payroll,
          year: periodo.year,
          period: periodo.period,
          pks: [user],
        };
      }

      axios({
        url: url,
        method: "POST",
        headers: {
          Authorization: tokenZentric,
        },
        responseType: "arraybuffer",
        data: data,
      })
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/pdf" });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "nomina-" + periodo.end_date + ".pdf";
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          this.muestraLoading = false;
        })
        .catch((error) => {
          alert("Error al obtener PDF, favor reportar el error.");
          console.error("Error al obtener el PDF:", error);
        })
        .finally(() => {
          this.dialogZentric = false;

          // this.$emit("cargandopdf", false);
        });
    },
    getNominaText(nomina) {
      if (nomina.prenomina) {
        return 'Prenómina';
      } else if (nomina.type === 2) {
        return nomina.stamped ? 'Nómina extraordinaria Timbrada' : 'Nómina extraordinaria';
      } else if (nomina.payroll === 5704 || nomina.payroll === 5709 || nomina.payroll === 8240) {
        return nomina.stamped ? 'Nómina Timbrada' : 'Nómina';
      } else if (nomina.payroll === 5705 || nomina.payroll === 5710 || nomina.payroll === 8241) {
        return nomina.stamped ? 'Nómina Finiquito Timbrada' : 'Nómina Finiquito';
      } else if (nomina.payroll === 5706 || nomina.payroll === 5711 || nomina.payroll === 8242) {
        return nomina.stamped ? 'Nómina Liquidación Timbrada' : 'Nómina Liquidación';
      } else if (nomina.payroll === 5707 || nomina.payroll === 5712 || nomina.payroll === 8243) {
        return nomina.stamped ? 'Nómina Aguinaldo Timbrada' : 'Nómina Aguinaldo';
      } else if (nomina.payroll === 5708 || nomina.payroll === 5713 || nomina.payroll === 8244) {
        return nomina.stamped ? 'Nómina PTU Timbrada' : 'Nómina PTU';
      } else if (nomina.payroll === 11720 || nomina.payroll === 11721 || nomina.payroll === 11722) {
        return nomina.stamped ? 'Nómina Fondo de Ahorro Timbrada' : 'Nómina Fondo de Ahorro';
      } else if (nomina.type !== 2 && nomina.stamped) {
        return 'Nómina Timbrada';
      } else {
        return 'Nómina';
      }
    },
  },
};
</script>
