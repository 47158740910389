<template>
  <v-dialog v-model="commentReject" persistent max-width="600">
    <v-card class="rounded-lg">
      <v-card-title class="pt-2 primary white--text">
        Justifica la acción.
        <v-spacer></v-spacer>
        <v-icon dark @click="$emit('ocultaDialogComentario', null)">
          mdi-close-thick
        </v-icon>
      </v-card-title>

      <v-card-subtitle
        class="primary pb-2 blue-grey--text text--lighten-3 text-uppercase"
      >
        ¿Cuál es el motivo del cambio?
      </v-card-subtitle>

      <v-card-text class="pt-2 pb-0">
        <v-form ref="formComments" v-model="valid" lazy-validation>
          <v-select
            v-if="isCandidate === true"
            v-model="commentRejectText"
            class="rounded-lg"
            outlined
            :items="types"
            item-text="descripcion"
            :rules="isCandidate === true ? commentRejectRules : []"
            :required="isCandidate === true ? true : false"
            placeholder="Selecciona el comentario de justificación."
          >
          </v-select>
          <v-textarea
            v-else
            v-model="commentRejectText"
            class="rounded-lg"
            outlined
            :rows="1"
            :rules="isCandidate === true ? [] : commentRejectRules"
            :required="isCandidate === true ? false : true"
            placeholder="Escribe el comentario de justificación."
            prepend-inner-icon="mdi-comment"
            counter="250"
          ></v-textarea>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn
          class="rounded-lg elevation-0"
          block
          color="primary"
          @click="validaComentario"
        >
          Aceptar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios'
export default {
  name: "dialog-comentario-rechazo",
  props: ["commentReject", "isCandidate"],
  data() {
    return {
      commentRejectText: "",
      valid: true,
      loadingTypes: true,
      types: [],
      commentRejectRules: [
        (v) => !!v || "Campo requiredo.",
        (v) =>
          (v && v.length <= 250) ||
          "el campo debe tener menos de 250 caracteres.",
      ],
    };
  },
  async mounted(){
    console.log(this.isCandidate)
    if(this.isCandidate === true)
      this.types = await this.getTiposRechazo().catch((e) => {alert(e)})
  },
  methods: {
    validaComentario() {
      if (this.$refs.formComments.validate()) {
        this.$emit("ocultaDialogComentario", this.commentRejectText);
      }
    },
    getTiposRechazo(){
      const params = {
        Opcion: 46,
        token: localStorage.getItem('token')
      }
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${this.$store.getters.getHost}/Reclutamiento/ws-admin-reclu.php`,
            params
          )
          .then((response) => {
            if (response.data.status == "OK") {
              resolve(response.data.respuesta);
            } else {
              resolve([]);
            }
          })
          .catch((error) => {
            reject(error);
          })
          .finally(() => {
            this.loadingTypes = false;
          });
      })
    }
  },
};
</script>
