<template>
  <!-- <v-dialog v-model="dialog" width="800" persistent content-class="fix-border-dialog"> -->
  <div>
    <v-card class="rounded-xl">
      <v-card-title
        class="subtitle-1 text-uppercase font-weight-bold primary white--text"
      >
        Tus incidencias por autorizar
        <v-spacer></v-spacer>
        <!-- <v-btn icon dark @click="$emit('closed')">
          <v-icon>mdi-close</v-icon>
        </v-btn> -->
      </v-card-title>
      <v-card-text class="pa-2">
        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="respuestaApiAutorizarIncidencias"
          :items-per-page="itemsPerPage"
          :footer-props="{
            'items-per-page-options': [5, 10],
            'items-per-page-text': 'Elementos por página',
          }"
          no-data-text="No hay registros"
          :header-props="headerProps"
          :loading="loadingTabla"
          item-key="id_incidencia"
          class="elevation-0"
          dense
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              dark
              color="pink darken-3"
              :disabled="loadingBotonCancelar"
              title="Rechazar"
              @click="cancelarIncidencia(item.id_incidencia)"
            >
              mdi-close-circle
            </v-icon>
            <v-icon
              dark
              color="teal darken-1"
              :disabled="loadingBotonCancelar"
              @click="autorizarIncidencia(item.id_incidencia)"
              title="Autorizar"
            >
              mdi-check-circle
            </v-icon>

            <v-icon
              dark
              color="cyan darken-2"
              :disabled="loadingBotonCancelar"
              @click="detallesIncidencia(item.id_incidencia)"
              title="Ver detalles"
            >
              mdi-information
            </v-icon>
          </template>
        </v-data-table>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="pa-0">
        <!-- <v-spacer></v-spacer> -->
        <v-btn
          v-show="respuestaApiAutorizarIncidencias.length > 0"
          color="teal darken-1"
          class="rounded-xl rounded-t-0 elevation-0 font-weight-bold"
          dark
          text
          large
          block
          @click="autorizarIncidencias()"
          :disabled="loadingTabla"
          :loading="loadingTabla"
        >
          <v-icon left> mdi-account-multiple-check-outline </v-icon>
          Autorizar Todo
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-sheet id="snack-bar-error-incidencias">
      <snack-bar-error-incidencias
      v-if="muestraBarra"
        :muestraBarra="muestraBarra"
        :texto="textoBarra"
        :color="colorBarra"
        @cierraBarra="muestraBarra = false"
      />
    </v-sheet>

    <v-sheet id="muestra-incidencias-cargadas">
      <muestra-incidencias-cargadas
        v-if="muestraDetalles"
        :muestraDetalles="muestraDetalles"
        :datosTabla="respuestaApiDetallesIncidencia"
        :alertErrorDosDias="alertErrorDosDias"
        @cierraTabla="muestraDetalles = false"
      />
    </v-sheet>
  </div>
</template>
<script>
import axios from "axios";
import SnackBarErrorIncidencias from "../../MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";
import MuestraIncidenciasCargadas from "../../MisIncidencias/components/componentsExtra/MuestraIncidenciasCargadas.vue";

export default {
  name: "persona-autorizar-incidencias",
  //   components: {},
  data: () => ({
    muestraDetalles: false,
    alertErrorDosDias: false,
    respuestaApiDetallesIncidencia: [],
    muestraBarra: false,
    textoBarra: "",
    colorBarra: "",
    itemsPerPage: 5,
    singleSelect: false,
    selected: [],
    loadingTabla: true,
    respuestaApiAutorizarIncidencias: [],
    headerProps: {
      sortByText: "Ordenar por",
    },
    headers: [
      {
        text: "ID",
        align: "start",
        sortable: false,
        value: "id_incidencia",
        class:
          "blue-grey--text text--darken-1 font-weight-bold text-uppercase rounded-tl-lg",
      },
      {
        text: "Nombre",
        value: "nombre",
        class: "blue-grey--text text--darken-1 font-weight-bold text-uppercase",
      },
      {
        text: "Incidencia",
        value: "decripcion",
        class: "blue-grey--text text--darken-1 font-weight-bold text-uppercase",
      },
      {
        text: "Acciones",
        value: "actions",
        sortable: false,
        class:
          "blue-grey--text text--darken-1 font-weight-bold text-uppercase rounded-tr-lg",
      },
    ],
    respuestaIncidenciaCancelada: "",
    respuestaIncidenciasAutorizadas: "",
    loadingBotonCancelar: false,
    visible: false,
  }),
  components: {
    SnackBarErrorIncidencias,
    MuestraIncidenciasCargadas,
  },
  props: ["dialog"],
  watch: {
    dialog: function(isVisible) {
      this.visible = isVisible;
    },
  },
  async mounted() {
    this.respuestaApiAutorizarIncidencias = await this.getIncidenciasPorAutorizar();
    if (this.respuestaApiAutorizarIncidencias.length == 0) {
      this.$emit("validaEstadoInc", 0);
    }
  },
  methods: {
    autorizarIncidencia(id) {
      var params = {
        Opcion: 1,
        token: localStorage.getItem("token"),
        idIncidencia: id,
        estatus: 2,
      };

      if (window.confirm("¿Realmente deseas autorizar esta incidencia?")) {
        this.loadingTabla = true;
        this.loadingBotonCancelar = true;
        axios
          .post(
            `${this.$store.getters.getHost}/Incidencias/ws-incidencias-autorizacion.php`,
            params
          )
          .then((response) => {
            // //console.log(response.data)
            var data = response.data.incidencias;
            this.respuestaIncidenciaCancelada = data;
            this.validaRespuestaModificacion();
          })
          .catch(function(e) {
            let result = e.toString().includes("401");
            if (result) {
              window.location.reload();
            } else {
              alert(e);
            }
          });
      }
    },
    detallesIncidencia(id) {
      var params = {
        Opcion: 4,
        token: localStorage.getItem("token"),
        id: id,
      };

      this.loadingTabla = true;
      axios
        .post(
          `${this.$store.getters.getHost}/Incidencias/ws-incidencias-autorizacion.php`,
          params
        )
        .then((response) => {
          var data = response.data.incidencias;
          this.respuestaApiDetallesIncidencia = data;
          this.validaRespuestaDetalles();
        })
        .catch(function(e) {
          let result = e.toString().includes("401");
          if (result) {
            window.location.reload();
          } else {
            alert(e);
          }
        });
    },
    validaRespuestaDetalles() {
      this.muestraDetalles = true;
      this.loadingTabla = false;
    },
    getIncidenciasPorAutorizar() {
      let params = {
        Opcion: 3,
        token: localStorage.getItem("token"),
      };
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${this.$store.getters.getHost}/Incidencias/ws-incidencias-autorizacion.php`,
            params
          )
          .then((response) => {
            if (response.data.status == "Ok") {
              resolve(response.data.incidenciasPorAutorizar);
            } else {
              resolve([]);
            }
          })
          .catch(function(e) {
            reject(e);
          })
          .finally(() => {
            this.loadingTabla = false;
          });
      });
    },
    cancelarIncidencia(id) {
      var params = {
        Opcion: 1,
        token: localStorage.getItem("token"),
        idIncidencia: id,
        estatus: 3,
      };

      if (window.confirm("¿Realmente deseas rechazar esta incidencia?")) {
        this.loadingTabla = true;
        this.loadingBotonCancelar = true;
        axios
          .post(
            `${this.$store.getters.getHost}/Incidencias/ws-incidencias-autorizacion.php`,
            params
          )
          .then((response) => {
            var data = response.data.incidencias;
            this.respuestaIncidenciaCancelada = data;
            this.validaRespuestaModificacion();
          })
          .catch(function(e) {
            let result = e.toString().includes("401");
            if (result) {
              window.location.reload();
            } else {
              alert(e);
            }
          });
      }
    },
    async validaRespuestaModificacion() {
      if (this.respuestaIncidenciaCancelada["estatusSolicitud"] == "true") {
        this.textoBarra = "La incidencia se modificó con éxito";
        this.colorBarra = "primary";
        this.muestraBarra = true;

        if (this.selected.length > 0) {
          for (let i = 0; i < this.selected.length; i++) {
            this.selected.pop();
          }
        }

        for (let i = 0; i < this.respuestaApiAutorizarIncidencias.length; i++) {
          this.respuestaApiAutorizarIncidencias.pop();
        }

        this.respuestaApiAutorizarIncidencias = await this.getIncidenciasPorAutorizar();
        if (this.respuestaApiAutorizarIncidencias.length == 0) {
          this.$emit("validaEstadoInc", 0);
        }
      } else {
        this.textoBarra =
          "Error no pudimos modificar el estatus de la incidencia";
        this.colorBarra = "error";
        this.muestraBarra = true;
      }
      this.loadingBotonCancelar = false;
      this.loadingTabla = false;
    },
    autorizarIncidencias() {
      let id_seleccionados = [];
      this.respuestaApiAutorizarIncidencias.forEach((elemento) =>
        id_seleccionados.push(elemento["id_incidencia"])
      );
      var params = {
        Opcion: 2,
        token: localStorage.getItem("token"),
        idIncidencia: id_seleccionados,
        estatus: 2,
      };

      if (
        window.confirm("¿Realmente deseas autorizar todas las incidencias?")
      ) {
        this.loadingTabla = true;
        this.loadingBotonCancelar = true;
        axios
          .post(
            `${this.$store.getters.getHost}/Incidencias/ws-incidencias-autorizacion.php`,
            params
          )
          .then((response) => {
            var data = response.data.incidencias;
            this.respuestaIncidenciaCancelada = data;
            this.validaRespuestaModificacion();
          })
          .catch(function(e) {
            let result = e.toString().includes("401");
            if (result) {
              window.location.reload();
            } else {
              alert(e);
            }
          });
      }
      // }
    },
  },
};
</script>
<style lang="sass" scoped></style>
