<template>
  <div>
    <v-card class="rounded-xl">
      <v-card-title class="primary">
        <div class="subtitle-1 white--text text-uppercase font-weight-bold">
          Visitas por autorizar
        </div>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text class="pa-2">
        <v-data-table
          :headers="headers"
          :items="visitas"
          :footer-props="{
            'items-per-page-options': [5, 10],
            'items-per-page-text': 'Elementos por página',
          }"
          no-data-text="No hay registros"
          :loading="loadingTabla"
          loading-text="Cargando..."
          item-key="id"
          dense
          :single-expand="true"
          :expanded.sync="expanded"
          show-expand
        >
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <!-- More info about {{ item }} -->
              <div class="pa-5">
                <v-card
                  class="rounded-lg elevation-0"
                  color="blue-grey lighten-4"
                >
                  <v-simple-table dense style="background-color: transparent;">
                    <tbody>
                      <tr>
                        <th
                          class="caption text-uppercase text-right font-weight-bold"
                        >
                          Folio de visita
                        </th>
                        <td class="caption text-uppercase text-left">
                          {{ item.id }}
                        </td>
                      </tr>
                      <tr>
                        <th
                          class="caption text-uppercase text-right font-weight-bold"
                        >
                          Visitante
                        </th>
                        <td class="caption text-uppercase text-left">
                          {{
                            item.Nombre +
                              " " +
                              item.Paterno +
                              " " +
                              item.Materno
                          }}
                        </td>
                      </tr>
                      <tr
                        v-show="![undefined, null, ''].includes(item.Compania)"
                      >
                        <th
                          class="caption text-uppercase text-right font-weight-bold"
                        >
                          Compañía
                        </th>
                        <td class="caption text-uppercase text-left">
                          {{ item.Compania }}
                        </td>
                      </tr>
                      <tr>
                        <th
                          class="caption text-uppercase text-right font-weight-bold"
                        >
                          Fecha
                        </th>
                        <td class="caption text-uppercase text-left">
                          {{
                            [null, undefined, ""].includes(item.DiaFin)
                              ? fixDate(item.Dia.date.substring(0, 10))
                              : item.Dia.date.substring(0, 10) ==
                                item.DiaFin.date.substring(0, 10)
                              ? fixDate(item.Dia.date.substring(0, 10))
                              : fixDate(item.Dia.date.substring(0, 10)) +
                                " al " +
                                fixDate(item.DiaFin.date.substring(0, 10))
                          }}
                          -
                          {{
                            item.Entrada.date.substring(11, 16) +
                              " a " +
                              item.Salida.date.substring(11, 16)
                          }}
                        </td>
                      </tr>
                      <tr>
                        <th
                          class="caption text-uppercase text-right font-weight-bold"
                        >
                          Razón
                        </th>
                        <td class="caption text-uppercase text-left">
                          {{ item.Razon }}
                        </td>
                      </tr>
                      <tr>
                        <th
                          class="caption text-uppercase text-right font-weight-bold"
                        >
                          Ubicación
                        </th>
                        <td class="caption text-uppercase text-left">
                          {{ item.Ubicacion }}
                        </td>
                      </tr>
                      <tr>
                        <th
                          class="caption text-uppercase text-right font-weight-bold"
                        >
                          Acción
                        </th>
                        <td class="caption text-uppercase text-left">
                          {{
                            item.AccionVisitanteDescr +
                              " (" +
                              item.AccionDetalleVisitanteDescr +
                              ")"
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-card>
              </div>
            </td>
          </template>
          <template v-slot:item.Nombre="{ item }">
            {{ item.Nombre + " " + item.Paterno + " " + item.Materno }}
          </template>
          <template v-slot:item.actions="{ item }">
            <div style="min-width: 150px;">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="teal darken-1"
                    class="mx-1 rounded-pill"
                    x-small
                    dark
                    depressed
                    v-bind="attrs"
                    v-on="on"
                    @click="openConfirm(item)"
                  >
                    <v-icon small>mdi-check</v-icon>
                  </v-btn>
                </template>
                <span>Autorizar</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="pink darken-1"
                    class="mx-1 rounded-pill"
                    x-small
                    dark
                    depressed
                    v-bind="attrs"
                    v-on="on"
                    @click="openReject(item)"
                  >
                    <v-icon small>mdi-close</v-icon>
                  </v-btn>
                </template>
                <span>Rechazar</span>
              </v-tooltip>
            </div>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <DialogComentarioRechazo
      :commentReject="muestraDialogComentarioRechazo"
      @ocultaDialogComentario="ocultaDialogComentarioRechazo"
    />

    <ConfirmDialogReclutamiento
      v-if="showConfirm"
      :showConfirm="showConfirm"
      :question="question"
      @cierraConfirm="cierraConfirm"
    />

    <SnackBar
    v-if="muestraBarra"
      :muestraBarra="muestraBarra"
      :texto="textoBarra"
      :color="colorBarra"
      @cierraBarra="muestraBarra = false"
    />
  </div>
</template>

<script>
import axios from "axios";
import { formatDate } from "../../../../../store/Funciones/funciones";
import DialogComentarioRechazo from "../../../AdmnVacantes/components/DialogComentarioRechazo.vue";
import ConfirmDialogReclutamiento from "../../../AdmnVacantes/components/ConfirmDialogReclutamiento.vue";
import SnackBar from "../../MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";

export default {
  name: "persona-autoriza-cantes",
  props: ["showAuthVacancy"],
  components: {
    DialogComentarioRechazo,
    ConfirmDialogReclutamiento,
    SnackBar,
  },
  data() {
    return {
      headers: [
        {
          value: "id",
          text: "id",
          class:
            "text-uppercase font-weight-bold blue-grey--text text--darken-1",
        },
        {
          value: "Nombre",
          text: "Nombre",
          class:
            "text-uppercase font-weight-bold blue-grey--text text--darken-1",
        },
        {
          value: "Razon",
          text: "Razon",
          class:
            "text-uppercase font-weight-bold blue-grey--text text--darken-1",
        },
        {
          value: "Emplid",
          align: "center",
          text: "Solicitante",
          class:
            "text-uppercase font-weight-bold blue-grey--text text--darken-1",
        },
        {
          value: "actions",
          align: "center",
          text: "",
          class:
            "text-uppercase font-weight-bold blue-grey--text text--darken-1",
        },
      ],
      expanded: [],
      visitas: [],
      detalleVacante: {},
      loadingTabla: true,
      muestraDialogComentarioRechazo: false,
      showConfirm: false,
      loadingDetalle: false,
      muestraDetalle: false,
      muestraBarra: false,
      question: "¿Realmente deseas autorizar esta visita?",
      colorBarra: "",
      textoBarra: "",
      comentarioAux: "",
    };
  },
  async mounted() {
    this.visitas = await this.getVisitasPendientesPorAutrizar();
  },
  methods: {
    fixDate(date) {
      return formatDate(date);
    },
    muestraSnackBar(texto, color) {
      this.colorBarra = color;
      this.textoBarra = texto;
      this.muestraBarra = true;
    },
    async getVisitasPendientesPorAutrizar() {
      const params = {
        Opcion: 56,
        token: localStorage.getItem("token"),
      };

      return new Promise((resolve, reject) => {
        axios
          .post(
            `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-RegistroVisitantes.php`,
            params
          )
          .then((response) => {
            if (response.data.status == "OK") {
              resolve(response.data.respuesta);
            } else {
              resolve([]);
            }
          })
          .catch((error) => {
            reject(error);
          })
          .finally(() => {
            this.loadingTabla = false;
          });
      });
    },
    openDetails(id) {
      const params = {
        Opcion: 4,
        token: localStorage.getItem("token"),
        id: id,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/Reclutamiento/ws-admin-reclu.php`,
          params
        )
        .then((response) => {
          if (response.data.status != "EXPSESSION") {
            this.detalleVacante = response.data.detalle;
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loadingDetalle = false;
        });
    },
    formatMXN(cadena) {
      return cadena.toLocaleString("es-MX", {
        style: "currency",
        currency: "MXN",
      });
    },
    ocultaDialogComentarioRechazo(comentario) {
      this.muestraDialogComentarioRechazo = false;
      if (comentario != null) {
        this.loadingTabla = true;
        this.setEstatusVisitante(comentario);
      }
    },
    openReject(item) {
      (this.tokenAux = item.token),
        (this.idAux = item.id),
        (this.estatusAux = 2);
      this.muestraDialogComentarioRechazo = true;
    },
    openConfirm(item) {
      (this.tokenAux = item.token),
        (this.idAux = item.id),
        (this.estatusAux = 1);
      this.showConfirm = true;
    },
    cierraConfirm(respuesta) {
      if (respuesta) {
        this.showConfirm = false;
        this.loadingTabla = true;
        this.setEstatusVisitante();
      } else {
        this.showConfirm = false;
      }
    },
    setEstatusVisitante(comentario = "") {
      let params = {
        Opcion: 39,
        token: localStorage.getItem('token'),
        token_visita: this.tokenAux,
        idVisita: this.idAux,
        estatus: this.estatusAux,
        comentario: comentario,
        password: this.tokenAux,
        esAdmin: true,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-RegistroVisitantes.php`,
          params
        )
        .then(async (response) => {
          if (response.data.status != "EXPSESSION") {
            if (response.data.respuesta.status_tran == "OK") {
              this.muestraSnackBar(response.data.respuesta.message, "primary");
              this.visitas = await this.getVisitasPendientesPorAutrizar();
              if (this.visitas.length == 0) {
                this.$emit("validaEstadoVis", 0);
              }
            } else {
              this.muestraSnackBar(
                response.data.respuesta.message,
                "pink darken-3"
              );
            }
          } else {
            this.muestraSnackBar("ERROR. No se pudo validar", "pink darken-3");
          }
        })
        .catch((e) => {
          this.muestraSnackBar(e, "pink darken-3");
        })
        .finally(() => {
          this.loadingTabla = false;
        });
    },
  },
};
</script>
