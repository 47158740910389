
<template>

  <v-list-item
    v-if="!nodeTree.nodes" link
    :to="url"
    no-action
    prepend-icon="mdi-account-circle"
    dense
  >
    <v-list-item-title>{{nodeTree.descripcion}}</v-list-item-title>
    <v-list-item-icon>
      <v-icon small>mdi-arrow-right-drop-circle</v-icon>
    </v-list-item-icon>
  </v-list-item>

  <v-list-group v-else
    :sub-group="level> 0"
    no-action
  >

    <template v-slot:activator>
      <v-list-item-content>
        <v-list-item-title>{{nodeTree.descripcion}}</v-list-item-title>
      </v-list-item-content>
    </template>

    <HeaderMenuCollapsed
      v-for="node in nodeTree.nodes"
      :key="node.descripcion"
      :submenu="node"
      :level="level + 1"
      :url="url +'/'+ node.url"
    />

  </v-list-group>

</template>
<script>
  export default {
    name: 'nav-header-menu-collapsed',
    components: {
      HeaderMenuCollapsed: () => import('./HeaderMenuCollapsed.vue'),
    },
    computed: {
      nodeTree() {
        return this.submenu;
      }
    },
    props: [
      'submenu',
      'level',
      'url'
    ],
    methods: {
    }
  }
</script>
<style lang="sass" scoped>

  .v-list-group .v-list-item
    padding-left: 66px
  
</style>