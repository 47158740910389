import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import '@mdi/font/css/materialdesignicons.css';

import es from 'vuetify/es5/locale/es'
import en from 'vuetify/es5/locale/es'

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        options: {
            customProperties: true,
        },
        themes: {
            light: {
                primary: '#0071BA', //'#0078BE',
                secondary: '#424242',
                accent: '#82B1FF',
                error: '#FF5252',
                info: '#757575',
                success: '#4CAF50',
                warning: '#FFC107',
                new_primary: '#c94d94'
            },
        },
        lang: {
            locales: { es, en },
            current: 'es',
        },
    },
    icons: {
        iconfont: 'mdi',
    },
});