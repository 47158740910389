<template>
  <v-dialog v-model="dialog" persistent fullscreen class="px-5">
    <v-card class="mx-5" v-if="!registroCandidato">
      <v-toolbar flat color="primary" dark>
        <v-toolbar-title>Actualización de datos</v-toolbar-title>
      </v-toolbar>
      <v-stepper v-model="e1" v-if="categorias.length > 0">
        <v-stepper-header>
          <div v-for="categoria in categorias" :key="categoria.id_categoria">
            <v-stepper-step
              :complete="e1 > categoria.id_categoria"
              :step="categoria.id_categoria"
            >
              <div class="caption">{{ categoria.abreviatura }}</div>
            </v-stepper-step>
          </div>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <v-card-text>
              <v-row>
                <v-col
                  class="py-0"
                  cols="12"
                  xl="4"
                  lg="4"
                  md="4"
                  sm="6"
                  xs="5"
                >
                  <v-text-field
                    class="mx-5"
                    v-model="formDomicilio.calle"
                    label="*Calle"
                  >
                  </v-text-field>
                </v-col>
                <v-col
                  class="py-0"
                  cols="12"
                  xl="4"
                  lg="4"
                  md="4"
                  sm="6"
                  xs="5"
                >
                  <v-text-field
                    class="mx-5"
                    v-model="formDomicilio.numExterior"
                    type="number"
                    label="*Numero Exterior"
                  >
                  </v-text-field>
                </v-col>
                <v-col
                  class="py-0"
                  cols="12"
                  xl="4"
                  lg="4"
                  md="4"
                  sm="6"
                  xs="5"
                >
                  <v-text-field
                    class="mx-5"
                    v-model="formDomicilio.numInterior"
                    label="Numero Interior"
                  >
                  </v-text-field>
                </v-col>
                <v-col
                  class="py-0"
                  cols="12"
                  xl="4"
                  lg="4"
                  md="4"
                  sm="6"
                  xs="5"
                >
                  <v-text-field
                    class="mx-5"
                    v-model="formDomicilio.cp"
                    label="*Código postal"
                    counter
                    maxlength="5"
                    type="number"
                    :rules="[rulesCP.required, rulesCP.counter]"
                    v-on:change="getInfoGeografico()"
                  >
                  </v-text-field>
                </v-col>
                <v-col
                  class="py-0"
                  cols="12"
                  xl="4"
                  lg="4"
                  md="4"
                  sm="6"
                  xs="5"
                >
                  <v-select
                    :items="colonias"
                    class="mx-5"
                    v-model="formDomicilio.colonia"
                    label="*Colonia"
                  >
                  </v-select>
                </v-col>
                <v-col
                  class="py-0"
                  cols="12"
                  xl="4"
                  lg="4"
                  md="4"
                  sm="6"
                  xs="5"
                >
                  <v-text-field
                    class="mx-5"
                    v-model="formDomicilio.entreCalles"
                    label="*Entre las calles"
                  >
                  </v-text-field>
                </v-col>
                <v-col
                  class="py-0"
                  cols="12"
                  xl="4"
                  lg="4"
                  md="4"
                  sm="6"
                  xs="5"
                >
                  <v-text-field
                    class="mx-5"
                    v-model="formDomicilio.municipio"
                    label="*Municipio"
                  >
                  </v-text-field>
                </v-col>
                <v-col
                  class="py-0"
                  cols="12"
                  xl="4"
                  lg="4"
                  md="4"
                  sm="6"
                  xs="5"
                >
                  <v-text-field
                    class="mx-5"
                    v-model="formDomicilio.estado"
                    label="*Estado"
                  >
                  </v-text-field>
                </v-col>
                <v-col
                  class="py-0"
                  cols="12"
                  xl="2"
                  lg="2"
                  md="2"
                  sm="6"
                  xs="6"
                >
                  <v-tooltip top color="grey" dark>
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        class="mx-5"
                        v-model="formDomicilio.anioArraigo"
                        type="number"
                        label="año(s)"
                        v-bind="attrs"
                        v-on="on"
                      >
                      </v-text-field>
                    </template>
                    <span>¿Cúanto tiempo tienes viviendo en el domicilio?</span>
                  </v-tooltip>
                </v-col>

                <v-col
                  class="py-0"
                  cols="12"
                  xl="2"
                  lg="2"
                  md="2"
                  sm="6"
                  xs="6"
                >
                  <v-tooltip top color="grey" dark>
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        class="mx-5"
                        v-model="formDomicilio.mesArraigo"
                        type="number"
                        label="mes(es)"
                        min="1"
                        max="11"
                        v-bind="attrs"
                        v-on="on"
                      >
                      </v-text-field>
                    </template>
                    <span>¿Cúanto tiempo tienes viviendo en el domicilio?</span>
                  </v-tooltip>
                </v-col>
                <v-col
                  class="py-0"
                  cols="12"
                  xl="4"
                  lg="4"
                  md="4"
                  sm="6"
                  xs="5"
                >
                  <v-text-field
                    class="mx-5"
                    v-model="formDomicilio.celular"
                    type="number"
                    label="*Celular"
                    counter="10"
                  >
                  </v-text-field>
                </v-col>
                <v-col
                  class="py-0"
                  cols="12"
                  xl="4"
                  lg="4"
                  md="4"
                  sm="6"
                  xs="5"
                >
                  <v-text-field
                    class="mx-5"
                    v-model="formDomicilio.telefono"
                    label="Telefono"
                  >
                  </v-text-field>
                </v-col>
                <v-col
                  class="py-0"
                  cols="12"
                  xl="4"
                  lg="4"
                  md="4"
                  sm="6"
                  xs="5"
                >
                  <v-text-field
                    class="mx-5"
                    v-model="formDomicilio.mail"
                    label="*Correo Electronico Personal"
                    type="mail"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-card-text>

            <v-divider></v-divider>
            <v-card-actions class="d-flex justify-end">
              <v-btn class="rounded-lg" color="primary" @click="nextStep1()">
                Continuar
              </v-btn>
            </v-card-actions>
          </v-stepper-content>

          <v-stepper-content step="2">
            <v-card-text>
              <v-row>
                <v-col
                  class="py-0"
                  cols="12"
                  xl="4"
                  lg="4"
                  md="4"
                  sm="6"
                  xs="5"
                >
                  <v-text-field
                    class="mx-5"
                    v-model="formDatos.nacionalidad"
                    label="*Nacionalidad"
                  >
                  </v-text-field>
                </v-col>
                <v-col
                  class="py-0"
                  cols="12"
                  xl="4"
                  lg="4"
                  md="4"
                  sm="6"
                  xs="5"
                >
                  <v-text-field
                    class="mx-5"
                    v-model="formDatos.lugarNacimiento"
                    label="*Lugar de nacimiento"
                  >
                  </v-text-field>
                </v-col>
                <v-col
                  class="py-0"
                  cols="12"
                  xl="4"
                  lg="4"
                  md="4"
                  sm="6"
                  xs="5"
                >
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="formDatos.fechaNacimiento"
                        label="*Fecha de nacimiento"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      >
                      </v-text-field>
                    </template>
                    <v-date-picker
                      v-model="formDatos.fechaNacimiento"
                      :active-picker.sync="activePicker"
                      :max="
                        new Date(
                          Date.now() - new Date().getTimezoneOffset() * 60000
                        )
                          .toISOString()
                          .substr(0, 10)
                      "
                      min="1950-01-01"
                      v-on:change="save"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col
                  class="py-0"
                  cols="12"
                  xl="4"
                  lg="4"
                  md="4"
                  sm="6"
                  xs="5"
                >
                  <v-select
                    :items="estadoCivil"
                    v-model="formDatos.estadoCivil"
                    v-on:change="parejaColaborador(formDatos.estadoCivil)"
                    item-text="mensaje"
                    item-value="id"
                    label="*Estado civil"
                    dense
                  >
                  </v-select>
                </v-col>
              </v-row>
            </v-card-text>

            <v-divider></v-divider>
            <v-card-actions class="d-flex justify-end">
              <v-btn color="primary" @click="nextStep1()"> Continuar </v-btn>
            </v-card-actions>
          </v-stepper-content>

          <v-stepper-content step="3">
            <v-card-text>
              <v-row>
                <v-col
                  class="py-0"
                  cols="12"
                  xl="4"
                  lg="4"
                  md="4"
                  sm="6"
                  xs="5"
                >
                  <v-text-field
                    class="mx-5"
                    v-model="formIdentificacion.nss"
                    type="number"
                    label="*Numero Seguro Social"
                    counter
                    maxlength="11"
                    :rules="[rulesNSS.required, rulesNSS.counter]"
                  >
                  </v-text-field>
                </v-col>
                <v-col
                  class="py-0"
                  cols="12"
                  xl="4"
                  lg="4"
                  md="4"
                  sm="6"
                  xs="5"
                >
                  <v-text-field
                    class="mx-5"
                    v-model="formIdentificacion.curp"
                    label="*CURP"
                    counter
                    maxlength="18"
                    :rules="[rulesCURP.required, rulesCURP.counter]"
                  >
                  </v-text-field>
                </v-col>
                <v-col
                  class="py-0"
                  cols="12"
                  xl="4"
                  lg="4"
                  md="4"
                  sm="6"
                  xs="5"
                >
                  <v-text-field
                    class="mx-5"
                    v-model="formIdentificacion.rfc"
                    label="*RFC"
                    counter
                    maxlength="13"
                    :rules="[rulesRFC.required, rulesRFC.counter]"
                  >
                  </v-text-field>
                </v-col>
                <v-col
                  class="py-0"
                  cols="12"
                  xl="4"
                  lg="4"
                  md="4"
                  sm="6"
                  xs="5"
                >
                  <v-text-field
                    v-model="formIdentificacion.ine"
                    class="mx-5"
                    type="number"
                    prefix="IDMEX"
                    label="*Identificación (INE o IFE)"
                    :rules="rulesINE"
                    counter="10"
                  >
                  </v-text-field>
                </v-col>
                <v-col
                  class="py-0"
                  cols="12"
                  xl="3"
                  lg="3"
                  md="3"
                  sm="6"
                  xs="5"
                >
                  <v-select
                    :items="anioVigencia"
                    v-model="formIdentificacion.ineVigencia"
                    label="*Año de vigencia"
                  >
                  </v-select>
                </v-col>
                <v-col
                  class="py-0"
                  cols="12"
                  xl="5"
                  lg="5"
                  md="5"
                  sm="6"
                  xs="5"
                >
                  <v-radio-group
                    v-model="formIdentificacion.licenciaManejo"
                    row
                    class="mx-5"
                  >
                    <template v-slot:label>
                      <div>*Licencia de manejo</div>
                    </template>
                    <v-radio
                      v-for="cerrada in opcionesCerradas"
                      :key="cerrada.id"
                      :label="`${cerrada.mensaje}`"
                      :value="cerrada.id"
                    >
                    </v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-card-text>

            <v-divider></v-divider>
            <v-card-actions class="d-flex justify-end">
              <v-btn color="primary" @click="nextStep1()"> Continuar </v-btn>
            </v-card-actions>
          </v-stepper-content>

          <v-stepper-content step="4">
            <v-card-text>
              <v-row>
                <v-col
                  class="py-0"
                  cols="12"
                  xl="4"
                  lg="4"
                  md="4"
                  sm="4"
                  xs="5"
                >
                  <v-select
                    :items="escolaridad"
                    v-model="formEscolaridad.nivelEstudios"
                    item-text="descr"
                    item-value="id"
                    label="*Ultimo nivel de estudios"
                  >
                  </v-select>
                </v-col>
                <v-col
                  class="py-0"
                  cols="12"
                  xl="4"
                  lg="4"
                  md="4"
                  sm="4"
                  xs="5"
                >
                  <v-text-field
                    class="mx-5"
                    v-model="formEscolaridad.carrera"
                    label="*Carrera"
                  >
                  </v-text-field>
                </v-col>
                <v-col
                  class="py-0"
                  cols="12"
                  xl="4"
                  lg="4"
                  md="4"
                  sm="4"
                  xs="5"
                >
                  <v-text-field
                    class="mx-5"
                    v-model="formEscolaridad.nombre"
                    label="*Nombre del Instituto"
                  >
                  </v-text-field>
                </v-col>
                <v-col
                  class="py-0"
                  cols="12"
                  xl="4"
                  lg="4"
                  md="4"
                  sm="4"
                  xs="5"
                >
                  <v-dialog
                    ref="dialog"
                    v-model="dialogFechasEscolaridad"
                    :return-value.sync="date"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="formEscolaridad.fechaEscolaridad"
                        label="Termino de ultimo nivel de estudios"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="formEscolaridad.fechaEscolaridad"
                      type="month"
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="
                          dialogFechasEscolaridad = false;
                          formEscolaridad.fechaEscolaridad = '';
                        "
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="dialogFechasEscolaridad = false"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                <v-col
                  class="px-5 py-0"
                  cols="12"
                  xs="4"
                  sm="4"
                  md="4"
                  lg="12"
                  xl="12"
                >
                  <v-radio-group
                    v-model="formEscolaridad.tituladoEscolaridad"
                    row
                  >
                    <template v-slot:label>
                      <div>Titulado</div>
                    </template>
                    <v-radio
                      v-for="item in opcionesCerradas"
                      :key="item.id"
                      :label="item.mensaje"
                      :value="item.id"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="d-flex justify-end">
              <v-btn color="primary" @click="nextStep1()"> Continuar </v-btn>
            </v-card-actions>
          </v-stepper-content>

          <v-stepper-content step="5">
            <v-card-text>
              <v-card-subtitle>*Idiomas</v-card-subtitle>
              <v-divider></v-divider>
              <v-row class="pt-5">
                <v-col
                  class="py-0"
                  cols="12"
                  xl="8"
                  lg="8"
                  md="8"
                  sm="6"
                  xs="5"
                >
                  <v-radio-group
                    v-model="formIdiomas.saberIdioma"
                    row
                    class="mx-5"
                  >
                    <v-radio
                      v-for="cerrada in otroIdioma"
                      :key="cerrada.id"
                      v-on:change="showForm(cerrada.id)"
                      :label="`${cerrada.mensaje}`"
                      :value="cerrada.id"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col
                  class="py-0"
                  cols="12"
                  xl="4"
                  lg="4"
                  md="4"
                  sm="6"
                  xs="5"
                >
                  <v-text-field
                    v-model="formIdiomas.numeroIdiomas"
                    class="mx-5"
                    min="1"
                    type="number"
                    label="¿Cuantos idiomas domina?"
                    v-if="showFormIdioma"
                    v-on:change="addNumIdiomas(formIdiomas.numeroIdiomas)"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <div v-if="numIdiomas.length > 0">
                <v-row v-for="(item, i) in numIdiomas" :key="i">
                  <v-col
                    class="py-0 d-flex align-center"
                    cols="12"
                    xl="3"
                    lg="3"
                    md="3"
                    sm="6"
                    xs="5"
                  >
                    <v-text-field
                      v-model="item.nombreOtroIdioma"
                      class="mx-5"
                      label="*Nombre idioma "
                    >
                    </v-text-field>
                  </v-col>
                  <v-col
                    class="py-0 d-flex align-center"
                    cols="12"
                    xl="3"
                    lg="3"
                    md="3"
                    sm="6"
                    xs="5"
                  >
                    <v-slider
                      v-model="item.hablaIdioma"
                      :thumb-size="24"
                      thumb-label="always"
                      step="10"
                      ticks="always"
                      label="*% Habla"
                      min="0"
                      max="100"
                      color="blue darken-1"
                    >
                    </v-slider>
                  </v-col>
                  <v-col
                    class="py-0 d-flex align-center"
                    cols="12"
                    xl="3"
                    lg="3"
                    md="3"
                    sm="6"
                    xs="5"
                  >
                    <v-slider
                      v-model="item.leeIdioma"
                      :thumb-size="24"
                      thumb-label="always"
                      step="10"
                      ticks="always"
                      label="*% Lectura"
                      min="0"
                      max="100"
                      color="blue darken-1"
                    >
                    </v-slider>
                  </v-col>
                  <v-col
                    class="py-0 d-flex align-center"
                    cols="12"
                    xl="3"
                    lg="3"
                    md="3"
                    sm="6"
                    xs="5"
                  >
                    <v-slider
                      v-model="item.escribeIdioma"
                      :thumb-size="24"
                      thumb-label="always"
                      step="10"
                      ticks="always"
                      label="*% Escritura"
                      min="0"
                      max="100"
                      color="blue darken-1"
                    >
                    </v-slider>
                  </v-col>
                </v-row>
              </div>
            </v-card-text>
            <v-card class="rounded-lg my-5" elevation="3">
              <v-card-subtitle>*Cursos realizados</v-card-subtitle>
              <v-divider></v-divider>
              <v-row class="pt-5">
                <v-col
                  class="py-2"
                  cols="12"
                  xl="8"
                  lg="8"
                  md="8"
                  sm="6"
                  xs="6"
                >
                  <v-radio-group
                    v-model="formIdiomas.cursoRealizado"
                    row
                    class="mx-5"
                  >
                    <v-radio
                      v-for="cerrada in cursos"
                      :key="cerrada.id"
                      v-on:change="showFormCurso(cerrada.id)"
                      :label="`${cerrada.mensaje}`"
                      :value="cerrada.id"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col
                  class="py-0"
                  cols="12"
                  xl="4"
                  lg="4"
                  md="4"
                  sm="6"
                  xs="5"
                >
                  <v-text-field
                    v-model="formIdiomas.numeroCursos"
                    class="mx-5"
                    min="1"
                    type="number"
                    label="¿Cuantos cursos ha realizado?"
                    v-if="showNombreCurso"
                    v-on:change="addNumCursos(formIdiomas.numeroCursos)"
                  >
                  </v-text-field>
                </v-col>
                <div v-if="numCursos.length > 0 && showNombreCurso">
                  <v-row v-for="(item, i) in numCursos" :key="i">
                    <v-col
                      class="py-2"
                      cols="12"
                      xl="4"
                      lg="4"
                      md="4"
                      sm="6"
                      xs="6"
                    >
                      <v-text-field
                        v-model="item.nombreCurso"
                        class="mx-5"
                        v-if="showNombreCurso"
                        label="Nombre del curso"
                      >
                      </v-text-field>
                    </v-col>

                    <v-col
                      class="py-0"
                      cols="12"
                      xl="4"
                      lg="4"
                      md="4"
                      sm="6"
                      xs="5"
                    >
                      <v-dialog
                        ref="dialog1"
                        v-model="item.dialogFechasCurso"
                        :return-value.sync="date"
                        persistent
                        width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="item.fechaCurso"
                            label="Termino de curso"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="item.fechaCurso"
                          type="month"
                          scrollable
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            color="primary"
                            @click="
                              item.dialogFechasCurso = false;
                              item.fechaCurso = '';
                            "
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="item.dialogFechasCurso = false"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-dialog>
                    </v-col>
                    <v-col
                      class="px-5 py-0"
                      cols="12"
                      xs="12"
                      sm="12"
                      md="4"
                      lg="4"
                      xl="4"
                    >
                      <v-radio-group v-model="item.certificado" row>
                        <template v-slot:label>
                          <div>¿Cuenta con certificado o reconocimiento?</div>
                        </template>
                        <v-radio
                          v-for="item in opcionesCerradas"
                          :key="item.id"
                          :label="item.mensaje"
                          :value="item.id"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </div>
              </v-row>
            </v-card>
            <v-divider></v-divider>
            <v-card-actions class="d-flex justify-end">
              <v-btn color="primary" @click="nextStep1()"> Continuar </v-btn>
            </v-card-actions>
          </v-stepper-content>

          <v-stepper-content step="6">
            <v-row>
              <v-col class="py-2" cols="12" xl="4" lg="4" md="4" sm="6" xs="6">
                <v-autocomplete
                  :items="padecimientos"
                  v-model="formSalud.enfermedad"
                  item-text="descripcion"
                  item-value="id"
                  label="*¿Padece alguno de los siguientes casos?"
                  clearable
                  multiple
                  dense
                >
                </v-autocomplete>
              </v-col>
              <v-col class="py-2" cols="12" xl="4" lg="4" md="4" sm="6" xs="6">
                <v-autocomplete
                  :items="selectIdentificacionPersonal"
                  v-model="formSalud.identificacionPersonal"
                  item-text="descripcion"
                  multiple
                  item-value="id"
                  label="*¿Podrías identificarte con alguna opción?"
                  clearable
                  dense
                >
                </v-autocomplete>
              </v-col>
              <v-col class="py-2" cols="12" xl="2" lg="2" md="2" sm="3" xs="3">
                <v-autocomplete
                  :items="estatura"
                  v-model="formSalud.estatura"
                  item-text="mensaje"
                  item-value="id"
                  label="*Estatura"
                  clearable
                  dense
                >
                </v-autocomplete>
              </v-col>
              <v-col class="py-2" cols="12" xl="2" lg="2" md="2" sm="3" xs="3">
                <v-text-field
                  v-model="formSalud.peso"
                  class="mx-5"
                  type="number"
                  min="0"
                  suffix="KG"
                  label="*Peso"
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col class="py-2" cols="12" xl="4" lg="4" md="4" sm="6" xs="6">
                <v-text-field
                  v-model="formSalud.numeroEmergencia"
                  class="mx-5"
                  dense
                  type="number"
                  label="*Numero de emergencia"
                  counter="10"
                >
                </v-text-field>
              </v-col>
              <v-col class="py-2" cols="12" xl="4" lg="4" md="4" sm="6" xs="6">
                <v-text-field
                  v-model="formSalud.nombreNumeroEmergencia"
                  class="mx-5"
                  dense
                  label="*Nombre"
                >
                </v-text-field>
              </v-col>
              <v-col class="py-2" cols="12" xl="4" lg="4" md="4" sm="6" xs="6">
                <v-text-field
                  v-model="formSalud.parentesco"
                  class="mx-5"
                  dense
                  label="*Parentesco"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-0">
              <v-col class="py-2" cols="12" xl="4" lg="4" md="4" sm="4" xs="4">
                <v-radio-group
                  v-model="formSalud.realizaEjercicio"
                  row
                  class="mx-5"
                >
                  <template v-slot:label>
                    <div>*Actividad fisica</div>
                  </template>
                  <v-radio
                    v-for="cerrada in opcionesSalud"
                    :key="cerrada.id"
                    :label="`${cerrada.mensaje}`"
                    :value="cerrada.id"
                    v-on:change="showFormSalud(cerrada.id)"
                  >
                  </v-radio>
                </v-radio-group>
              </v-col>
              <v-col class="py-2" cols="12" xl="8" lg="8" md="8" sm="8" xs="8">
                <v-autocomplete
                  :items="actFisica"
                  v-model="formSalud.nombreEjercicio"
                  item-text="nombre"
                  v-if="showNombreEjercicio"
                  item-value="id"
                  label="*Seleccione las actividades que realice"
                  clearable
                  multiple
                >
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-2" cols="12" xl="4" lg="4" md="4" sm="4" xs="4">
                <v-radio-group v-model="formSalud.tabaco" row class="mx-5">
                  <template v-slot:label>
                    <div>*Tabaco</div>
                  </template>
                  <v-radio
                    v-for="cerrada in opcionesSalud"
                    :key="cerrada.id"
                    :label="`${cerrada.mensaje}`"
                    :value="cerrada.id"
                  >
                  </v-radio>
                </v-radio-group>
              </v-col>
              <v-col
                v-if="formSalud.tabaco == 1"
                class="py-2"
                cols="12"
                xl="2"
                lg="2"
                md="2"
                sm="2"
                xs="2"
              >
                <v-text-field
                  v-model="formSalud.edadInicioTabaco"
                  class="mx-5"
                  type="number"
                  label="*Edad inicio"
                >
                </v-text-field>
              </v-col>
              <v-col
                v-if="formSalud.tabaco == 1"
                class="py-2"
                cols="12"
                xl="3"
                lg="3"
                md="3"
                sm="3"
                xs="3"
              >
                <v-text-field
                  v-model="formSalud.frecuenciaTabaco"
                  class="mx-5"
                  label="*Frecuencia"
                >
                </v-text-field>
              </v-col>
              <v-col
                v-if="formSalud.tabaco == 1"
                class="py-2"
                cols="12"
                xl="3"
                lg="3"
                md="3"
                sm="3"
                xs="3"
              >
                <v-text-field
                  v-model="formSalud.ultimoConsumoTabaco"
                  class="mx-5"
                  label="*Ultima vez consumo"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-0">
              <v-col class="py-2" cols="12" xl="4" lg="4" md="4" sm="4" xs="4">
                <v-radio-group v-model="formSalud.alcohol" row class="mx-5">
                  <template v-slot:label>
                    <div>*Alcohol</div>
                  </template>
                  <v-radio
                    v-for="cerrada in opcionesSalud"
                    :key="cerrada.id"
                    :label="`${cerrada.mensaje}`"
                    :value="cerrada.id"
                  >
                  </v-radio>
                </v-radio-group>
              </v-col>

              <v-col
                v-if="formSalud.alcohol == 1"
                class="py-2"
                cols="12"
                xl="2"
                lg="2"
                md="2"
                sm="2"
                xs="2"
              >
                <v-text-field
                  v-model="formSalud.edadInicioAlcohol"
                  class="mx-5"
                  type="number"
                  label="*Edad inicio"
                >
                </v-text-field>
              </v-col>
              <v-col
                v-if="formSalud.alcohol == 1"
                class="py-2"
                cols="12"
                xl="3"
                lg="3"
                md="3"
                sm="3"
                xs="3"
              >
                <v-text-field
                  v-model="formSalud.frecuenciaAlcohol"
                  class="mx-5"
                  label="*Frecuencia"
                >
                </v-text-field>
              </v-col>
              <v-col
                v-if="formSalud.alcohol == 1"
                class="py-2"
                cols="12"
                xl="3"
                lg="3"
                md="3"
                sm="3"
                xs="3"
              >
                <v-text-field
                  v-model="formSalud.ultimoConsumoAlcohol"
                  class="mx-5"
                  label="*Ultima vez consumo"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-0">
              <v-col class="py-2" cols="12" xl="4" lg="4" md="4" sm="4" xs="4">
                <v-radio-group v-model="formSalud.drogas" row class="mx-5">
                  <template v-slot:label>
                    <div>*Drogas</div>
                  </template>
                  <v-radio
                    v-for="cerrada in opcionesSalud"
                    :key="cerrada.id"
                    :label="`${cerrada.mensaje}`"
                    :value="cerrada.id"
                  >
                  </v-radio>
                </v-radio-group>
              </v-col>

              <v-col
                v-if="formSalud.drogas == 1"
                class="py-2"
                cols="12"
                xl="2"
                lg="2"
                md="2"
                sm="2"
                xs="2"
              >
                <v-text-field
                  v-model="formSalud.edadInicioDrogas"
                  class="mx-5"
                  type="number"
                  label="*Edad inicio"
                >
                </v-text-field>
              </v-col>
              <v-col
                v-if="formSalud.drogas == 1"
                class="py-2"
                cols="12"
                xl="3"
                lg="3"
                md="3"
                sm="3"
                xs="3"
              >
                <v-text-field
                  v-model="formSalud.frecuenciaDrogas"
                  class="mx-5"
                  label="*Frecuencia"
                >
                </v-text-field>
              </v-col>
              <v-col
                v-if="formSalud.drogas == 1"
                class="py-2"
                cols="12"
                xl="3"
                lg="3"
                md="3"
                sm="3"
                xs="3"
              >
                <v-text-field
                  v-model="formSalud.ultimoConsumoDrogas"
                  class="mx-5"
                  label="*Ultima vez consumo"
                >
                </v-text-field>
              </v-col>
            </v-row>

            <v-divider></v-divider>
            <v-card-actions class="d-flex justify-end">
              <v-btn color="primary" @click="nextStep1()"> Continuar </v-btn>
            </v-card-actions>
          </v-stepper-content>

          <v-stepper-content step="7" style="background: #eceff1">
            <v-card class="rounded-lg">
              <v-data-table
                :headers="headers"
                :items="familias"
                mobile-breakpoint="0"
                :footer-props="{
                  'items-per-page-text': 'Elementos por página',
                }"
                no-data-text="No hay registros"
                class="elevation-1"
                hide-default-footer
                :items-per-page="familias.length"
              >
                <template v-slot:top>
                  <v-toolbar flat color="primary">
                    <v-toolbar-title class="white--text"
                      >Familiares</v-toolbar-title
                    >
                    <v-spacer></v-spacer>
                    <v-btn
                      class="rounded-lg elevation-0 ma-2"
                      color="purple"
                      small
                      dark
                      @click="addFamiliar({ parentesco: 'Hermano' })"
                    >
                      <v-icon x-small>mdi-plus</v-icon>
                      Hermano
                    </v-btn>
                    <v-btn
                      class="rounded-lg elevation-0 ma-2"
                      color="purple"
                      small
                      dark
                      @click="addFamiliar({ parentesco: 'hijo' })"
                    >
                      <v-icon x-small>mdi-plus</v-icon>
                      Hijo
                    </v-btn>
                  </v-toolbar>
                </template>
                <template v-slot:item.nombre="{ item }">
                  <v-text-field
                    v-model="item.nombre"
                    dense
                    label="Nombre"
                    v-on:change="validar(item)"
                  ></v-text-field>
                </template>
                <template v-slot:item.ocupacion="{ item }">
                  <v-text-field
                    v-model="item.ocupacion"
                    dense
                    label="¿A qué se dedica?"
                    v-on:change="validar(item)"
                  ></v-text-field>
                </template>
                <template v-slot:item.lugar="{ item }">
                  <v-text-field
                    v-model="item.lugar"
                    dense
                    label="Lugar donde labora"
                    v-on:change="validar(item)"
                  ></v-text-field>
                </template>
                <template v-slot:item.parentesco="{ item }">
                  <div align="left">{{ item.parentesco }}</div>
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-simple-checkbox
                    v-if="!item.loadingCheck"
                    v-model="item.actions"
                    color="success"
                    disabled
                  ></v-simple-checkbox>
                  <v-progress-circular
                    v-else
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </template>
              </v-data-table>
            </v-card>

            <v-divider></v-divider>
            <v-card-actions class="d-flex justify-end">
              <v-btn
                class="rounded-lg elevation-0"
                color="teal darken-1"
                dark
                :loading="loading"
                @click="confirmDialog = true"
              >
                Guardar
              </v-btn>
            </v-card-actions>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
      <v-dialog v-model="confirmDialog" max-width="700px" persistent>
        <v-card>
          <v-card-title class="text-subtitle-2 primary white--text">
            ¿Estás seguro de continuar con el registo?
            <v-spacer></v-spacer>
            <v-icon small dark @click="confirmDialog = false">mdi-close</v-icon>
          </v-card-title>
          <v-card-text>
            Revisa que hayas registrado a todos tus familiares cercanos, en caso
            de necesitar registrar un hermano o hijo, se encuentran los botones
            de
            <v-btn
              class="rounded-lg elevation-0 ma-2"
              color="purple"
              small
              dark
            >
              <v-icon x-small>mdi-plus</v-icon>
              Hermano
            </v-btn>
            e
            <v-btn
              class="rounded-lg elevation-0 ma-2"
              color="purple"
              small
              dark
            >
              <v-icon x-small>mdi-plus</v-icon>
              Hijo
            </v-btn>
            para poder agregar uno más a la lista.
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="teal darken-1" dark small @click="nextStep1()">
              Confirmar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <SnackBarErrorIncidencias
        v-if="muestraBarra"
        :muestraBarra="muestraBarra"
        :texto="textoBarra"
        :color="colorBarra"
        @cierraBarra="muestraBarra = false"
      />
    </v-card>
    <v-card v-else>
      <CargaDocumentosPersonales
        v-if="dialogCargaDocumento && !dialogAlerta"
        :dialog="dialogCargaDocumento"
        :mensaje="mensaje"
        :mensajeBtn="mensajeBtn"
        :datosInfo="datosInfo"
        @cierraAlertaDocumentos="dialogCargaDocumento = false;$emit('cierraAlertaDocumentos'); "
      />
    </v-card>
    <alerta-carga-documentos
      v-if="dialogAlerta && mensaje"
      :dialog="dialogAlerta"
      :mensaje="mensaje"
      :mensajeBtn="mensajeBtn"
      :datosInfo="datosInfo"
      @cierraAlertaDocumentos="dialogAlerta = false"
    />
  </v-dialog>
</template>

<script>
import axios from "axios";
import { deleteToken } from "../../../../../../store/Funciones/funciones";
import SnackBarErrorIncidencias from "../../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";
import AlertaCargaDocumentos from "./AlertaCargaDocumentos.vue";
import CargaDocumentosPersonales from "./CargaDocumentosPersonales.vue";
export default {
  name: "formulario-info-personal",
  components: {
    SnackBarErrorIncidencias,
    AlertaCargaDocumentos,
    CargaDocumentosPersonales,
  },
  props: ["dialog", "mensaje", "mensajeBtn", "datosInfo"],
  data() {
    return {
      textoBarra: "",
      colorBarra: "",
      muestraBarra: false,
      timeout: 2000,

      dialogAlerta: false,
      dialogCargaDocumento: false,
      registroCandidato: false,

      e1: 1,
      categorias: [],

      //RULES
      rulesCP: {
        required: (value) => !!value || "Campo obligatorio.",
        counter: (value) => value.length <= 5 || "Max 5 caracteres",
      },
      rulesNSS: {
        required: (value) => !!value || "Campo obligatorio.",
        counter: (value) => value.length <= 11 || "Max 11 caracteres",
      },
      rulesCURP: {
        required: (value) => !!value || "Campo obligatorio.",
        counter: (value) => value.length <= 18 || "Max 18 caracteres",
      },
      rulesRFC: {
        required: (value) => !!value || "Campo obligatorio.",
        counter: (value) => value.length <= 13 || "Max 13 caracteres",
      },
      rulesINE: [(v) => v.length <= 10 || "Deben ser 10 caracteres"],

      //STEP 1
      formDomicilio: {
        calle: "",
        numExterior: "",
        numInterior: "",
        colonia: "",
        cp: "",
        entreCalles: "",
        municipio: "",
        estado: "",
        anioArraigo: "",
        mesArraigo: "",
        celular: "",
        telefono: "",
        mail: "",
      },
      colonias: [],

      //STEP 2
      formDatos: {
        nacionalidad: "",
        fechaNacimiento: "",
        lugarNacimiento: "",
        estadoCivil: "",
      },
      date: null,
      menu: false,
      menu1: false,
      menu2: false,
      menu3: false,
      menu4: false,
      activePicker: null,
      estadoCivil: [],

      //STEP 3
      formIdentificacion: {
        nss: "",
        curp: "",
        rfc: "",
        licenciaManejo: "",
        ine: "",
        ineVigencia: "",
      },
      anioVigencia: [],
      opcionesCerradas: [],
      estatura: [],

      //SETP 4
      formEscolaridad: {
        nivelEstudios: "",
        carrera: "",
        nombre: "",
        fechaEscolaridad: "",
        tituladoEscolaridad: "",
        addMaestria: "2",
        tipoMaestria: "",
        nombreMaestria: "",
        nombreInstitutoMaestria: "",
        fechaMaestria: "",
        tituladoMaestria: "",
      },
      escolaridad: [],
      dialogFechasEscolaridad: false,

      //STEP 5
      formIdiomas: {
        numeroIdiomas: "",
        numeroCursos: "",
        saberIdioma: "",
        nombreOtroIdioma: "",
        //idimoa: '',
        //hablaIdioma: '',
        //leeIdioma: '',
        //escribeIdioma: '',
        cursoRealizado: "",
        cursoNombre: "",
      },
      showFormIdioma: false,
      numIdiomas: [],
      numCursos: [],
      showNombreCurso: false,
      cursos: [
        { id: 1, mensaje: "He realizado cursos" },
        { id: 2, mensaje: "No he realizado cursos" },
      ],
      otroIdioma: [
        { id: 1, mensaje: "Sé otro idioma" },
        { id: 2, mensaje: "No sé otro idioma" },
      ],

      //STEP 6
      actFisica: [],
      padecimientos: [
        { id: 1, descripcion: "Ninguna" },
        { id: 2, descripcion: "Diabetes" },
        { id: 3, descripcion: "Hipertensión" },
        { id: 4, descripcion: "Embarazo" },
        { id: 5, descripcion: "Uso de anteojos" },
      ],
      selectIdentificacionPersonal: [
        { id: 1, descripcion: "No" },
        { id: 2, descripcion: "Discapacidad motriz" },
        { id: 3, descripcion: "Discapacidad auditiva" },
        { id: 4, descripcion: "Discapacidad visual" },
        { id: 5, descripcion: "Discapacidad intelectual" },
        { id: 6, descripcion: "Elijo no responder" },
      ],

      opcionesSalud: [
        { id: 1, mensaje: "Si" },
        { id: 2, mensaje: "No" },
      ],
      formSalud: {
        estatura: "",
        peso: "",
        enfermedad: "",
        identificacionPersonal: "",
        tabaco: "",
        alcohol: "",
        drogas: "",
        numeroEmergencia: "",
        nombreNumeroEmergencia: "",
        edadInicioTabaco: "",
        frecuenciaTabaco: "",
        ultimoConsumoTabaco: "",
        edadInicioAlcohol: "",
        frecuenciaAlcohol: "",
        ultimoConsumoAlcohol: "",
        edadInicioDrogas: "",
        frecuenciaDrogas: "",
        ultimoConsumoDrogas: "",
        realizaEjercicio: "",
        nombreEjercicio: "",
        parentesco: "",
      },
      showNombreEjercicio: false,

      //STEP 7
      headers: [
        { text: "Nombre", value: "nombre", align: "center", sortable: true },
        {
          text: "Ocupación",
          value: "ocupacion",
          align: "center",
          sortable: true,
        },
        {
          text: "Lugar donde labora",
          value: "lugar",
          align: "center",
          sortable: true,
        },
        {
          text: "Parentesco",
          value: "parentesco",
          align: "center",
          sortable: true,
        },
        { text: "Acciones", value: "actions", align: "center", sortable: true },
      ],
      familias: [
        {
          nombre: "",
          parentesco: "Mamá",
          ocupacion: "",
          lugar: "",
          actions: false,
          loadingCheck: false,
        },
        {
          nombre: "",
          parentesco: "Papá",
          ocupacion: "",
          lugar: "",
          actions: false,
          loadingCheck: false,
        },
      ],
      confirmDialog: false,
    };
  },
  computed: {},
  mounted() {
    console.log(this.mensaje);
    this.getRegistroCandidato();
    this.getEstadoCivil();
    this.getLicenciaManejo();
    this.getEstaturaAnioVigencia();
    this.getEmpleado();
    this.getEscolaridad();
    this.getActFisica();
    if (this.dialog == true) {
      this.dialogAlerta = true;
    }
  },
  methods: {
    getPreguntas() {
      let params = {
        Opcion: 11,
        token: localStorage.getItem("token"),
      };
      axios
        .post(
          `${this.$store.getters.getHost}/RelacionesLaborales/DocumentacionEmpleados/ws-carga-documentos.php`,
          params
        )
        .then((response) => {
          if (response.data.status.trim() == "EXPSESSION") {
            deleteToken();
          } else if (response.data.status.trim() == "OK") {
            if (response.data.data) {
              this.categorias = response.data.data;
            }
          }
          this.loading = false;
        })
        .catch(function (e) {
          let result = e.toString().includes("401");
          if (result) {
            window.location.reload();
          } else {
            alert(e);
          }
        });
    },

    getRegistroCandidato() {
      let params = {
        Opcion: 10,
        token: localStorage.getItem("token"),
      };
      axios
        .post(
          `${this.$store.getters.getHost}/RelacionesLaborales/DocumentacionEmpleados/ws-carga-documentos.php`,
          params
        )
        .then((response) => {
          this.loadingInfo = false;
          if (response.data.status == "VACIO") {
            this.registroCandidato = false;
            this.getPreguntas();
            if (response.data.response.bandera) {
              this.e1 = response.data.response.bandera;
              this.parejaColaborador(response.data.response.EstadoCivil);
            }
          } else if (response.data.status == "OK") {
            this.registroCandidato = true;
            this.dialogCargaDocumento = true;
            console.log(this.dialogCargaDocumento);
            // this.getCompania();
          } else if (response.data.status == "EXPRESSION") {
            deleteToken();
          }
        })
        .catch(function (e) {
          alert("Ocurrió un error: " + e);
        });
    },

    parejaColaborador(item) {
      if (item == 2 || item == 4) {
        this.familias.push({
          nombre: "",
          parentesco: "Pareja",
          ocupacion: "",
          lugar: "",
          actions: false,
          loadingCheck: false,
        });
      }
    },

    getInfoGeografico() {
      this.formDomicilio.colonia = "";
      this.colonias = [];
      if (this.formDomicilio.cp) {
        let params = {
          Opcion: 1,
          CodigoPostal: this.formDomicilio.cp,
        };
        axios
          .post(
            `https://serviciosweb.urrea.com/API_codigo_postal/ws-general.php`,
            params
          )
          .then((response) => {
            this.formDomicilio.municipio = response.data.Ciudad;
            this.formDomicilio.estado = response.data.Estado;
            if (response.data.Colonias) {
              // for (let i in response.data.Colonias) {
              //     this.colonias.push(response.data.Colonias[i]);
              // }
              this.colonias = response.data.Colonias;
            }
          });
      }
    },

    nextStep1() {
      let mensaje = "Gracias, puede continuar con las siguientes preguntas";
      let validador = true;
      let completo = true;
      switch (this.e1) {
        case 1:
          if (
            !this.formDomicilio["calle"] ||
            !this.formDomicilio["colonia"] ||
            !this.formDomicilio["entreCalles"] ||
            !this.formDomicilio["municipio"] ||
            !this.formDomicilio["estado"]
          ) {
            mensaje =
              "Responde las preguntas para continuar a la siguiente sección";
            validador = false;
          } else if (
            !this.formDomicilio["numExterior"] ||
            !this.formDomicilio["cp"] ||
            !this.formDomicilio["anioArraigo"] ||
            !this.formDomicilio["celular"]
          ) {
            mensaje =
              "Escriba valores numericos en los campos correspondientes";
            validador = false;
          } else if (this.formDomicilio["cp"].length != 5) {
            mensaje = "Ingrese los 5 digitos del código postal";
            validador = false;
          } else if (this.formDomicilio["celular"].length != 10) {
            mensaje = "Ingrese los 10 digitos de su celular";
            validador = false;
          } else if (this.formDomicilio["mail"].includes("@") === false) {
            mensaje = "Debes ingresar un correo valido";
            validador = false;
          } else if (
            this.formDomicilio["mail"].toLowerCase().includes("@urrea.net")
          ) {
            mensaje = "Ingresa un correo personal";
            validador = false;
          } else if (this.formDomicilio["telefono"]) {
            if (isNaN(this.formDomicilio["telefono"]) == true) {
              mensaje = "Escriba valores numericos si ingresa Telefono de casa";
              validador = false;
            }
          }

          break;
        case 2:
          if (
            !this.formDatos["nacionalidad"] ||
            !this.formDatos["fechaNacimiento"] ||
            !this.formDatos["lugarNacimiento"] ||
            !this.formDatos["estadoCivil"]
          ) {
            mensaje =
              "Responde las preguntas para continuar a la siguiente sección";
            validador = false;
          }
          break;
        case 3:
          if (
            !this.formIdentificacion["curp"] ||
            !this.formIdentificacion["rfc"] ||
            !this.formIdentificacion["licenciaManejo"] ||
            !this.formIdentificacion["ineVigencia"]
          ) {
            mensaje =
              "Responde las preguntas para continuar a la siguiente sección";
            validador = false;
          } else if (!this.formIdentificacion["nss"]) {
            mensaje =
              "Escriba valores numericos en los campos correspondientes";
            validador = false;
          } else if (!this.formIdentificacion["ine"]) {
            mensaje =
              "Escriba valores numericos en los campos correspondientes";
            validador = false;
          } else if (
            this.formIdentificacion["ine"].length < 10 ||
            this.formIdentificacion["ine"].length > 10
          ) {
            mensaje =
              "Escriba los 10 numeros de su identificación ubicados atrás despues de la clave IDMEX";
            validador = false;
          } else if (this.formIdentificacion["rfc"].length != 13) {
            mensaje =
              "Por favor, complete el numero de caracteres solicitados en su RFC";
            validador = false;
          } else if (this.formIdentificacion["nss"].length != 11) {
            mensaje =
              "Por favor, complete el numero de caracteres solicitados en su NSS";
            validador = false;
          } else if (this.formIdentificacion["curp"].length != 18) {
            mensaje =
              "Por favor, complete el numero de caracteres solicitados en su CURP";
            validador = false;
          }

          break;
        case 4:
          if (
            !this.formEscolaridad["nivelEstudios"] ||
            !this.formEscolaridad["carrera"] ||
            !this.formEscolaridad["nombre"] ||
            !this.formEscolaridad["tituladoEscolaridad"] ||
            !this.formEscolaridad["fechaEscolaridad"]
          ) {
            mensaje =
              "Responde las preguntas para continuar a la siguiente sección";
            validador = false;
          }
          // if (this.formEscolaridad["addMaestria"] == 1) {
          //     if (!this.formEscolaridad["tipoMaestria"] || !this.formEscolaridad["nombreMaestria"] || !this.formEscolaridad["nombreInstitutoMaestria"] ||
          //         !this.formEscolaridad["fechaMaestria"] || !this.formEscolaridad["tituladoMaestria"]) {
          //         mensaje = "Selecciona y escribe la maestria o posgrado, en caso de no tener, por favor de clic en no para continuar";
          //         validador = false;
          //     }
          // }
          break;
        case 5:
          if (
            !this.formIdiomas["saberIdioma"] ||
            !this.formIdiomas["cursoRealizado"]
          ) {
            mensaje =
              "Responde las preguntas para continuar a la siguiente sección";
            validador = false;
          } else if (this.formIdiomas["saberIdioma"] == 1) {
            if (this.formIdiomas["idioma"] == "") {
              mensaje = "Seleccione una opción del idioma";
              validador = false;
            } else if (
              this.formIdiomas["idioma"] == 2 &&
              this.formIdiomas["nombreOtroIdioma"] == ""
            ) {
              mensaje = "Escriba el idioma que practica";
              validador = false;
            }
          } else if (
            this.formIdiomas["cursoRealizado"] == 1 &&
            this.formIdiomas["nombreCurso"] == ""
          ) {
            mensaje = "Escribe el nombre del curso";
            validador = false;
          }
          break;
        case 6:
          if (
            !this.formSalud["tabaco"] ||
            !this.formSalud["alcohol"] ||
            !this.formSalud["drogas"] ||
            !this.formSalud["estatura"] ||
            !this.formSalud["enfermedad"] ||
            !this.formSalud["identificacionPersonal"] ||
            !this.formSalud["nombreNumeroEmergencia"] ||
            !this.formSalud["realizaEjercicio"]
          ) {
            mensaje =
              "Responde las preguntas para continuar a la siguiente sección";
            validador = false;
          } else if (
            !this.formSalud["peso"] ||
            !this.formSalud["numeroEmergencia"]
          ) {
            mensaje =
              "Escriba valores numericos en los campos correspondientes";
            validador = false;
          } else if (this.formSalud["numeroEmergencia"].length != 10) {
            mensaje =
              "Ingrese los 10 digitos del numero de celular de emergencia ";
            validador = false;
          }
          break;
        case 7:
          this.familias.map((elemento) => {
            if (!elemento.actions) {
              completo = false;
            }
          });
          break;
      }
      if (validador === true) {
        let params = {};
        switch (this.e1) {
          case 1:
            params = {
              Opcion: 12,
              token: localStorage.getItem("token"),
              datos: this.formDomicilio,
              bandera: this.e1,
            };
            this.guardarPorCategorias(params);
            break;
          case 2:
            params = {
              Opcion: 12,
              token: localStorage.getItem("token"),
              datos: this.formDatos,
              bandera: this.e1,
            };
            this.guardarPorCategorias(params);
            break;
          case 3:
            params = {
              Opcion: 12,
              token: localStorage.getItem("token"),
              datos: this.formIdentificacion,
              bandera: this.e1,
            };
            this.guardarPorCategorias(params);
            break;
          case 4:
            params = {
              Opcion: 12,
              token: localStorage.getItem("token"),
              datos: this.formEscolaridad,
              bandera: this.e1,
            };
            this.guardarPorCategorias(params);
            break;
          case 5:
            this.formIdiomas.nombreOtroIdioma = this.numIdiomas;
            this.formIdiomas.cursoNombre = this.numCursos;
            params = {
              Opcion: 12,
              token: localStorage.getItem("token"),
              datos: this.formIdiomas,
              bandera: this.e1,
            };
            this.guardarPorCategorias(params);
            break;
          case 6:
            params = {
              Opcion: 12,
              token: localStorage.getItem("token"),
              datos: this.formSalud,
              bandera: this.e1,
            };
            this.guardarPorCategorias(params);
            break;
          case 7:
            if (completo == true) {
              params = {
                Opcion: 12,
                token: localStorage.getItem("token"),
                datos: this.familias,
                bandera: this.e1,
              };
              this.guardarPorCategorias(params);
            } else {
              this.textoBarra =
                "Por favor complete la tabla de familiares para continuar";
              this.colorBarra = "error";
              this.muestraBarra = true;
            }
            break;
        }
      } else {
        if (this.e1 < 7) {
          this.textoBarra = mensaje;
          this.colorBarra = "error";
          this.muestraBarra = true;
        } else {
          mensaje = "Responde las preguntas para guardar el registro";
          this.textoBarra = mensaje;
          this.colorBarra = "error";
          this.muestraBarra = true;
        }
      }
    },

    guardarPorCategorias(params) {
      this.loading = true;
      console.log(params);
      axios
        .post(
          `${this.$store.getters.getHost}/RelacionesLaborales/DocumentacionEmpleados/ws-carga-documentos.php`,
          params
        )
        .then((response) => {
          if (response.data.status.trim() == "EXPSESSION") {
            deleteToken();
          } else if (response.data.status.trim() == "OK") {
            this.textoBarra = "Datos guardados correctamente";
            this.colorBarra = "success";
            this.muestraBarra = true;
            if (response.data.bandera != 7) {
              this.e1++;
            } else {
              this.getRegistroCandidato();
              this.confirmDialog = false;
              this.registroCandidato = true;
              this.categorias = [];
            }
          } else {
            this.textoBarra = "Algo salió mal";
            this.colorBarra = "error";
            this.muestraBarra = true;
          }
          this.loading = false;
        });
    },

    //STEP 2
    save(date) {
      console.log(date);
      this.menu = false;
      this.menu1 = false;
      this.menu2 = false;
      this.menu3 = false;
      this.menu4 = false;
    },

    getEstadoCivil() {
      let params = {
        Opcion: 21,
        token: localStorage.getItem("token"),
      };
      axios
        .post(
          `${this.$store.getters.getHost}/RelacionesLaborales/DocumentacionEmpleados/ws-carga-documentos.php`,
          params
        )
        .then((response) => {
          if (response.data.status.trim() == "EXPSESSION") {
            deleteToken();
          } else if (response.data.status.trim() == "OK") {
            if (response.data.data) {
              this.estadoCivil = response.data.data;
            }
          }
        });
    },

    getLicenciaManejo() {
      let params = {
        Opcion: 22,
        token: localStorage.getItem("token"),
      };
      axios
        .post(
          `${this.$store.getters.getHost}/RelacionesLaborales/DocumentacionEmpleados/ws-carga-documentos.php`,
          params
        )
        .then((response) => {
          if (response.data.status.trim() == "EXPSESSION") {
            deleteToken();
          } else if (response.data.status.trim() == "OK") {
            if (response.data.data) {
              this.opcionesCerradas = response.data.data;
            }
          }
        });
    },

    //STEP 3
    getEstaturaAnioVigencia() {
      let count = 0;
      for (let a = 1.1; a < 2.5; a += 0.01) {
        this.estatura.push({
          id: Number.parseFloat(a).toFixed(2),
          mensaje: Number.parseFloat(a).toFixed(2) + " M",
        });
        if (count > 200) {
          break;
        }
        count++;
      }
      const fecha = new Date();
      const anioActual = fecha.getFullYear();
      let anio = anioActual;
      do {
        this.anioVigencia.push(anio);
        anio++;
      } while (anio != anioActual + 11);
    },

    getEmpleado() {
      let params = {
        Opcion: 18,
        token: localStorage.getItem("token"),
      };
      axios
        .post(
          `${this.$store.getters.getHost}/RelacionesLaborales/DocumentacionEmpleados/ws-carga-documentos.php`,
          params
        )
        .then((response) => {
          if (response.data.status.trim() == "EXPSESSION") {
            deleteToken();
          } else if (response.data.status.trim() == "OK") {
            if (response.data.colaborador) {
              this.formIdentificacion.rfc = response.data.colaborador.rfc;
              this.formIdentificacion.curp = response.data.colaborador.curp;
              this.formIdentificacion.nss = response.data.colaborador.imss;
            }
          }
        });
    },

    //STEP 4
    getEscolaridad() {
      let params = {
        Opcion: 30,
        token: localStorage.getItem("token"),
      };
      axios
        .post(`${this.$store.getters.getHost}/General/ws-general.php`, params)
        .then((response) => {
          if (response.data.status.trim() == "EXPSESSION") {
            deleteToken();
          } else if (response.data.status.trim() == "OK") {
            if (response.data.escolaridad) {
              this.escolaridad = response.data.escolaridad.carreras;
            }
          }
          this.loading = false;
        });
    },

    //STEP 5
    showForm(item) {
      console.log(item);
      if (item == 1) {
        this.showFormIdioma = true;
      } else {
        this.numIdiomas = [];
        this.showFormIdioma = false;
      }
    },

    addNumIdiomas(item) {
      this.numIdiomas = [];
      let contador = item;
      if (item || item > 0) {
        do {
          this.numIdiomas.push({
            nombreOtroIdioma: "",
            hablaIdioma: "",
            leeIdioma: "",
            escribeIdioma: "",
          });
          contador--;
        } while (contador != 0);
      } else {
        this.textoBarra = "Evita ingresar palabras en campos numericos";
        this.colorBarra = "error";
        this.muestraBarra = true;
      }
    },

    showFormCurso(item) {
      if (item == 1) {
        this.showNombreCurso = true;
      } else {
        this.showNombreCurso = false;
      }
    },

    addNumCursos(item) {
      this.numCursos = [];
      let contador = item;
      if (item || item > 0) {
        do {
          this.numCursos.push({
            nombreCurso: "",
            fechaCurso: "",
            certificado: "",
            dialogFechasCurso: false,
          });
          contador--;
        } while (contador != 0);
      } else {
        this.textoBarra = "Evita ingresar palabras en campos numericos";
        this.colorBarra = "error";
        this.muestraBarra = true;
      }
      console.log(item);
      console.log(this.numCursos);
    },

    //STEP 6
    showFormSalud(item) {
      if (item == 1) {
        this.showNombreEjercicio = true;
      } else {
        this.showNombreEjercicio = false;
      }
    },

    getActFisica() {
      let params = {
        Opcion: 19,
        token: localStorage.getItem("token"),
      };
      axios
        .post(
          `${this.$store.getters.getHost}/RelacionesLaborales/DocumentacionEmpleados/ws-carga-documentos.php`,
          params
        )
        .then((response) => {
          if (response.data.status.trim() == "EXPSESSION") {
            deleteToken();
          } else if (response.data.status.trim() == "OK") {
            if (response.data.actFisica) {
              this.actFisica = response.data.actFisica;
            }
          }
          this.loading = false;
        });
    },

    //STEP 7
    validar(item) {
      item.loadingCheck = true;
      if (item.lugar && item.ocupacion && item.nombre) {
        item.actions = true;
      } else {
        item.actions = false;
      }
      setTimeout(() => {
        item.loadingCheck = false;
      }, 1500);
    },

    addFamiliar(item) {
      if (item.parentesco === 'hijo') {
          this.familias.push({
              nombre: '',
              parentesco: 'Hijo',
              ocupacion: '',
              lugar: '',
              actions: false,
              loadingCheck: false
          },);
      } else if (item.parentesco === 'Hermano') {
          this.familias.push({ nombre: '', parentesco: 'Hermano', ocupacion: '', lugar: '', actions: false, loadingCheck: false },);
      } else if (item.parentesco === 'Pareja') {
          this.familias.push({ nombre: '', parentesco: 'Pareja', ocupacion: '', lugar: '', actions: false, loadingCheck: false },);
          this.disabledBtnPareja = true;
      }
    },
  },
};
</script>
