var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.ruta == 'notificacion' && !_vm.moduloError)?_c('v-dialog',{attrs:{"fullscreen":"","transition":"dialog-bottom-transition","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-subtitle-2 primary white--text"},[_vm._v(" Lista de solicitudes realizadas "),_c('v-spacer'),_c('v-icon',{attrs:{"small":"","dark":""},on:{"click":function($event){return _vm.$emit('cierraReporteInconformidades', 'cancelar')}}},[_vm._v("mdi-close")])],1),_c('v-card-text',{staticClass:"pt-5"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.inconformidades,"search":_vm.buscar,"loading":_vm.loadingInfo,"loading-text":"Cargando solicitudes","dense":"","mobile-breakpoint":"0","footer-props":{ 'items-per-page-text': 'Elementos por página' },"no-data-text":"No se encontraron solicitudes","item-key":"id"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"rounded-lg rounded-b-0 rounded-t-0",attrs:{"flat":""}},[_c('v-text-field',{staticClass:"mt-7 rounded-lg",attrs:{"label":_vm.$t('DH360.findTextBox'),"dense":"","clearable":"","outlined":"","solo-inverted":"","prepend-inner-icon":"mdi-table-search"},model:{value:(_vm.buscar),callback:function ($$v) {_vm.buscar=$$v},expression:"buscar"}}),_c('v-spacer')],1)]},proxy:true},{key:"item.nombre",fn:function(ref){
var item = ref.item;
return [_c('div',{attrs:{"align":"left"}},[_vm._v(_vm._s(item.nombre))])]}},{key:"item.diasTranscurridos",fn:function(ref){
var item = ref.item;
return [(item.diasTranscurridos > 1)?_c('div',[_vm._v(" "+_vm._s(item.diasTranscurridos)+" Días ")]):(item.diasTranscurridos == 1)?_c('div',[_vm._v(" "+_vm._s(item.diasTranscurridos)+" Día ")]):(item.diasTranscurridos == 0)?_c('div',[_vm._v("Hoy")]):_vm._e()]}},{key:"item.motivo",fn:function(ref){
var item = ref.item;
return [_c('div',{attrs:{"align":"left"}},[_vm._v(_vm._s(item.motivo))])]}},{key:"item.departamento",fn:function(ref){
var item = ref.item;
return [_c('div',{attrs:{"align":"left"}},[_vm._v(_vm._s(item.departamento))])]}},{key:"item.comentario",fn:function(ref){
var item = ref.item;
return [(item.muestraDesc == false)?_c('div',{staticClass:"text-truncate",staticStyle:{"max-width":"200px","cursor":"pointer"},attrs:{"align":"left"},on:{"click":function($event){return _vm.mostrarMas(item)}}},[_vm._v(" "+_vm._s(item.comentario)+" ")]):_c('div',{staticClass:"text-justify",staticStyle:{"cursor":"pointer"},attrs:{"align":"left"},on:{"click":function($event){return _vm.mostrarMas(item)}}},[_vm._v(" "+_vm._s(item.comentario)+" ")])]}},{key:"item.estatus",fn:function(ref){
var item = ref.item;
return [(item.estatus == 1)?_c('div',{staticClass:"d-flex justify-center"},[_c('v-chip-group',{attrs:{"column":""}},[_c('v-btn',{attrs:{"x-small":"","rounded":"","elevation":"1","color":"red","dark":""}},[_vm._v(" Abierta ")])],1)],1):(item.estatus == 2)?_c('div',{staticClass:"d-flex justify-center"},[_c('v-chip-group',{attrs:{"column":""}},[_c('v-btn',{attrs:{"x-small":"","rounded":"","elevation":"1","color":"light-green darken-1","dark":""}},[_vm._v(" Por calificar ")])],1)],1):(item.estatus == 3)?_c('div',{staticClass:"d-flex justify-center"},[_c('v-chip-group',{attrs:{"column":""}},[_c('v-btn',{attrs:{"x-small":"","rounded":"","elevation":"1","color":"teal darken-1","dark":""}},[_vm._v(" Cerrada ")])],1)],1):_vm._e()]}},{key:"item.nivelSatisfaccion",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center"},[(item.estatus == 2)?_c('v-chip-group',{attrs:{"column":""}},[(
                  item.nivelSatisfaccion == 0 ||
                  item.nivelSatisfaccion == 'Pendiente' ||
                  item.nivelSatisfaccion == null
                )?_c('v-btn',{attrs:{"x-small":"","rounded":"","elevation":"1","color":"red","dark":""},on:{"click":function($event){return _vm.openEvaluacionDialog(item)}}},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi mdi-star-outline")])],1):_c('v-btn',{attrs:{"x-small":"","rounded":"","elevation":"1","color":"teal darken-4","dark":""}},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-star-check")]),_vm._v(" "+_vm._s(item.nivelSatisfaccion)+" ")],1)],1):_c('v-chip-group',{attrs:{"column":""}},[_c('v-btn',{attrs:{"x-small":"","rounded":"","elevation":"1","color":"grey darken-1","dark":""}},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi mdi-star-check")]),(item.nivelSatisfaccion != 'Pendiente')?_c('div',[_vm._v(" "+_vm._s(item.nivelSatisfaccion)+" ")]):_vm._e()],1)],1)],1)]}},{key:"item.actions",fn:function(ref){
                var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","color":"indigo lighten-4"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"indigo darken-4"},on:{"click":function($event){return _vm.dialogDetalles(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-details ")])]}}],null,true)},[_c('label',{staticClass:"text-uppercase font-weight-bold white--text"},[_vm._v("Detalles")])])]}}],null,false,79886236)})],1),_c('v-divider')],1),_c('v-dialog',{attrs:{"max-width":"600px","transition":"dialog-top-transition","persistent":""},model:{value:(_vm.openDetalles),callback:function ($$v) {_vm.openDetalles=$$v},expression:"openDetalles"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-subtitle-2 primary white--text"},[_vm._v(" Detalles de solicitud "),_c('v-spacer'),_c('v-icon',{attrs:{"small":"","dark":""},on:{"click":function($event){_vm.openDetalles = false;
            _vm.e6 = 1;}}},[_vm._v("mdi-close")])],1),_c('v-card-text',{staticClass:"pt-5"},[_c('v-stepper',{attrs:{"vertical":""},model:{value:(_vm.e6),callback:function ($$v) {_vm.e6=$$v},expression:"e6"}},[_c('v-stepper-step',{attrs:{"complete":_vm.e6 > 1,"step":"1","editable":""}},[_vm._v(" Detalles de solicitud "),_c('small',[_vm._v("Consulta para despues responder")])]),_c('v-stepper-content',{attrs:{"step":"1"}},[_c('v-card',{staticClass:"pa-5 mb-5 elevation-2"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"12","md":"10","lg":"10","xl":"10"}},[_c('div',[_c('label',{staticClass:"font-weight-bold"},[_vm._v("Colaborador:")]),_vm._v(" "+_vm._s(_vm.item.EMPLID)+" ")]),_c('div',[_c('label',{staticClass:"font-weight-bold"},[_vm._v("Nombre:")]),_vm._v(" "+_vm._s(_vm.item.nombre)+" ")]),_c('div',[_c('label',{staticClass:"font-weight-bold"},[_vm._v("Motivo:")]),_vm._v(" "+_vm._s(_vm.item.motivo)+" ")])]),_c('v-col',{staticClass:"d-flex align-center justify-center",attrs:{"cols":"12","xs":"12","sm":"12","md":"2","lg":"2","xl":"2"}},[_c('div',[_c('v-tooltip',{attrs:{"top":"","color":"blue lighten-3"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"blue","dark":"","fab":""},on:{"click":function($event){return _vm.openEvidencia(_vm.item, 'evidencia')}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-image-search-outline")])],1)]}}],null,false,133076000)},[_c('span',{staticClass:"white---text"},[_vm._v(" Ver evidencia ")])])],1)])],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex justify-space-around mb-5",attrs:{"cols":"12","xs":"12","sm":"12","md":"12","lg":"12","xl":"12"}},[_c('div',{attrs:{"align":"center"}},[_c('label',{staticClass:"font-weight-bold"},[_vm._v("Fecha solicitud:")]),_c('br'),_vm._v(" "+_vm._s(_vm.item.fechaInconformidad)+" ")]),_c('div',{attrs:{"align":"center"}},[_c('label',{staticClass:"font-weight-bold"},[_vm._v("Fecha registro:")]),_c('br'),_vm._v(" "+_vm._s(_vm.item.fechaRegistro)+" ")])])],1),_c('v-card',{staticClass:"pa-5 mb-5"},[_c('v-row',[_c('v-col',{staticClass:"text-justify",attrs:{"cols":"12","xs":"12","sm":"12","md":"10","lg":"10","xl":"10"}},[_c('div',[_c('label',{staticClass:"font-weight-bold blue--text"},[_vm._v("Comentario:")]),_vm._v(" "+_vm._s(_vm.item.comentario)+" ")]),(_vm.item.estatus > 1)?_c('div',[_c('label',{staticClass:"font-weight-bold green--text"},[_vm._v("Respuesta:")]),_vm._v(" "+_vm._s(_vm.item.respuestaAdmon)+" ")]):_vm._e()]),_c('v-col',{staticClass:"d-flex align-center justify-center",attrs:{"cols":"12","xs":"12","sm":"12","md":"2","lg":"2","xl":"2"}},[(_vm.item.estatus > 1)?_c('div',[_c('v-tooltip',{attrs:{"top":"","color":"green lighten-3"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"green","dark":"","fab":""},on:{"click":function($event){return _vm.openEvidencia(_vm.item, 'respuesta')}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-image-search-outline")])],1)]}}],null,false,2515593977)},[_c('span',{staticClass:"white---text"},[_vm._v(" Ver evidencia respuesta ")])])],1):_vm._e()])],1)],1)],1)],1)],1)],1)],1),(_vm.muestraBarra)?_c('SnackBar',{attrs:{"muestraBarra":_vm.muestraBarra,"texto":_vm.textoBarra,"color":_vm.colorBarra},on:{"cierraBarra":function($event){_vm.muestraBarra = false}}}):_vm._e(),(_vm.evaluarRespuestaAclaracion)?_c('evaluacion-respuesta-aclaracion',{attrs:{"dialog":_vm.evaluarRespuestaAclaracion,"IDAclaracion":this.ID,"ruta":"inconformidad"},on:{"closed":_vm.refrescar}}):_vm._e(),(_vm.verEvidencia)?_c('inconformidades-evidencia',{attrs:{"dialog":_vm.verEvidencia,"item":_vm.item,"tipoEvidencia":_vm.tipoEvidencia},on:{"cierraDialogEvidencia":_vm.cerrarDialog}}):_vm._e()],1):(_vm.moduloError)?_c('v-container',[_c('mantenimiento-modulo')],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }