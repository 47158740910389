<template>
  <div>
    <!-- <v-dialog
      v-model="showAuthVacancy"
      max-width="600"
      content-class="fix-border-dialog"
    > -->
      <v-card class="rounded-xl">
        <v-card-title class="primary">
          <div class="subtitle-1 white--text text-uppercase font-weight-bold">
            Vacantes por autorizar
          </div>
          <v-spacer></v-spacer>
          <!-- <v-icon @click="$emit('closeAutVacantes')" dark> mdi-close </v-icon> -->
        </v-card-title>
        <v-card-text class="pa-2">
          <v-data-table
            :headers="headers"
            :items="vacantes"
            :footer-props="{
              'items-per-page-options': [5, 10],
              'items-per-page-text': 'Elementos por página',
            }"
            no-data-text="No hay registros"
            :loading="loadingTabla"
            loading-text="Cargando..."
            item-key="id"
            dense
          >
            <template v-slot:item.actions="{ item }">
                <div style="min-width: 150px;">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    class="mx-1 rounded-pill"
                    x-small
                    dark
                    depressed
                    v-bind="attrs"
                    v-on="on"
                    @click="
                      muestraDetalle = true;
                      loadingDetalle = true;
                      openDetails(item.id);
                    "
                  >
                    <v-icon small>mdi-text-search</v-icon>
                  </v-btn>
                </template>
                <span>Ver detalles</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="teal darken-1"
                    class="mx-1 rounded-pill"
                    x-small
                    dark
                    depressed
                    v-bind="attrs"
                    v-on="on"
                    @click="openConfirm(item)"
                  >
                    <v-icon small>mdi-check</v-icon>
                  </v-btn>
                </template>
                <span>Autorizar</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="pink darken-1"
                    class="mx-1 rounded-pill"
                    x-small
                    dark
                    depressed
                    v-bind="attrs"
                    v-on="on"
                    @click="openReject(item)"
                  >
                    <v-icon small>mdi-close</v-icon>
                  </v-btn>
                </template>
                <span>Rechazar</span>
              </v-tooltip>
            </div>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>

      <v-dialog
        v-model="muestraDetalle"
        persistent
        max-width="350"
        content-class="fix-border-dialog"
      >
        <v-card class="neumorphism-card-reclutamiento">
          <v-card-title>
            <div class="subtitle-1 font-weight-bold text-uppercase">
              detalle de vacante
            </div>
            <v-spacer></v-spacer>
            <v-icon @click="muestraDetalle = false"> mdi-close </v-icon>
          </v-card-title>
          <v-card-text>
            <v-row
              v-if="loadingDetalle"
              class="fill-height"
              align-content="center"
              justify="center"
            >
              <v-col class="overline text-center" cols="12">
                cargando...
              </v-col>
              <v-col cols="6" class="center-item-in-div">
                <v-progress-circular
                  :size="70"
                  :width="7"
                  color="primary"
                  indeterminate
                ></v-progress-circular>
              </v-col>
            </v-row>
            <div v-else-if="detalleVacante && !loadingDetalle">
              <v-card
                class="rounded-lg pa-2 elevation-0 mb-2"
                color="blue-grey lighten-5"
                style="border: 1.3px solid #78909C !important;"
              >
                <div
                  class="subtitle-2 text-uppercase font-weight-bold text-center"
                >
                  {{ detalleVacante.Nombre }}
                </div>
              </v-card>
              <v-card
                class="rounded-lg pa-2 elevation-0 mb-2"
                color="blue-grey lighten-5"
                style="border: 1.3px solid #78909C !important;"
              >
                <p class="body-2 text-justify">
                  {{
                    detalleVacante.productivity != null
                      ? detalleVacante.productivity
                      : detalleVacante.mercer
                  }}
                </p>
              </v-card>
              <v-row dense>
                <v-col cols="6">
                  <v-card
                    class="rounded-lg pa-2 elevation-0 mb-2"
                    color="blue-grey lighten-5"
                    style="border: 1.3px solid #78909C !important;"
                  >
                    <label class="caption text-uppercase font-weight-bold"
                      >Razón</label
                    >
                    <div class="body-2">{{ detalleVacante.razon }}</div>
                  </v-card>
                </v-col>
                <v-col cols="6">
                  <v-card
                    class="rounded-lg pa-2 elevation-0 mb-2"
                    color="blue-grey lighten-5"
                    style="border: 1.3px solid #78909C !important;"
                  >
                    <label class="caption text-uppercase font-weight-bold"
                      >Sueldo inicial</label
                    >
                    <div class="body-2">
                      {{
                        detalleVacante.Contratacion != null
                          ? formatMXN(parseFloat(detalleVacante.Contratacion))
                          : formatMXN(0)
                      }}
                    </div>
                  </v-card>
                </v-col>
              </v-row>
              <v-card
                class="rounded-lg pa-2 elevation-0 mb-2"
                color="blue-grey lighten-5"
                style="border: 1.3px solid #78909C !important;"
              >
                <label class="caption text-uppercase font-weight-bold"
                  >Número de posiciones solicitadas</label
                >
                <div class="body-2">{{ detalleVacante.num_vacantes }}</div>
              </v-card>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>

      <DialogComentarioRechazo
        :commentReject="muestraDialogComentarioRechazo"
        @ocultaDialogComentario="ocultaDialogComentarioRechazo"
      />

      <ConfirmDialogReclutamiento
        v-if="showConfirm"
        :showConfirm="showConfirm"
        :question="question"
        @cierraConfirm="cierraConfirm"
      />
    <!-- </v-dialog> -->

    <SnackBar
      v-if="muestraBarra"
      :muestraBarra="muestraBarra"
      :texto="textoBarra"
      :color="colorBarra"
      @cierraBarra="muestraBarra = false"
    />
  </div>
</template>

<script>
import axios from "axios";
import DialogComentarioRechazo from "../../../AdmnVacantes/components/DialogComentarioRechazo.vue";
import ConfirmDialogReclutamiento from "../../../AdmnVacantes/components/ConfirmDialogReclutamiento.vue";
import SnackBar from "../../MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";

export default {
  name: "persona-autoriza-cantes",
  props: ["showAuthVacancy"],
  components: { DialogComentarioRechazo, ConfirmDialogReclutamiento, SnackBar },
  data() {
    return {
      headers: [
        { value: "id", text: "id", class: "text-uppercase font-weight-bold blue-grey--text text--darken-1" },
        { value: "Nombre", text: "Nombre", class: "text-uppercase font-weight-bold blue-grey--text text--darken-1" },
        { value: "EMP_NOMEMP", text: "Solicitante", class: "text-uppercase font-weight-bold blue-grey--text text--darken-1" },
        { value: "actions", sortable: false, align: "center", text: "", class: "text-uppercase font-weight-bold blue-grey--text text--darken-1" },
      ],
      expanded: [],
      vacantes: [],
      detalleVacante: {},
      loadingTabla: true,
      muestraDialogComentarioRechazo: false,
      showConfirm: false,
      loadingDetalle: false,
      muestraDetalle: false,
      muestraBarra: false,
      question: "¿Realmente deseas autorizar esta vacante?",
      colorBarra: "",
      textoBarra: "",
    };
  },
  async mounted() {
    this.vacantes = await this.getVacantesPendientesPorAutorizar();
    // console.log(this.vacantes)
    if(this.vacantes.length === 0){
      this.$emit('validaEstado', this.vacantes.length);
    }
  },
  methods: {
    muestraSnackBar(texto, color) {
      this.colorBarra = color;
      this.textoBarra = texto;
      this.muestraBarra = true;
    },
    async getVacantesPendientesPorAutorizar() {
      const params = {
        Opcion: 41,
        token: localStorage.getItem("token"),
      };

      return new Promise((resolve, reject) => {
        axios
          .post(
            `${this.$store.getters.getHost}/Reclutamiento/ws-admin-reclu.php`,
            params
          )
          .then((response) => {
            if (response.data.status == "OK") {
              resolve(response.data.respuesta);
            } else {
              resolve([])
            }
          })
          .catch((error) => {
            reject(error);
          })
          .finally(() => {
            this.loadingTabla = false;
          });
      });
    },
    openDetails(id) {
      const params = {
        Opcion: 4,
        token: localStorage.getItem("token"),
        id: id,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/Reclutamiento/ws-admin-reclu.php`,
          params
        )
        .then((response) => {
          if (response.data.status != "EXPSESSION") {
            this.detalleVacante = response.data.detalle;
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loadingDetalle = false;
        });
    },
    formatMXN(cadena) {
      return cadena.toLocaleString("es-MX", {
        style: "currency",
        currency: "MXN",
      });
    },
    ocultaDialogComentarioRechazo(comentario) {
      this.muestraDialogComentarioRechazo = false;

      if (comentario != null) {
        this.loadingTabla = true;
        const params = {
          Opcion: 38,
          token: localStorage.getItem("token"),
          status: 8,
          idVacante: this.idAux,
          comentarios: comentario,
        };

        axios
          .post(
            `${this.$store.getters.getHost}/Reclutamiento/ws-admin-reclu.php`,
            params
          )
          .then(async (response) => {
            if (response.data.status != "EXPSESSION") {
              if (response.data.status === "OK") {
                if (response.data.respuesta.status_tran == "OK") {
                  this.muestraSnackBar(
                    response.data.respuesta.message,
                    "teal darken-1"
                  );
                  this.vacantes = await this.getVacantesPendientesPorAutorizar();
                  this.setEstatusToken();
                } else {
                  this.muestraSnackBar(
                    response.data.respuesta.message,
                    "pink darken-3"
                  );
                }
              } else {
                this.muestraSnackBar(
                  "Ocurrió un error. No se actualizó la información.",
                  "pink darken-3"
                );
              }
            }
          })
          .catch((e) => {
            this.muestraSnackBar(e, "pink darken-3");
          })
          .finally(() => {
            this.loadingTabla = false;
          });
      }
    },
    openReject(item){
        this.tokenAux = item.token,
        this.idAux = item.id,
        this.estatusAux = item.Estatus
        this.muestraDialogComentarioRechazo = true
    },
    openConfirm(item){
        this.tokenAux = item.token,
        this.idAux = item.id,
        this.estatusAux = item.Estatus
        this.showConfirm = true
    },

    cierraConfirm(respuesta) {
      if (respuesta) {
        this.showConfirm = false;
        this.loadingTabla = true;
        this.setEstatusVacante();
      } else {
        this.showConfirm = false;
      }
    },
    setEstatusVacante() {
      let params = {
        Opcion: 27,
        token: this.tokenAux,
        id: this.idAux,
        estatus: this.estatusAux,
        psw: this.tokenAux,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/Reclutamiento/ws-admin-reclu.php`,
          params
        )
        .then(async (response) => {
          if (response.data.status != "EXPSESSION") {
            if (response.data.respuesta.estatusPass == "AUTHORIZED") {
              this.muestraSnackBar("Modificado con éxito", "success");
              this.vacantes = await this.getVacantesPendientesPorAutorizar();
              if(this.vacantes.length === 0){
                this.$emit('validaEstado', this.vacantes.length);
              }
            } else {
                this.muestraSnackBar(response.data.respuesta.message, "error");
            }
          } else {
            this.muestraSnackBar("ERROR. No se pudo validar", "error");
          }
        })
        .catch((e) => {
          this.muestraSnackBar(e, "error");
        })
        .finally(() => {
          this.loadingTabla = false;
        });
    },
    setEstatusToken() {
      let params = {
        Opcion: 42,
        llave: this.tokenAux,
        token: localStorage.getItem('token')
      };
      axios
        .post(
          `${this.$store.getters.getHost}/Reclutamiento/ws-admin-reclu.php`,
          params
        )
        .then(async (response) => {
          if (response.data.status != "EXPSESSION") {
            if (response.data.respuesta.status_tran == "OK") {
              this.muestraSnackBar(response.data.respuesta.message, "primary");
            } else {
                this.muestraSnackBar(response.data.respuesta.message, "pink darken-3");
            }
          } else {
            this.muestraSnackBar("ERROR. No se pudo validar", "pink darken-3");
          }
        })
        .catch((e) => {
          this.muestraSnackBar(e, "pink darken-3");
        })
        .finally(() => {
          this.loadingTabla = false;
        });
    },
  },
};
</script>
