<template>
    <v-dialog v-model="verEncuestaRutas" fullscreen persistent>
        <v-card class="rounded-lg">
            <v-toolbar color="primary" dark>
                <v-row>
                    <v-col cols="2" style="margin-top: 12px">
                        <img src="require('@/assets/img/chat_urrea.png')" width="40px"
                            height="40px" />
                    </v-col>
                    <v-col cols="10" style="margin-top: 5px">
                        <h3>¡Hola!</h3>
                        <p>Contesta esta encuesta sobre las rutas de tranporte.</p>
                    </v-col>
                </v-row>
            </v-toolbar>
            <v-card-text>
                <br>
                <p>El objetivo de esta encuesta es recopilar información para mejorar nuestro servicio de transporte.
                    Agradecemos tu participación y tus opiniones son muy valiosas para nosotros. ¡Gracias por ayudarnos
                    a mejorar!</p>

                <v-card v-for="(pregunta, index) in preguntasFiltradas" :key="pregunta.id" class="ma-1 pa-3 rounded-lg"
                    elevation="3">
                    <div class="font-weight-bold">{{ pregunta.pregunta }}</div>
                    <v-radio-group v-model="selectedAnswers[index]">
                        <v-radio v-for="respuesta in pregunta.respuestas" :key="respuesta.id"
                            :label="respuesta.respuesta" :value="respuesta.id">
                        </v-radio>
                    </v-radio-group>
                </v-card>

                <br>
                <v-btn color="primary" @click="enviarRespuestas" :disabled="!isStep1Complete"
                    :loading="datosenviados">Enviar</v-btn>

                <v-snackbar v-model="snackbar" :timeout="timeout" :color="color" shaped elevation="9">
                    {{ text }}

                    <template v-slot:action="{ attrs }">
                        <v-btn text v-bind="attrs" @click="snackbar = false, $emit('cierraRutas')">
                            Cerrar
                        </v-btn>
                    </template>
                </v-snackbar>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import axios from "axios";

export default {
    name: "formulario-beneficios-flexibles",
    props: ["verEncuestaRutas"],
    data() {
        return {
            preguntas: [],
            selectedAnswers: Array.from({ length: 3 }, () => null),
            showCards: [true, false, false, false], // Mostrar la primera tarjeta y ocultar las demás inicialmente
            isStep1Complete: false, // indicador si el primer paso está completo
            snackbar: false,
            text: '',
            timeout: 3000,
            color: '',
            showCard: false,
            datosenviados: false,
        };
    },
    computed: {
        preguntasFiltradas() {
            if (this.selectedAnswers[0] === 53) {
                // Si se selecciona la respuesta con el ID 53 en la pregunta 0, devolver todas las preguntas
                return this.preguntas;
            } else {
                // Si no se selecciona la respuesta con el ID 53 en la pregunta 0, devolver solo la primera pregunta
                return this.preguntas.slice(0, 1);
            }
        },
    },
    mounted() {
        this.getPreguntas();
    },
    watch: {
        selectedAnswers: {
            handler: function (newVal) {
                // Marcar el paso 1 como completo si se han seleccionado respuestas para las primeras tres preguntas
                if (newVal[0] === 54) {
                    this.isStep1Complete = true;
                } else {
                    this.isStep1Complete = newVal.slice(0, 3).every((answer) => answer !== null);
                }
            },

            deep: true,
        },
    },
    created() {
    },
    methods: {
        getPreguntas() {
            let params = {
                Opcion: 9,
                token: localStorage.getItem("token"),
                proyecto: 2,
            };
            axios
                .post(`${this.$store.getters.getHost}/Compensaciones/ws-beneficios-flexibles.php`, params)
                .then((response) => {
                    if (response.data.status == "OK") {
                        const data = response.data;
                        this.preguntas = data.preguntas;

                        axios.post(`${this.$store.getters.getHost}/Login/ws-rutas.php`, { Opcion: 1 })
                            .then((response) => {
                                const rutas = response.data.rutas.map(ruta => ({
                                    id: ruta.id,
                                    respuesta: ruta.DescRuta
                                }));
                                // Sustituir solo la parte de respuestas en la posición 1 del array preguntas
                                this.preguntas[1].respuestas = rutas;
                            })
                            .catch((error) => {
                                console.error('Error al obtener el arreglo adicional:', error);
                            });
                        //console.log(this.preguntas);
                    }
                    else {
                        console.log('No cargo preguntas');
                    }
                })
                .catch(function (e) {
                    //
                    console.log(e);
                });
        },
        enviarRespuestas() {
            if (this.selectedAnswers[0] === 54) {
                this.selectedAnswers = [54, null, null];
            }
            this.datosenviados = true;
            let params = {
                Opcion: 13,
                token: localStorage.getItem("token"),
                respuestas: this.selectedAnswers,
            };
            axios
                .post(`${this.$store.getters.getHost}/Compensaciones/ws-beneficios-flexibles.php`, params)
                .then((response) => {
                    this.datosenviados = false;

                    if (response.data.status == "OK") {
                        this.snackbar = true;
                        this.text = 'Se ah registrado sus respuestas.';
                        this.color = "success"
                        setTimeout(() => {
                            this.$emit("cierraRutas");
                        }, 3000); // 3 segundos
                    }
                    else {
                        this.snackbar = true;
                        this.text = 'No se ah registrado sus respuestas.';
                        this.color = "red accent-2";
                    }
                })
                .catch(function (e) {
                    //
                    console.log(e);
                });
        },
    }
}
</script>

<style scoped>
.containerChat {
    background: white;
    height: 65vh;
    overflow: auto;
    transition: all 1.5s;
}

.v-dialog__content {
    align-items: end !important;
    display: flex;
    height: 100%;
    justify-content: end !important;
    left: 0;
    pointer-events: none;
    position: fixed;
    top: 0;
    transition: 0.2s cubic-bezier(0.25, 0.8, 0.25, 1), z-index 1ms;
    width: 100%;
    z-index: 6;
    outline: none;
}

.mi-boton .v-btn__content {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 768px) {
    .containerChat {
        background: white;
        height: calc(100vh - 120px);
        overflow: auto;
    }
}
</style>