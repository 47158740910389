import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "../store";

Vue.use(VueRouter)

const routes = [{
    path: '/Login',
    name: 'Login',
    component: () =>
        import( /* webpackChunkName: "InitialLoad" */ '@/views/static/Login/index.vue'),
    meta: {
        requiresAuth: false
    }
},
{
    path: '/Forbidden',
    name: 'Forbidden',
    component: () =>
        import( /* webpackChunkName: "InitialLoad" */ '@/views/static/Forbidden/index.vue'),
    meta: {
        requiresAuth: false
    }
},
{
    path: '/AutorizaVacantes/:key/:id',
    name: 'AutorizaVacantes',
    component: () =>
        import( /* webpackChunkName: "InitialLoad" */ '@/views/static/AuthVcantes/index.vue'),
    meta: {
        requiresAuth: false
    }
},
{
    path: '/AutorizaIncidencia/:key/:token',
    name: 'AutorizaIncidencia',
    component: () =>
        import( /* webpackChunkName: "InitialLoad" */ '@/components/AutorizaIncidencia.vue'),
    meta: {
        requiresAuth: false
    }
},
{
    path: '/Nominas/Compensaciones/ofertasEconomicas/:idOferta',
    name: 'ofertasEconomicas',
    component: () =>
        import( /* webpackChunkName: "InitialLoad" */ '@/views/generated/Nominas/Compensaciones/ofertasEconomicas/index.vue'),
    meta: {
        requiresAuth: true
    }
},
{
    path: '/ApegoPerfil/:key/:id',
    name: 'ApegoPerfil',
    component: () =>
        import( /* webpackChunkName: "InitialLoad" */ '@/components/LlenaApegoPerfil.vue'),
    meta: {
        requiresAuth: false
    }
},
{
    path: '/AutorizaCandidato/:key/:id',
    name: 'AutorizaCandidato',
    component: () =>
        import( /* webpackChunkName: "InitialLoad" */ '@/components/AutorizaCandidatoInterno.vue'),
    meta: {
        requiresAuth: false
    }
},
{
    path: '/AutorizaVisita/:key',
    name: 'AutorizaVisita',
    component: () =>
        import( /* webpackChunkName: "InitialLoad" */ '@/components/AutorizaVisita.vue'),
    meta: {
        requiresAuth: false
    }
},
{
    path: '/AutorizaOferta/:key/:id/:tipo',
    name: 'AutorizaOferta',
    component: () =>
        import( /* webpackChunkName: "InitialLoad" */ '@/components/AutorizaOfertaEconomica.vue'),
    meta: {
        requiresAuth: false
    }
},
    {
        path: '/AutorizaDescansoLaborado/:key/:emplid/:opcion',
        name: 'AutorizaDescansoLaborado',
        component: () =>
            import ( /* webpackChunkName: "InitialLoad" */ '@/components/AutorizaDL.vue'),
        meta: {
            requiresAuth: false
        }
    },
{
    path: '/CargaApegos',
    name: 'Carga de apegos a perfil',
    component: () =>
        import( /* webpackChunkName: "InitialLoad" */ '@/views/generated/General/MiEquipo/PuestosLider/index.vue'),
    meta: {
        requiresAuth: true,
        forceImport: true
    }
},
{
    path: '/TalentoHumano',
    name: 'TalentoHumano',
    component: () =>
        import( /* webpackChunkName: "InitialLoad" */ '@/components/AtraccionDeTalento.vue'),
    meta: {
        requiresAuth: false
    }
},
{
    path: '/RegistoDeCandidatos/:key/:type/:id',
    name: 'RegistoDeCandidatos',
    component: () =>
        import( /* webpackChunkName: "InitialLoad" */ '@/components/FormularioRegistroCandidatos.vue'),
    meta: {
        requiresAuth: false
    }
},
{
    path: '/IngresoDeMaterial/:key/:id',
    name: 'IngresoDeMaterial',
    component: () =>
        import( /* webpackChunkName: "InitialLoad" */ '@/components/FormularioMaterialIngresar.vue'),
    meta: {
        requiresAuth: false
    }
},
{
    path: '/AutorizaPaseDeSalida/:key/:idPase/:mail',
    name: 'AutorizaPaseDeSalida',
    component: () =>
        import( /* webpackChunkName: "InitialLoad" */ '@/components/AutorizaPaseDeSalida.vue'),
    meta: {
        requiresAuth: false
    }
},
{
    path: '/AutorizaPaseDeIngreso/:key/:idPase/:mail',
    name: 'AutorizaPaseDeIngreso',
    component: () =>
        import( /* webpackChunkName: "InitialLoad" */ '@/components/AutorizaPaseDeIngreso.vue'),
    meta: {
        requiresAuth: false
    }
},
{
    path: '/AutorizaTE/:folio/:opc/',
    name: 'AutorizaTE',
    component: () =>
        import( /* webpackChunkName: "InitialLoad" */ '@/components/AutorizacionTE.vue'),
    meta: {
        requiresAuth: false
    }
},
{
    path: '/Inicio',
    name: 'Inicio',
    component: () =>
        import( /* webpackChunkName: "InitialLoad" */ '@/views/generated/General/Inicio/index.vue'),
    meta: {
        requiresAuth: true,
        forceImport: true
    }
},
{
    path: '/VerPuestoLider',
    name: 'vista lider',
    component: () =>
        import( /* webpackChunkName: "InitialLoad" */ '@/views/generated/General/MiEquipo/PuestosLider/ApegoAPerfilPorPuesto/index.vue'),
    meta: {
        requiresAuth: true,
        forceImport: true
    }
},
{
    path: '/SeguridadPatrimonial/AdmonVisitas',
    name: 'AdmonVisitas',
    component: () =>
        import( /* webpackChunkName: "InitialLoad" */ '@/views/generated/SeguridadPatrimonial/AdmonVisitas/index.vue'),
    meta: {
        requiresAuth: true,
        forceImport: true
    },
    beforeRouteLeave(to, from, next) {
        next();
    }
},
{
    path: '',
    redirect: '/Login'
}
]


const router = new VueRouter({
    routes
})

router.beforeEach((to, from, next) => {
    const isStaticRoute = routes.some(route => route.path == to.path);
    const nStaticRoutes = routes.length;
    const nRouterRoutes = router.getRoutes().length;
    const noModules = (nStaticRoutes == nRouterRoutes);

    const homeRegex = /^\/AutorizaVacantes(\/[a-zA-Z0-9-_]+)*$/;
    const apegoRegex = /^\/ApegoPerfil(\/[a-zA-Z0-9-_]+)*$/;
    const autCandidato = /^\/AutorizaCandidato(\/[a-zA-Z0-9-_]+)*$/;
    const autVisita = /^\/AutorizaVisita(\/[a-zA-Z0-9-_]+)*$/;
    const landingReclu = /^\/TalentoHumano(\/[a-zA-Z0-9-_]+)*$/;
    const autOferta = /^\/AutorizaOferta(\/[a-zA-Z0-9-_]+)*$/;
    const ingresoDeMaterial = /^\/IngresoDeMaterial(\/[a-zA-Z0-9-_]+)*$/;
    const registoDeCandidatos = /^\/RegistoDeCandidatos(\/[a-zA-Z0-9-_]+)*$/;
    const autorizaPase = /^\/AutorizaPaseDeSalida(\/[a-zA-Z0-9-_]+)*$/;
    const autorizaPaseIngreso = /^\/AutorizaPaseDeIngreso(\/[a-zA-Z0-9-_]+)*$/;
    const outTe = /^\/AutorizaTE(\/[a-zA-Z0-9-_]+)*$/;
    const autorizaIncidencia = /^\/AutorizaIncidencia(\/[a-zA-Z0-9-_]+)*$/;
    const autorizaIncidenciaDL = /^\/AutorizaDescansoLaborado(\/[a-zA-Z0-9-_]+)*$/;

    if ((to.path === '/Login' || homeRegex.test(to.path) || autorizaIncidenciaDL.test(to.path) || autorizaIncidencia.test(to.path) || apegoRegex.test(to.path) || outTe.test(to.path) || autorizaPase.test(to.path) || autorizaPaseIngreso.test(to.path) || autVisita.test(to.path) || autCandidato.test(to.path) || registoDeCandidatos.test(to.path) || landingReclu.test(to.path) || autOferta.test(to.path) || ingresoDeMaterial.test(to.path)) && !localStorage.getItem('token')) {
        next();
    } else if (isStaticRoute && to.meta.forceImport != false) {
        next();
    } else if (localStorage.getItem('token')) {
        if (noModules) {
            let menuListRequest = new Promise((resolve) => resolve())
            if (store.getters.getMenuList.length == 0) menuListRequest = store.dispatch('retrieveMenu');
            menuListRequest.then(() => loadMenu());
            next();
        } else next();
    } else next('/Login');

})

const loadMenu = () => {
    if (store.getters.getMenuList.length > 0) {
        store.getters.getMenuList.forEach(
            module => router.addRoute(generateLazyLoadModule(module))
        );
        return true
    }
    return false
}

const generateLazyLoadModule = (module) => {
    let modulePromise = resolve => require([`@/views/generated/${module.module}/index.vue`], resolve)
    return {
        path: module.url,
        name: module.title,
        component: modulePromise,
        meta: {
            requiresAuth: true
        }
    }
}

export default router