var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.esDialog)?_c('div',[_c('v-dialog',{attrs:{"fullscreen":"","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"mx-5"},[_c('v-toolbar',{attrs:{"flat":"","color":"primary","dark":""}},[_c('v-toolbar-title',[_vm._v("Carga de documentos")])],1),(_vm.documentos.length == 0)?_c('div',[_c('div',{staticClass:"d-flex flex-column justify-space-between align-center pa-0"},[_c('v-img',{attrs:{"transition":"scroll-y-transition","max-width":"300","src":require('@/assets/icons/Documentacion/box_empty.png')}})],1),_c('h2',{staticClass:"text-center blue-grey--text text--lighten-2 mb-5"},[_vm._v(" No hay documentación cargada aún. ")])]):_c('div',[_c('v-row',_vm._l((_vm.documentos),function(n){return _c('v-col',{key:n.idTipo,staticClass:"d-flex child-flex",attrs:{"cols":"12","xl":"4","lg":"4","md":"4","sm":"6","xs":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"rounded-xl",attrs:{"elevation":hover ? 16 : 2},on:{"click":function($event){return _vm.openFile(n.archivo, n.tipo_archivo)}}},[_c('div',{staticClass:"text-center my-2 font-weight-bold text-uppercase blue-grey--text text--darken-1"},[_vm._v(" "+_vm._s(n.documento)+" ")]),(n.tipo_archivo == 'image')?_c('div',[_c('v-img',{staticClass:"my-3",attrs:{"src":require('@/assets/icons/Documentacion/image-icon.png'),"contain":"","max-height":"125"}})],1):_c('div',[(n.extension == 'pdf')?_c('v-img',{staticClass:"my-3",attrs:{"src":require('@/assets/icons/Documentacion/pdf-icon.png'),"contain":"","max-height":"125"}}):_c('v-img',{staticClass:"my-3",attrs:{"src":require('@/assets/icons/Documentacion/paper.png'),"contain":"","max-height":"125"}})],1),_c('v-card-title',{staticClass:"py-2 center-item-in-div"},[_c('v-icon',{staticClass:"mx-1",attrs:{"color":"cyan"}},[_vm._v("mdi-eye")]),_c('div',{staticClass:"cyan--text overline"},[_vm._v("ver documento")])],1)],1)]}}],null,true)})],1)}),1)],1),_c('div',{staticClass:"text-center mt-5",attrs:{"transition":"scroll-y-transition"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',{attrs:{"color":hover ? 'blue lighten-1' : 'primary',"elevation":hover ? 16 : 2,"dark":hover ? false : true,"large":"","rounded":""},on:{"click":function($event){return _vm.openArchivosEdit()}}},[_c('v-icon',{attrs:{"left":"","large":""}},[_vm._v("mdi-upload")]),_vm._v(" cargar ó editar documentación ")],1)]}}],null,false,2858334529)})],1)],1),(_vm.muestraCargaArchivos)?_c('CargaArchivos',{attrs:{"dialogCargaArchivos":_vm.muestraCargaArchivos,"emplid":_vm.emplid,"estatusColaborador":1,"esAdmin":"2"},on:{"cierraVentana":function($event){return _vm.reloadInformation()}}}):_vm._e(),(_vm.muestraBarra)?_c('SnackBarErrorIncidencias',{attrs:{"muestraBarra":_vm.muestraBarra,"texto":_vm.textoBarra,"color":_vm.colorBarra},on:{"cierraBarra":function($event){_vm.muestraBarra = false}}}):_vm._e()],1)],1):_c('div',[_c('v-card',{attrs:{"elevation":"0"}},[_c('v-toolbar',{attrs:{"flat":"","color":"primary","dark":""}},[_c('v-toolbar-title',[_vm._v("Carga de documentos")])],1),(_vm.documentos.length == 0)?_c('div',[_c('div',{staticClass:"d-flex flex-column justify-space-between align-center pa-0"},[_c('v-img',{attrs:{"transition":"scroll-y-transition","max-width":"300","src":require('@/assets/icons/Documentacion/box_empty.png')}})],1),_c('h2',{staticClass:"text-center blue-grey--text text--lighten-2 mb-5"},[_vm._v(" No hay documentación cargada aún. ")])]):_c('div',[_c('v-row',_vm._l((_vm.documentos),function(n){return _c('v-col',{key:n.idTipo,staticClass:"d-flex child-flex",attrs:{"cols":"12","xl":"4","lg":"4","md":"4","sm":"6","xs":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"rounded-xl",attrs:{"elevation":hover ? 16 : 2},on:{"click":function($event){return _vm.openFile(n.archivo, n.tipo_archivo)}}},[_c('div',{staticClass:"text-center my-2 font-weight-bold text-uppercase blue-grey--text text--darken-1"},[_vm._v(" "+_vm._s(n.documento)+" ")]),(n.tipo_archivo == 'image')?_c('div',[_c('v-img',{staticClass:"my-3",attrs:{"src":require('@/assets/icons/Documentacion/image-icon.png'),"contain":"","max-height":"125"}})],1):_c('div',[(n.extension == 'pdf')?_c('v-img',{staticClass:"my-3",attrs:{"src":require('@/assets/icons/Documentacion/pdf-icon.png'),"contain":"","max-height":"125"}}):_c('v-img',{staticClass:"my-3",attrs:{"src":require('@/assets/icons/Documentacion/paper.png'),"contain":"","max-height":"125"}})],1),_c('v-card-title',{staticClass:"py-2 center-item-in-div"},[_c('v-icon',{staticClass:"mx-1",attrs:{"color":"cyan"}},[_vm._v("mdi-eye")]),_c('div',{staticClass:"cyan--text overline"},[_vm._v("ver documento")])],1)],1)]}}],null,true)})],1)}),1)],1),_c('div',{staticClass:"text-center mt-5",attrs:{"transition":"scroll-y-transition"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',{attrs:{"color":hover ? 'blue lighten-1' : 'primary',"elevation":hover ? 16 : 2,"dark":hover ? false : true,"large":"","rounded":""},on:{"click":function($event){return _vm.openArchivosEdit()}}},[_c('v-icon',{attrs:{"left":"","large":""}},[_vm._v("mdi-upload")]),_vm._v(" cargar ó editar documentación ")],1)]}}])})],1)],1),(_vm.muestraCargaArchivos)?_c('CargaArchivos',{attrs:{"dialogCargaArchivos":_vm.muestraCargaArchivos,"emplid":_vm.emplid,"estatusColaborador":1,"esAdmin":"2"},on:{"cierraVentana":function($event){return _vm.reloadInformation()}}}):_vm._e(),(_vm.muestraBarra)?_c('SnackBarErrorIncidencias',{attrs:{"muestraBarra":_vm.muestraBarra,"texto":_vm.textoBarra,"color":_vm.colorBarra},on:{"cierraBarra":function($event){_vm.muestraBarra = false}}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }