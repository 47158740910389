<template>
  <v-dialog
    v-model="dialog"
    max-width="800"
    transition="dialog-bottom-transition"
    persistent
  >
    <v-card>
      <v-card-title class="text-subtitle-2 primary white--text">
        Solicitudes de aclaración de nómina
        <v-spacer></v-spacer>
        <v-icon
          v-if="bloqueo == false"
          small
          dark
          @click="$emit('cierraAdmonAclaraciones')"
          >mdi-close</v-icon
        >
      </v-card-title>
      <v-card-text class="pt-5">
        <div v-if="bloqueo == true">
          <v-alert
            shaped
            prominent
            type="error"
            color="blue-grey"
            class="text-justify font-italic"
          >
            Para continuar navegando por el portal, nos gustaria poder contar
            con su evaluación sobre nuestra atención brindada en sus
            aclaraciones cerradas. Clic en 
            <v-btn x-small rounded elevation="1" color="red" dark>
              <v-icon x-small>mdi mdi-star-outline</v-icon>
            </v-btn>
            para continuar con la evaluación.
          </v-alert>
        </div>
        <v-data-table
          dense
          :headers="headers"
          :items="aclaraciones"
          :items-per-page="10"
          class="elevation-1"
          :loading="cargando"
          :search="buscar"
          mobile-breakpoint="0"
          :footer-props="{ 'items-per-page-text': 'Elementos por página' }"
          no-data-text="No hay registros"
        >
          <template v-slot:top>
            <v-toolbar
              flat
              color="primary"
              class="rounded-lg rounded-b-0 rounded-t-0"
            >
              <v-text-field
                v-model="buscar"
                :label="$t('DH360.findTextBox')"
                class="mt-7 rounded-lg"
                dense
                clearable
                flat
                solo-inverted
                dark
                prepend-inner-icon="mdi-magnify"
              ></v-text-field>
              <v-spacer></v-spacer>
              <v-btn
                class="rounded-lg elevation-0"
                color="teal darken-1"
                dark
                @click="openFormAclaraciones = true"
              >
                Solicitar aclaración
              </v-btn>
            </v-toolbar>
          </template>

          <template v-slot:item.estatus="{ item }">
            <div v-if="item.estatus == 'Abierta'" class="d-flex justify-center">
              <v-chip-group column>
                <v-btn x-small rounded elevation="1" color="red" dark>
                  {{ item.estatus }}
                </v-btn>
              </v-chip-group>
            </div>
            <div
              v-else-if="item.estatus == 'Cerrada'"
              class="d-flex justify-center"
            >
              <v-chip-group column>
                <v-btn x-small rounded elevation="1" color="teal darken-1" dark>
                  {{ item.estatus }}
                </v-btn>
              </v-chip-group>
            </div>
          </template>
          <template v-slot:item.nivelSatisfaccion="{ item }">
            <div class="d-flex justify-center">
              <v-chip-group column v-if="item.estatus == 'Cerrada'">
                <v-btn
                  v-if="
                    item.nivelSatisfaccion == 0 ||
                    item.nivelSatisfaccion == null
                  "
                  x-small
                  rounded
                  elevation="1"
                  color="red"
                  dark
                  @click="openEvaluacionDialog(item)"
                >
                  <v-icon x-small>mdi mdi-star-outline</v-icon>
                </v-btn>
                <v-btn
                  v-else
                  x-small
                  rounded
                  elevation="1"
                  color="teal darken-1"
                  dark
                >
                  <v-icon x-small>mdi mdi-star-check</v-icon>
                  {{ item.nivelSatisfaccion }}
                </v-btn>
              </v-chip-group>
              <v-chip-group column v-else>
                <v-btn
                  x-small
                  rounded
                  elevation="1"
                  color="grey darken-1"
                  dark
                >
                  <v-icon x-small>mdi mdi-star-check</v-icon>
                  {{ item.nivelSatisfaccion }}
                </v-btn>
              </v-chip-group>
            </div>
          </template>
          <template v-slot:item.comentarios="{ item }">
            <div class="d-flex justify-center">
              <v-chip-group column>
                <v-btn
                  x-small
                  rounded
                  elevation="1"
                  color="primary"
                  dark
                  @click="editItem(item)"
                >
                  <v-icon x-small>mdi mdi-book-open-outline</v-icon>
                </v-btn>
              </v-chip-group>
            </div>
          </template>
          <template v-slot:item.FIN_CADPER="{ item }">
            <div v-if="item.FIN_CADPER == '0 Q'" class="d-flex justify-center">
              Utilidades (PTU)
            </div>
            <div
              v-else-if="item.FIN_CADPER == '220'"
              class="d-flex justify-center"
            >
              Aguinaldo
            </div>
            <div
              v-else-if="item.FIN_CADPER == '230'"
              class="d-flex justify-center"
            >
              Fondo de ahorro
            </div>
            <div v-else class="d-flex justify-center">
              {{ item.periodo }}
            </div>
          </template>
        </v-data-table>

        <v-dialog v-model="dialogComentarios" max-width="800px">
          <v-card>
            <v-card-title class="text-subtitle-2 primary white--text">
              Comentarios
              <v-spacer></v-spacer>
              <v-icon small dark @click="dialogComentarios = false"
                >mdi-close</v-icon
              >
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="6" sm="6" md="6">
                    <v-textarea
                      auto-grow
                      v-model="comentarioGral"
                      rows="1"
                      row-height="15"
                      label="Aclaración Colaborador"
                      disabled
                      prepend-icon="mdi-comment"
                    ></v-textarea>
                  </v-col>
                  <v-col cols="6" sm="6" md="6">
                    <v-textarea
                      auto-grow
                      v-model="comentario"
                      rows="1"
                      row-height="15"
                      label="Aclaración Colaborador"
                      disabled
                      prepend-icon="mdi-comment"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <div>
                <v-btn
                  class="rounded-lg elevation-0"
                  color="primary"
                  dark
                  @click="dialogComentarios = false"
                >
                  <v-icon left>mdi-keyboard-return</v-icon> Volver
                </v-btn>
              </div>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card-text>
    </v-card>
    <v-sheet id="snack-bar-error-incidencias">
      <snack-bar-error-incidencias
        :muestraBarra="muestraBarra"
        :texto="textoBarra"
        :color="colorBarra"
        @cierraBarra="muestraBarra = false"
      />
    </v-sheet>
    <v-sheet>
      <mi-nomina-form-aclaraciones
        v-if="openFormAclaraciones"
        :dialog="openFormAclaraciones"
        @cierraFormAclaraciones="
          openFormAclaraciones = false;
          recargar = 1;
          refresh();
        "
      />
    </v-sheet>
    <v-sheet id="ev-respuesta-aclaracion">
      <evaluacion-respuesta-aclaracion
        :dialog="evaluarRespuestaAclaracion"
        :IDAclaracion="this.ID"
        ruta="aclaracion"
        @closed="
          evaluarRespuestaAclaracion = false;
          recargar = 1;
          refresh();
        "
      />
    </v-sheet>
  </v-dialog>
</template>

<script>
import axios from "axios";
import SnackBarErrorIncidencias from "../../views/generated/General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";
import EvaluacionRespuestaAclaracion from "../../views/generated/Nominas/Aclaraciones/components/EvaluacionRespuestaAclaracion.vue";
import MiNominaFormAclaraciones from "../componentsNomina/MiNominaFormAclaraciones.vue";
import { deleteToken } from "../../store/Funciones/funciones";
export default {
  name: "mi-nomina-admon-aclaraciones",
  data: () => ({
    textoBarra: "",
    colorBarra: "",
    muestraBarra: false,
    openFormAclaraciones: false,
    evaluarRespuestaAclaracion: false,
    recargar: 0,

    cargando: true,
    buscar: "",
    headers: [
      { text: "ID", value: "ID", align: "center", sortable: false },
      {
        text: "Colaborador",
        value: "Emplid",
        align: "center",
        sortable: false,
      },
      {
        text: "Fecha",
        value: "fechaRegistro",
        align: "center",
        sortable: false,
      },
      {
        text: "Periodo",
        value: "FIN_CADPER",
        align: "center",
        sortable: false,
      },
      { text: "Motivo", value: "motivo", align: "nombre", sortable: false },
      {
        text: "Evaluado",
        value: "nivelSatisfaccion",
        align: "center",
        sortable: false,
      },
      { text: "Estatus", value: "estatus", align: "center", sortable: false },
      {
        text: "Comentarios",
        value: "comentarios",
        align: "center",
        sortable: false,
      },
    ],
    aclaraciones: [],
    editedIndex: -1,
    dialogComentarios: false,
    comentarioGral: "",
    comentario: "",
    ID: "",
    bloqueo: false,
  }),
  components: {
    SnackBarErrorIncidencias,
    MiNominaFormAclaraciones,
    EvaluacionRespuestaAclaracion,
  },
  props: ["dialog"],
  created() {},
  mounted() {
    this.getAclaraciones();
  },
  methods: {
    refresh() {
      if (this.recargar == 1) {
        this.getAclaraciones();
        this.recargar = 0;
      }
    },

    openEvaluacionDialog(item) {
      this.evaluarRespuestaAclaracion = true;
      this.ID = item.ID;
    },

    editItem(item) {
      this.editedIndex = this.aclaraciones.indexOf(item);
      // this.editedItem = Object.assign({}, item)
      this.comentarioGral = item.comentarioGral;
      if (item.estatus != "Abierta") {
        this.comentario = item.comentarioNominas;
      } else {
        this.comentario = "Aún no se encuentra respuesta";
      }
      this.ID = item.ID;
      this.dialogComentarios = true;
    },

    getAclaraciones() {
      let params = {
        Opcion: 11,
        token: localStorage.getItem("token"),
        perfil: "usuario",
      };
      axios
        .post(`${this.$store.getters.getHost}/nominas/ws-nominas.php`, params)
        .then((response) => {
          if (response.data.status.trim() == "EXPSESSION") {
            deleteToken();
          } else {
            var data = response.data.data;
            if (data) {
              this.aclaraciones = data;
            }
            if (response.data.numNoEvaluados != null) {
              this.bloqueo = true;
            } else {
              this.bloqueo = false;
            }
          }
          this.cargando = false;
        })
        .catch(function (e) {
          let result = e.toString().includes("401");
          if (result) {
            window.location.reload();
          } else {
            alert(e);
          }
        });
    },
  },
};
</script>
