<template>
  <v-dialog
    v-model="verNotificaciones"
    class="rounded-lg"
    max-width="500"
    persistent
    scrollable
  >
    <v-card class="rounded-lg">
      <v-card-title class="text-center my-3">
        Mis notificaciones
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <PersonaNotificacionesVue :items="items" :key="componentKey" />
        <div v-show="items.length == 1" class="text-center">
          <p class="font-weight-medium grey--text text--darken-1">
            No hay notificaciones por mostrar
          </p>
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn
          text
          color="primary"
          rounded
          block
          @click="$emit('closeNotificaciones')"
        >
          cerrar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import PersonaNotificacionesVue from "../views/generated/General/Inicio/components/PersonaNotificaciones.vue";
import axios from "axios";
import { deleteToken } from "../store/Funciones/funciones";

export default {
  name: "dialog-notificaciones-mobile",
  props: ["verNotificaciones"],
  components: {
    PersonaNotificacionesVue,
  },
  data: () => ({
    items: [
      {
        header: "Notificaciones",
      },
    ],
    componentKey: 0,
  }),
  mounted() {
    this.findNotifications();
  },
  methods: {
    findNotifications() {
      this.items.length = 0;
      this.items = [{header: "Notificaciones",},];
      let params = {
        Opcion: 5,
        token: localStorage.getItem("token"),
      };
      axios
        .post(
          `${this.$store.getters.getHost}/Incidencias/ws-incidencias-autorizacion.php`,
          params
        )
        .then(async (response) => {
          if (response.data.status == "EXPSESSION") {
            deleteToken();
          } else {
            this.respuestaApiIncidenciasPorAutorizar =
              response.data.incidencias;
            if (this.respuestaApiIncidenciasPorAutorizar > 0) {
              if (this.abrirAutorizaciones) {
                this.autorizarIncidenciasVisible = true;
              }
              this.items.push({
                id: 0,
                color: "primary",
                icon: "mdi-bell",
                title: "Tienes incidencias por autorizar",
                type: "",
                subtitle: "",
                action: "",
              });
            }
            let notificaciones = await this.getNotificaciones();
            notificaciones.map((n) => {
              this.items.push(n);
            });
            this.componentKey++;
          }
        })
        .catch(function(e) {
          console.log(e);
        });
    },
    async getNotificaciones() {
      let params = {
        Opcion: 15,
        token: localStorage.getItem("token"),
      };
      return new Promise((resolve, reject) => {
        axios
          .post(`${this.$store.getters.getHost}/General/ws-general.php`, params)
          .then((response) => {
            if (response.data.status == "EXPSESSION") {
              deleteToken();
            } else {
              if (response.data.status != "Vacio") {
                resolve(response.data.notificaciones);
              } else {
                reject([]);
              }
            }
          });
      });
    },
  },
};
</script>
