<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" max-width="90%" transition="dialog-bottom-transition" persistent>
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>Kardex laboral</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="$emit('cierraKardexLaboral')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-container>
          <v-row dense>
            <v-col>
              <v-card :loading="loadingData">
                <v-card-title> Faltas admin.</v-card-title>
                <v-card-text style="display: flex; justify-content: center">
                  <v-simple-table>
                    <tbody>
                      <tr>
                        <td style="font-weight: bold">Total</td>
                        <td style="
                            margin-left: 5px !important;
                            width: 25px;
                            text-align: center;
                          ">
                          {{ kardex.faltasAdminTotales }}
                        </td>
                      </tr>
                      <tr>
                        <td style="font-weight: bold">Anual</td>
                        <td style="
                            margin-left: 5px !important;
                            width: 25px;
                            text-align: center;
                          ">
                          {{ kardex.faltasAdminEsteAnio }}
                        </td>
                      </tr>
                      <tr>
                        <td style="font-weight: bold">Mensual</td>
                        <td style="
                            margin-left: 5px !important;
                            width: 25px;
                            text-align: center;
                          ">
                          {{ kardex.faltasAdminEsteMes }}
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn v-show="kardex.faltasAdminTotales > 0" small color="primary" text :disabled="loadingData"
                    :loading="loadingBoton" @click="verDetalles(1)">
                    Ver detalles
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col>
              <v-card :loading="loadingData">
                <v-card-title> Faltas injust.</v-card-title>
                <v-card-text style="display: flex; justify-content: center">
                  <v-simple-table>
                    <tbody>
                      <tr>
                        <td style="font-weight: bold">Total</td>
                        <td style="
                            margin-left: 5px !important;
                            width: 25px;
                            text-align: center;
                          ">
                          {{ kardex.faltasInjustTotales }}
                        </td>
                      </tr>
                      <tr>
                        <td style="font-weight: bold">Anual</td>
                        <td style="
                            margin-left: 5px !important;
                            width: 25px;
                            text-align: center;
                          ">
                          {{ kardex.faltasInjustEsteAnio }}
                        </td>
                      </tr>
                      <tr>
                        <td style="font-weight: bold">Mensual</td>
                        <td style="
                            margin-left: 5px !important;
                            width: 25px;
                            text-align: center;
                          ">
                          {{ kardex.faltasInjustEsteMes }}
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn v-show="kardex.faltasInjustTotales > 0" small color="primary" text :disabled="loadingData"
                    :loading="loadingBoton" @click="verDetalles(2)">
                    Ver detalles
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col>
              <v-card :loading="loadingData">
                <v-card-title> Incapacidades</v-card-title>
                <v-card-text style="display: flex; justify-content: center">
                  <v-simple-table>
                    <tbody>
                      <tr>
                        <td style="font-weight: bold">Total</td>
                        <td style="
                            margin-left: 5px !important;
                            width: 25px;
                            text-align: center;
                          ">
                          {{ kardex.incapacidadesTotales }}
                        </td>
                      </tr>
                      <tr>
                        <td style="font-weight: bold">Anual</td>
                        <td style="
                            margin-left: 5px !important;
                            width: 25px;
                            text-align: center;
                          ">
                          {{ kardex.incapacidadesEsteAnio }}
                        </td>
                      </tr>
                      <tr>
                        <td style="font-weight: bold">Mensual</td>
                        <td style="
                            margin-left: 5px !important;
                            width: 25px;
                            text-align: center;
                          ">
                          {{ kardex.incapacidadesEsteMes }}
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn v-show="kardex.incapacidadesTotales > 0" small color="primary" text :disabled="loadingData"
                    :loading="loadingBoton" @click="verDetalles(3)">
                    Ver detalles
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col>
              <v-card :loading="loadingData">
                <v-card-title> Vacaciones</v-card-title>
                <v-card-text style="display: flex; justify-content: center">
                  <v-simple-table>
                    <tbody>
                      <tr>
                        <td style="font-weight: bold">Total</td>
                        <td style="
                            margin-left: 5px !important;
                            width: 25px;
                            text-align: center;
                          ">
                          {{ kardex.vacacionesTotales }}
                        </td>
                      </tr>
                      <tr>
                        <td style="font-weight: bold">Anual</td>
                        <td style="
                            margin-left: 5px !important;
                            width: 25px;
                            text-align: center;
                          ">
                          {{ kardex.vacacionesEsteAnio }}
                        </td>
                      </tr>
                      <tr>
                        <td style="font-weight: bold">Mensual</td>
                        <td style="
                            margin-left: 5px !important;
                            width: 25px;
                            text-align: center;
                          ">
                          {{ kardex.vacacionesEsteMes }}
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn v-show="kardex.vacacionesTotales > 0" small color="primary" text :disabled="loadingData"
                    :loading="loadingBoton" @click="verDetalles(4)">
                    Ver detalles
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col>
              <v-card :loading="loadingData">
                <v-card-title> Permisos </v-card-title>
                <v-card-text style="display: flex; justify-content: center">
                  <v-simple-table>
                    <tbody>
                      <tr>
                        <td style="font-weight: bold">Total</td>
                        <td style="
                            margin-left: 5px !important;
                            width: 25px;
                            text-align: center;
                          ">
                          {{ kardex.permisosTotales }}
                        </td>
                      </tr>
                      <tr>
                        <td style="font-weight: bold">Anual</td>
                        <td style="
                            margin-left: 5px !important;
                            width: 25px;
                            text-align: center;
                          ">
                          {{ kardex.permisosEsteAnio }}
                        </td>
                      </tr>
                      <tr>
                        <td style="font-weight: bold">Mensual</td>
                        <td style="
                            margin-left: 5px !important;
                            width: 25px;
                            text-align: center;
                          ">
                          {{ kardex.permisosEsteMes }}
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn v-show="kardex.permisosTotales > 0" small color="primary" text :disabled="loadingData"
                    :loading="loadingBoton" @click="verDetalles(5)">
                    Ver detalles
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col>
              <v-card :loading="loadingData">
                <v-card-title style="display: flex; justify-content: center"> Tarjetas Bloqueo
                  Preventivas</v-card-title>
                <v-card-text style="display: flex; justify-content: center">
                  <v-simple-table>
                    <tbody>
                      <tr>
                        <td style="font-weight: bold">Total</td>
                        <td style="
                            margin-left: 5px !important;
                            width: 25px;
                            text-align: center;
                          ">
                          {{ kardex.tarjetasTotales }}
                        </td>
                      </tr>
                      <tr>
                        <td style="font-weight: bold">Anual</td>
                        <td style="
                            margin-left: 5px !important;
                            width: 25px;
                            text-align: center;
                          ">
                          {{ kardex.tarjetasEsteAnio }}
                        </td>
                      </tr>
                      <tr>
                        <td style="font-weight: bold">Mensual</td>
                        <td style="
                            margin-left: 5px !important;
                            width: 25px;
                            text-align: center;
                          ">
                          {{ kardex.tarjetasEsteMes }}
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn v-show="kardex.tarjetasTotales > 0" small color="primary" text :disabled="loadingData"
                    :loading="loadingBoton" @click="verDetalles(6)">
                    Ver detalles
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>

    <!-- Ver detalles -->
    <v-dialog v-model="verDetallesKardex" persistent max-width="70%">

      <v-card class="rounded-xl">
        <v-card-title class="text-subtitle-2 primary white--text">
          {{ titulo }}
          <v-spacer></v-spacer>
          <v-icon small dark @click="
            verDetallesKardex = false">mdi-close</v-icon>
        </v-card-title>
        <v-card-text>
          <v-data-table dense v-show="verDetallesKardex" :headers="headers" :items="items"
            :footer-props="{ 'items-per-page-text': 'Elementos por página' }"
            no-data-text="No se encontraron inconformidades">
            <template v-slot:item.estatus="{ item }">
              <div v-if="item.estatus == 1" class="d-flex justify-center">
                <v-chip-group column>
                  <v-btn x-small rounded elevation="1" color="red" dark>
                    Abierta
                  </v-btn>
                </v-chip-group>
              </div>
              <div v-else-if="item.estatus == 2" class="d-flex justify-center">
                <v-chip-group column>
                  <v-btn x-small rounded elevation="1" color="lime darken-1" dark>
                    Contactando
                  </v-btn>
                </v-chip-group>
              </div>
              <div v-else-if="item.estatus == 3" class="d-flex justify-center">
                <v-chip-group column>
                  <v-btn x-small rounded elevation="1" color="teal darken-1" dark>
                    Cerrada
                  </v-btn>
                </v-chip-group>
              </div>
              <div v-else-if="item.estatus == 4" class="d-flex justify-center">
                <v-chip-group column>
                  <v-btn x-small rounded elevation="1" color="light-green darken-1" dark>
                    Revisión
                  </v-btn>
                </v-chip-group>
              </div>
              <div v-else-if="item.estatus == 5" class="d-flex justify-center">
                <v-chip-group column>
                  <v-btn x-small rounded elevation="1" color="blue darken-1" dark>
                    Reparación
                  </v-btn>
                </v-chip-group>
              </div>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-sheet id="snack-bar-error-incidencias">
      <snack-bar-error-incidencias :muestraBarra="muestraBarra" :texto="textoBarra" :color="colorBarra"
        @cierraBarra="muestraBarra = false" />
    </v-sheet>
  </v-row>
</template>

<script>
import axios from "axios";
import SnackBarErrorIncidencias from "../views/generated/General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";
import { deleteToken } from '../store/Funciones/funciones'

export default {
  name: "persona-mi-kardex-laboral",
  //   components: {},
  data: () => ({
    kardex: [],
    loadingData: true,
    headers: [],
    items: [],
    verDetallesKardex: false,
    loadingBoton: false,
    titulo: "",
    muestraBarra: false,
    textoBarra: "",
    colorBarra: "pink",
    dialogDetalles: false
  }),
  components: {
    SnackBarErrorIncidencias
  },
  props: ["dialog", "folio_trabajador"],
  created() {
    this.saveMonitoreo();
  },
  mounted() {
    this.getKatdex();
  },
  methods: {
    saveMonitoreo() {
      let params = {
        Opcion: 10,
        pantalla: "Mi kardex",
        token: localStorage.getItem("token"),
      };
      axios
        .post(`${this.$store.getters.getHost}/General/ws-general.php`, params)
        .then((response) => {
          // console.log("monitoreo guardado. " + response.data)
          console.log(response.data);
        });
    },
    getKatdex() {
      let params = {
        Opcion: 6,
        token: localStorage.getItem("token"),
        Folio: this.folio_trabajador,
      };
      axios
        .post(`${this.$store.getters.getHost}/Kardex/ws-kardex.php`, params)
        .then((response) => {
          if (response.data.status == "EXPSESSION") {
            deleteToken();
          } else {
            var data = response.data.infoKardex[0];
            this.kardex = data;
            this.loadingData = false;
          }
          //(response.data.infoKardex[0])
        })
        .catch(function (e) {
          let result = e.toString().includes("401");
          if (result) {
            window.location.reload();
          } else {
            alert(e);
          }
        });
    },

    verDetalles: function (opc) {
      this.loadingBoton = true;
      if (opc == 1 && this.kardex["faltasAdminTotales"] > 0) {
        this.titulo = "Faltas Administrativas";
        this.headers = [
          {
            align: "center",
            text: "Fecha",
            sortable: false,
            value: "Fecha",
          },
          {
            align: "center",
            text: "Comentario",
            sortable: false,
            value: "comentario",
          },
        ];
        let params = {
          Opcion: 5,
          token: localStorage.getItem("token"),
          Folio: this.folio_trabajador,
        };
        axios
          .post(`${this.$store.getters.getHost}/Kardex/ws-kardex.php`, params)
          .then((response) => {
            if (response.data.kardex && response.data.kardex.length > 0) {
              var data = response.data.kardex;
              this.items = data;
              this.validaRespuesta();
            } else {
              this.items = [];
              this.loadingBoton = false;
              alert('No se pudieron obtener los datos');
            }

          })
          .catch(function (e) {
            let result = e.toString().includes("401");
            if (result) {
              window.location.reload();
            } else {
              alert(e);
            }
          });
      } else if (opc == 2 && this.kardex["faltasInjustTotales"] > 0) {
        this.titulo = "Faltas Injustificadas";
        this.headers = [
          {
            align: "center",
            text: "Fecha",
            sortable: false,
            value: "Fecha",
          },
          {
            align: "center",
            text: "Comentario",
            sortable: false,
            value: "comentario",
          },
        ];
        let params = {
          Opcion: 4,
          token: localStorage.getItem("token"),
          Folio: this.folio_trabajador,
        };
        axios
          .post(`${this.$store.getters.getHost}/Kardex/ws-kardex.php`, params)
          .then((response) => {
            if (response.data.kardex && response.data.kardex.length > 0) {
              var data = response.data.kardex;
              this.items = data;
              this.validaRespuesta();
            } else {
              this.items = [];
              this.loadingBoton = false;
              alert('No se pudieron obtener los datos');
            }

          })
          .catch(function (e) {
            let result = e.toString().includes("401");
            if (result) {
              window.location.reload();
            } else {
              alert(e);
            }
          });
      } else if (opc == 3 && this.kardex["incapacidadesTotales"] > 0) {
        this.titulo = "Incapacidades";
        this.headers = [
          {
            align: "center",
            text: "Fecha",
            sortable: false,
            value: "Fecha",
          },
          {
            align: "center",
            text: "Comentario",
            sortable: false,
            value: "incapacidad",
          },
        ];
        let params = {
          Opcion: 3,
          token: localStorage.getItem("token"),
          Folio: this.folio_trabajador,
        };
        axios
          .post(`${this.$store.getters.getHost}/Kardex/ws-kardex.php`, params)
          .then((response) => {
            if (response.data.kardex && response.data.kardex.length > 0) {
              var data = response.data.kardex;
              this.items = data;
              this.validaRespuesta();
            } else {
              this.items = [];
              this.loadingBoton = false;
              alert('No se pudieron obtener los datos');
            }

          })
          .catch(function (e) {
            let result = e.toString().includes("401");
            if (result) {
              window.location.reload();
            } else {
              alert(e);
            }
          });
      } else if (opc == 4 && this.kardex["vacacionesTotales"] > 0) {
        this.titulo = "Vacaciones";
        this.headers = [
          {
            align: "center",
            text: "Fecha",
            sortable: false,
            value: "Fecha",
          },
          {
            align: "center",
            text: "Estatus",
            sortable: false,
            value: "Estatus",
          },
        ];
        let params = {
          Opcion: 2,
          token: localStorage.getItem("token"),
          Folio: this.folio_trabajador,
        };
        axios
          .post(`${this.$store.getters.getHost}/Kardex/ws-kardex.php`, params)
          .then((response) => {
            if (response.data.kardex && response.data.kardex.length > 0) {
              var data = response.data.kardex;
              this.items = data;
              this.validaRespuesta();
            } else {
              this.items = [];
              this.loadingBoton = false;
              alert('No se pudieron obtener los datos');
            }

          })
          .catch(function (e) {
            let result = e.toString().includes("401");
            if (result) {
              window.location.reload();
            } else {
              alert(e);
            }
          });
      } else if (opc == 5 && this.kardex["permisosTotales"] > 0) {
        this.titulo = "Permisos";
        this.headers = [
          {
            align: "center",
            text: "Fecha",
            sortable: false,
            value: "Fecha",
          },
          {
            align: "center",
            text: "Estatus",
            sortable: false,
            value: "Estatus",
          },
          {
            align: "center",
            text: "Tipo Incidencia",
            sortable: false,
            value: "TipoIncidencia",
          },
          {
            align: "center",
            text: "Tipo Permiso",
            sortable: false,
            value: "TipoPermiso",
          },
          {
            align: "center",
            text: "Comentarios",
            sortable: false,
            value: "Comentarios",
          },
        ];
        let params = {
          Opcion: 1,
          token: localStorage.getItem("token"),
          Folio: this.folio_trabajador,
        };
        axios
          .post(`${this.$store.getters.getHost}/Kardex/ws-kardex.php`, params)
          .then((response) => {
            if (response.data.kardex && response.data.kardex.length > 0) {
              var data = response.data.kardex;
              this.items = data;
              this.validaRespuesta();
            } else {
              this.items = [];
              this.loadingBoton = false;
              alert('No se pudieron obtener los datos');
            }

          })
          .catch(function (e) {
            let result = e.toString().includes("401");
            if (result) {
              window.location.reload();
            } else {
              alert(e);
            }
          });
      } else if (opc == 6 && this.kardex["tarjetasTotales"] > 0) {
        this.titulo = "Tarjetas de bloqueo o preventivas";
        this.headers = [
          {
            align: "center",
            text: "Fecha",
            sortable: false,
            value: "fechaInconformidad",
          },
          {
            align: "center",
            text: "Estatus",
            sortable: false,
            value: "estatus",
          },
          {
            align: "center",
            text: "Maquina",
            sortable: false,
            value: "maquina",
          },
          {
            align: "center",
            text: "Tipo tarjeta",
            sortable: false,
            value: "tipoTarjeta",
          },
        ];
        let params = {
          Opcion: 7,
          token: localStorage.getItem("token"),
          Folio: this.folio_trabajador,
        };
        axios
          .post(`${this.$store.getters.getHost}/Kardex/ws-kardex.php`, params)
          .then((response) => {
            if (response.data.kardex && response.data.kardex.length > 0) {
              var data = response.data.kardex;
              this.items = data;
              this.validaRespuesta();
            } else {
              this.items = [];
              this.loadingBoton = false;
              alert('No se pudieron obtener los datos');
            }

          })
          .catch(function (e) {
            let result = e.toString().includes("401");
            if (result) {
              window.location.reload();
            } else {
              alert(e);
            }
          });
      }
    },
    validaRespuesta: function () {
      this.loadingBoton = false;
      if (this.items.length > 0) {
        this.verDetallesKardex = true;
      }
    },
  },
};
</script>