<!-- https://vuetifyjs.com/en/components/app-bars/#images -->

<template>
  <v-card class="rounded-0">
    <v-app-bar id="site-header" absolute color="primary" dark shrink-on-scroll fade-img-on-scroll
      :src="require('@/assets/img/header_banner.jpg')" scroll-target="#site-content" app v-resize="onResize">
      <!-- Header Image -->
      <template v-slot:img="{ props }">
        <v-img v-bind="props" gradient="to top right, rgba(0,120,195,.7), rgba(0,120,195,.9)"></v-img>
      </template>
      <!-- Header Image -->

      <!-- Header Buttons -->
      <v-app-bar-nav-icon @click.stop="$emit('toggle-drawer')"></v-app-bar-nav-icon>
      <v-app-bar-title class="subtitle-1 font-weight-bold">{{
        $t($route.name)
      }}</v-app-bar-title>
      <!--  -->
      <v-autocomplete v-model="routeSelected"
        v-if="!['xs', 'sm'].includes($vuetify.breakpoint.name) && $store.getters.isMultisistema === true"
        class="rounded-lg ml-2 my-1" style="max-height: 38px !important;" :items="$store.getters.getMenuList"
        item-text="title" prepend-inner-icon="mdi-magnify" :placeholder="$t('searchBar')" dense flat solo-inverted
        clearable dark v-on:change="getRoute"></v-autocomplete>
      <v-spacer v-else></v-spacer>
      <div v-if="!isSmallDisplay">
        <!-- <v-tooltip v-if="![0].includes($store.getters.getUser['Folio']) && $store.getters.getHost == 'http://localhost:99' && compania == '00100'" color="accent" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" @click="openCA = true">
              <v-icon>mdi-chat-sleep</v-icon>
            </v-btn>
          </template>
          <span>CAJA</span>
        </v-tooltip> -->
        <v-tooltip v-if="![0].includes($store.getters.getUser['Folio']) && compania == '00100'" color="accent" bottom>

          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" @click="openMiNomina = true">
              <v-icon>mdi-cash</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("MiNomina") }}</span>
        </v-tooltip>
        <v-tooltip v-if="![0].includes($store.getters.getUser['Folio']) && compania == '00100'" color="accent" bottom>

          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" @click="openKardex = true">
              <v-icon>mdi-notebook-outline</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("Kardex") }}</span>
        </v-tooltip>
        <v-tooltip color="accent" bottom v-if="$store.getters.getUser['compania'] != 'CANDIDATO'">

          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" @click="openValores = true">
              <v-icon>mdi-charity</v-icon>
            </v-btn>
          </template>
          <span>
            {{ $t("Premia Principios") }}
          </span>
        </v-tooltip>
        <!-- <v-tooltip v-if="![0].includes($store.getters.getUser['Folio']) && compania == '00100'" color="accent" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" @click="openAlu = true">
              <v-icon>mdi-chat</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("Asistente Laboral Urrea") }}</span>
        </v-tooltip> -->
        <v-tooltip v-if="compania == '00100'" color="accent" bottom>

          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" @click="abrirNuevaVentana(1)">
              <v-icon>mdi-school</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("universidad") }}</span>
        </v-tooltip>


        <!-- <v-tooltip v-if="compania == '00100' && gTrabajo === 1 "  color="accent" bottom>

          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" @click="abrirNuevaVentana(3)">
              <v-icon>mdi-account-details-outline</v-icon>
            </v-btn>
          </template>
          <span>Mis objetivos</span>
        </v-tooltip> -->


      <!--  <v-tooltip v-if="compania == '00100'" color="accent" bottom>

          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" @click="abrirNuevaVentana(2)">
              <v-icon>mdi-earth-arrow-right </v-icon>
            </v-btn>
          </template>
          <span>Portal DH 1.0</span>
        </v-tooltip>-->

        <v-tooltip color="accent" bottom>

          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" value="logout" @click="deleteTokenHeader()">
              <v-icon>mdi-logout</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("Salir") }}</span>
        </v-tooltip>
      </div>
      <div v-else>
        <v-tooltip color="accent" bottom>

          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" value="logout" @click="openNotificaciones = true">
              <v-icon :color="iconColor">{{ iconNotifications }}</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("Salir") }}</span>
        </v-tooltip>
        <v-menu>

          <template v-slot:activator="{ on, attrs }">
            <v-btn dark icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list rounded>
            <v-list-item-group v-if="![0].includes($store.getters.getUser['Folio']) && compania == '00100'"
              color="primary">
              <v-list-item @click="openMiNomina = true">
                <v-list-item-icon>
                  <v-icon> mdi-cash </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title> {{ $t("MiNomina") }} </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>

            <v-list-item-group v-if="![0].includes($store.getters.getUser['Folio']) && compania == '00100'"
              color="primary">
              <v-list-item @click="openKardex = true">
                <v-list-item-icon>
                  <v-icon> mdi-notebook-outline </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title> {{ $t("Kardex") }} </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>

            <v-list-item-group color="primary" v-if="$store.getters.getUser['compania'] != 'CANDIDATO'">
              <v-list-item @click="openValores = true">
                <v-list-item-icon>
                  <v-icon> mdi-charity </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ $t("Premia Principios") }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>

            <!-- <v-list-item-group v-if="![0].includes($store.getters.getUser['Folio']) && compania == '00100'" color="primary">
              <v-list-item @click="openAlu = true">
                <v-list-item-icon>
                  <v-icon> mdi-chat </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ $t("Asistente Laboral Urrea") }}</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group> -->

            <v-list-item-group v-if="![0].includes($store.getters.getUser['Folio']) &&
              $store.getters.getUser['compania'] != 'CANDIDATO'
            " color="primary">
              <v-list-item @click="openCA = true">
                <v-list-item-icon>
                  <v-icon> mdi-chat </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ $t("Asistente Laboral Urrea") }} 2.0</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>

            <v-list-item-group v-if="compania == '00100'" color="primary">
              <v-list-item @click="abrirNuevaVentana(1)">
                <v-list-item-icon>
                  <v-icon> mdi-school </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ $t("universidad") }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
            <!-- 
            <v-list-item-group v-if="compania == '00100'" color="primary">
              <v-list-item @click="abrirNuevaVentana(2)">
                <v-list-item-icon>
                  <v-icon> mdi-earth-arrow-right </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title> Portal DH 1.0 </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>-->

            <v-list-item-group color="primary">
              <v-list-item to="/Login">
                <v-list-item-icon>
                  <v-icon> mdi-logout </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title> {{ $t("Salir") }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </div>
      <!-- Header Buttons -->
    </v-app-bar>

    <v-main class="overflow-y-auto" id="site-content">
      <!-- Main Content -->
      <router-view />
    </v-main>

    <!-- <div id="webchat" role="main"></div> -->
    <!-- Muestra ALU interno -->
    <v-sheet id="persona-alu">
      <persona-alu v-if="openAlu" :dialog="openAlu" @cierraAlu="openAlu = false">
      </persona-alu>
    </v-sheet>

    <!-- Muestra nominas timbradas -->
    <v-sheet>
      <persona-mi-nomina v-if="openMiNomina" :dialog="openMiNomina" @cierraMiNomina="openMiNomina = false" />

    </v-sheet>

    <!-- Muestra asignacion de valores -->
    <v-sheet>
      <persona-asignacion-de-valores v-if="openValores" :dialog="openValores"
        @cierraAsignacionValores="openValores = false" />
    </v-sheet>

    <!-- Muestra kardex laboral -->
    <v-sheet>
      <persona-mi-kardex-laboral v-if="openKardex" :dialog="openKardex" @cierraKardexLaboral="openKardex = false" />
    </v-sheet>
    <v-sheet>
      <dialog-notificaciones-mobile v-if="openNotificaciones" :verNotificaciones="openNotificaciones"
        @closeNotificaciones="openNotificaciones = false"></dialog-notificaciones-mobile>
    </v-sheet>

    <PersonaCajaDeAhorro v-if="openCA" :verCajaDeAhorro="openCA" @cierraCA="openCA = false" />
    <v-container>
      <FormularioBeneficosFlex v-if="openBF" :verEncuestaBeneficios="openBF" @cierraBF="openBF = false" />
    </v-container>
    <v-container>
      <FormularioRutas v-if="openRutas" :verEncuestaRutas="openRutas" @cierraRutas="openRutas = false" />
    </v-container>
    <!-- <PersonaCajaDeAhorro v-if="openCA" :verCajaDeAhorro="openCA" @cierraCA="openCA = false"/> -->
  </v-card>
</template>

<script>
import PersonaAlu from "./PersonaAlu.vue";
import PersonaMiNomina from "./PersonaMiNomina.vue";
import PersonaAsignacionDeValores from "./PersonaAsignacionDeValores.vue";
import PersonaMiKardexLaboral from "./PersonaMiKardexLaboral.vue";
import DialogNotificacionesMobile from "./DialogNotificacionesMobile.vue";
import PersonaCajaDeAhorro from "./PersonaCajaDeAhorro.vue";
import FormularioBeneficosFlex from "./FormularioBeneficosFlexibles.vue";
import FormularioRutas from "./FormularioRutas.vue";
import axios from "axios";
import { deleteToken } from "../store/Funciones/funciones";

export default {
  name: "nav-header-menu",
  components: {
    PersonaAlu,
    PersonaMiNomina,
    PersonaAsignacionDeValores,
    PersonaMiKardexLaboral,
    DialogNotificacionesMobile,
    PersonaCajaDeAhorro,
    FormularioBeneficosFlex,
    FormularioRutas,
  },
  data: () => ({
    compania: "",
    routeSelected: "",
    openAlu: false,
    openMiNomina: false,
    openMiNominaZentric: false,
    openValores: false,
    openKardex: false,
    openNotificaciones: false,
    openCA: false,
    iconNotifications: "mdi-bell",
    iconColor: "blue-grey lighten-5",
    items: [
      {
        header: "Notificaciones",
      },
    ],
    windowSize: {
      x: 0,
      y: 0,
    },
    openBF: false,
    openRutas: false,
    itteam: [18378, 15689, 19706, 12026, 18517, 19193, 10345, 11378, 10720],
    gTrabajo: 0
  }),
  mounted() {
    this.onResize();
    this.findNotifications();
    this.getCompania();
    this.gTrabajo = localStorage.getItem("gTrabajo");
    // console.log(this.gTrabajo);

    // console.log(JSON.stringify(this.$store.getters.getMenuList, null, 2));
  },
  computed: {
    isSmallDisplay() {
      return this.$vuetify.breakpoint.name == "xs" ||
        this.$vuetify.breakpoint.name == "sm" ||
        this.$vuetify.breakpoint.name == "md"
        ? true
        : false;
    },
  },
  methods: {
    getRoute() {
      let route = this.$store.getters.getMenuList.filter((r) => {
        return r.title === this.routeSelected
      })[0]

      if (route) {
        this.$router.push(route.url);
        this.routeSelected = "";
      }
    },
    getCompania() {
      let paramsEstatusInc = {
        Opcion: 28,
        token: localStorage.getItem("token"),
      };
      axios
        .post(
          `${this.$store.getters.getHost}/General/ws-general.php`,
          paramsEstatusInc
        )
        .then((response) => {
          if (response.data.status == "EXPSESSION") {
            deleteToken();
          } else {
            this.compania = response.data.compania;
            if (this.compania === '00100') {
              // this.getEncuestaBF();
              // this.getEncuestaRutas();
            }
          }
        })
        .catch(function (e) {
          let result = e.toString().includes("401");
          if (result) {
            window.location.reload();
          } else {
            alert(e);
          }
        });
    },

    onResize() {
      this.windowSize = {
        x: window.innerWidth,
        y: window.innerHeight,
      };
    },
    abrirNuevaVentana(opc) {
      let url = "";
      switch (opc) {
        case 1:
          url = "http://universidad2.urrea.com/login/index.php";
          break;
        case 2:
          url =
            "https://apidh.urrea.com/phpold/dhNoticias.php?ky=" +
            localStorage.getItem("token");
          break;
        case 3:
          url =
            "https://desarrollohumano.urrea.com/#/MiEmpresa/MisObjetivos";

          break;
      }

      let nuevaVentana = window.open(url);
      if (nuevaVentana) {
        nuevaVentana.focus();
      }
    },
    deleteTokenHeader() {
      deleteToken();
    },
    findNotifications() {
      let params = {
        Opcion: 5,
        token: localStorage.getItem("token"),
      };
      axios
        .post(
          `${this.$store.getters.getHost}/Incidencias/ws-incidencias-autorizacion.php`,
          params
        )
        .then((response) => {
          if (response.data.status == "EXPSESSION") {
            deleteToken();
          } else {
            var data = response.data.incidencias;
            this.respuestaApiIncidenciasPorAutorizar = data;
            if (this.respuestaApiIncidenciasPorAutorizar > 0) {
              this.iconNotifications = "mdi-bell-badge";
              this.iconColor = "yellow accent-3";
            }
            // if (this.$store.getters.getHost == process.env.VUE_APP_API_LOCAL) {
            this.getNotificaciones();
            // }
          }
        })
        .catch(function (e) {
          console.log(e);
        });
    },
    getNotificaciones() {
      let params = {
        Opcion: 15,
        token: localStorage.getItem("token"),
      };
      axios
        .post(`${this.$store.getters.getHost}/General/ws-general.php`, params)
        .then((response) => {
          if (response.data.status == "EXPSESSION") {
            deleteToken();
          } else {
            if (response.data.status != "Vacio") {
              var data = response.data.notificaciones;
              data.map((notificacion) => {
                this.items.push({
                  id: notificacion.id,
                  color: notificacion.color,
                  icon: notificacion.icono,
                  title: notificacion.titulo,
                  type: notificacion.subtitulo,
                  subtitle: notificacion.comentario,
                  action: "",
                });
              });
            }
            if (this.items.length > 1) {
              this.iconNotifications = "mdi-bell-badge";
              this.iconColor = "yellow accent-3";
            }
          }
        })
        .catch(function (e) {
          //
          console.log(e);
          this.validaRespuesta(); // "oh, no!"
        });
    },
    getEncuestaBF() {
      let params = {
        Opcion: 8,
        token: localStorage.getItem("token"),
        proyecto: 1
      };
      axios
        .post(`${this.$store.getters.getHost}/Compensaciones/ws-beneficios-flexibles.php`, params)
        .then((response) => {
          if (response.data.status == "EXPSESSION") {
            deleteToken();
          } else {
            if (response.data.status == "Conestada") {
              this.openBF = false;
              //this.openBF = true;
            }
            if (response.data.status == "Vacio") {
              if (this.itteam.includes(response.data.folio)) {
                console.log('Dentro del team');
                this.openBF = true;
              } else {
                this.openBF = true;
                console.log('Fuera del team');

              }
            }
          }
        })
        .catch(function (e) {
          //
          console.log(e);
        });
    },
    getEncuestaRutas() {
      let params = {
        Opcion: 8,
        token: localStorage.getItem("token"),
        proyecto: 2
      };
      axios
        .post(`${this.$store.getters.getHost}/Compensaciones/ws-beneficios-flexibles.php`, params)
        .then((response) => {
          if (response.data.status == "EXPSESSION") {
            deleteToken();
          } else {
            if (response.data.status == "Conestada") {
              this.openRutas = false;
              //this.openRutas = true;
            }
            if (response.data.status == "Vacio") {
              if (this.itteam.includes(response.data.folio)) {
                console.log('Dentro del team rutas');
                this.openRutas = true;
              } else {
                this.openRutas = true;
                console.log('Fuera del team rutas');

              }
            }
          }
        })
        .catch(function (e) {
          //
          console.log(e);
        });
    },
  },
};
</script>

<style lang="sass" scoped>
#site-header
  z-index: 9

#site-content
  padding-top: 128px !important
  height: 100vh
  overflow-y: auto !important
  overflow-x: hidden !important
</style>
