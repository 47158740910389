<template>
  <v-dialog
    v-model="dialog"
    max-width="600px"
    transition="dialog-bottom-transition"
    persistent
  >
    <v-card>
      <v-card-title class="text-subtitle-2 primary white--text">
        Encuesta de satisfacción interna
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text class="pt-5">
        <v-row>
          <v-col
            cols="12"
            xs="12"
            sm="12"
            md="12"
            lg="12"
            xl="12"
            class="d-flex align-center"
          >
            <div class="font-italic text-justify">
              ¡Ayúdanos a mejorar! Te invitamos a responder la encuesta de
              satisfacción interna. Tu participación nos permitirá realizar un
              análisis más preciso del ambiente laboral. ¡Recuerda que juntos
              hacemos de este lugar el mejor para trabajar!.
            </div>
          </v-col>
          <v-col cols="12" align="center">
            <v-btn
              v-show="!mostrarBoton"
              class="rounded-lg custom-outline-btn"
              text
              color="primary"
              outlined
              href="https://apidh.urrea.com/EncuestaInterna/sgw-v3/Encuesta_login.php"
              target="_blank"
              :loading="loading"
              @click="loadingResponse"
              >Comenzar encuesta</v-btn
            >
            <v-btn
              v-show="mostrarBoton && !loading"
              class="rounded-lg custom-outline-btn-2"
              dark
              color="success"
              :loading="loadingCambio"
              @click="cambiarStatus"
              >Terminé mi encuesta</v-btn
            >
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <SnackBarErrorIncidencias
      v-if="muestraBarra"
      :muestraBarra="muestraBarra"
      :texto="textoBarra"
      :color="colorBarra"
      @cierraBarra="muestraBarra = false"
    />
  </v-dialog>
</template>

<script>
import axios from "axios";
import { deleteToken } from "../store/Funciones/funciones";
import SnackBarErrorIncidencias from "../views/generated/General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";

export default {
  name: "aviso-gptw",
  props: ["dialog"],
  components: { SnackBarErrorIncidencias },
  data() {
    return {
      textoBarra: "",
      colorBarra: "",
      muestraBarra: false,

      loading: false,
      loadingCambio: false,
      mostrarBoton: false,
    };
  },
  created() {},
  mounted() {},
  methods: {
    loadingResponse() {
      this.loading = true;
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(this.showRefresh());
        }, 10000);
      });
    },
    showRefresh() {
      this.loading = false;
      this.mostrarBoton = true;
    },
    cambiarStatus() {
      this.loadingCambio = true;
      let folio = this.$store.getters.getUser["Folio"];
      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      return new Promise((res) => {
        axios
          .get(
            `${this.$store.getters.getHostNode}/api/get-encuesta-pendiente-gptw/${folio}`,
            {
              headers: headers,
            }
          )
          .then((response) => {
            if (response.data.status == "EXPSESSION") {
              deleteToken();
            } else {
              res(response.data.data);
              console.log(response.data.data);
              if (!response.data.data) {
                this.textoBarra = "Verificación exitosa, muchas gracias";
                this.colorBarra = "success";
                this.muestraBarra = true;
                return new Promise((resolve) => {
                  setTimeout(() => {
                    resolve(this.$emit("close"));
                  }, 3000);
                });
              } else {
                this.textoBarra =
                  "Lo siento, aún no encontramos registros de tus respuestas, por favor completa todas las preguntas";
                this.colorBarra = "error";
                this.muestraBarra = true;
              }
            }
          })
          .finally(() => {
            this.loadingCambio = false;
          });
      });
    },
  },
};
</script>
<style scoped>
.custom-outline-btn {
  border: 2px solid var(--v-primary-base) !important;
}
.custom-outline-btn-2 {
  border: 2px solid var(--v-success-base) !important;
}
</style>