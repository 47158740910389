<template>
  <v-dialog v-model="muestraAdmAutorizaciones" fullscreen scrollable>
    <v-card class="rounded-0">
      <v-card-title class="primary">
        <label class="overline white--text font-weight-bold">
          autorizaciones pendientes 
        </label>
        <v-spacer></v-spacer>
        <v-icon dark @click="$emit('cierraDialogAutorizaciones')"
          >mdi-close</v-icon
        >
      </v-card-title>
      <v-card-text class="pt-5">
        <!-- {{ 'vacantes es ' +numVacantes }} -->
        <v-row>
          <v-col :cols="numCols">
            <div v-if="numVacantesAux > 0" class="pb-5">
              <PersonaAutorizaVacantes @validaEstado="validaNumVacantes"/>
            </div>
            <div v-if="numVisitasAux > 0" >
              <PersonaAutorizaVisitas @validaEstadoVis="validaNumVisitas"/>
            </div>
            <div v-if="numTiempoExtraAux > 0" >
              <PersonaAutorizaTiempoExtra @validaEstadoTE="validaNumTE"/>
            </div>
          </v-col>
          <v-col :cols="12 - numCols == 0 || (numVacantesAux <= 0 && numVisitasAux <= 0)  ? 12 : 12 - numCols">
            <div v-if="numIncidenciasAux > 0">
              <PersonaAutorizarIncidencias @validaEstadoInc="validaNumIncidencias" />
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import PersonaAutorizarIncidencias from "../views/generated/General/Inicio/components/PersonaAutorizarIncidencias.vue";
import PersonaAutorizaVacantes from "../views/generated/General/Inicio/components/PersonaAutorizaVacantes.vue"
import PersonaAutorizaVisitas from "../views/generated/General/Inicio/components/PersonaAutorizaVisitas.vue"
import PersonaAutorizaTiempoExtra from "../views/generated/General/Inicio/components/PersonaAutorizaTiempoExtra.vue"

export default {
  name: "persona-administracion-autorizaciones",
  props: ["muestraAdmAutorizaciones", "numIncidencias", "numVacantes", "numVisitas","tiempoextra"],
  components: {
    PersonaAutorizarIncidencias,
    PersonaAutorizaVacantes,
    PersonaAutorizaVisitas,
    PersonaAutorizaTiempoExtra
},
  data() {
    return {
        numVacantesAux: 0,
        numVisitasAux: 0,
        numTiempoExtraAux: 0,
        numIncidenciasAux: 0
    };
  },
  computed: {
    numCols() {
        return ['xs', 'sm'].includes(this.$vuetify.breakpoint.name) ? 12 : this.$vuetify.breakpoint.name === 'md' ? 7 : 6
    }
  },
  mounted(){
    this.numVacantesAux = this.numVacantes;
    this.numVisitasAux = this.numVisitas;
    this.numIncidenciasAux = this.numIncidencias;
    this.numTiempoExtraAux = this.tiempoextra;
  },
  methods: {
    validaNumVacantes(respuesta){
        if(respuesta == 0){
            this.numVacantesAux = 0;
        }
    },
    validaNumVisitas(respuesta){
        if(respuesta == 0){
            this.numVisitasAux = 0;
        }
    },
    validaNumIncidencias(respuesta){
        if(respuesta == 0){
            this.numIncidenciasAux = 0;
        }
    },
    validaNumTE(respuesta){
        if(respuesta == 0){
            this.numTiempoExtraAux = 0;
        }
    },
  }
};
</script>
