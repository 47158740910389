<template>
  <v-dialog max-width="450" v-model="dialog" persistent class="rounded-xxl">
    <v-card>
      <v-toolbar color="primary" dark dense>
        Aviso de actualización de datos 
        <v-spacer></v-spacer>
        <v-icon dark @click="$emit('cierraAlertaDocumentos')">mdi-close</v-icon>
      </v-toolbar>
      <v-card-text class="pt-5 pb-0">
        <v-row>
          <v-col class="d-flex justify-center pb-0" 
            cols="12" xl="12" lg="12" md="12" sm="12" xs="12">
              <v-icon x-large>mdi mdi-file-document-alert</v-icon>
          </v-col>
          <v-col class="d-flex justify-center pb-0" 
            cols="12" xl="12" lg="12" md="12" sm="12" xs="12">
            {{ mensaje }}
          </v-col>
          <v-col class="d-flex justify-center pb-0"
            cols="12" xl="6" lg="6" md="6" sm="12" xs="12">
            Formulario completado: <v-icon :color="colorForm">{{iconForm}}</v-icon>
          </v-col>
          <v-col class="d-flex justify-center pb-0" 
            cols="12" xl="6" lg="6" md="6" sm="12" xs="12">
            <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">Documentos cargados: <v-icon :color="colorCargados">{{iconDocs}}</v-icon></div>
                </template>
                <span>
                    Documentos Pendientes: {{datosInfo["Pendiente"]}} 
                    Documentos cargados: {{datosInfo["Completado"]}} 
                </span>
            </v-tooltip>
          </v-col>
        </v-row>
        <br />
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn
          color="primary"
          elevation="2"
          dark
          @click="redireccionar()"
          :loading="loadingBotonGuardar"
          >{{ mensajeBtn }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "alerta-carga-documentos",
  components: {},
  props: ["dialog", "mensaje", "mensajeBtn", "datosInfo"],
  data() {
    return {
      muestraBarra: false,
      textoBarra: "",
      colorBarra: "",
      loadingBotonGuardar: false,
      iconDocs: "",
      iconForm: "",

      colorForm:"grey",
      colorCargados:"grey",
      openDialogForm:false
    };
  },
  computed: {},
  mounted() {
    this.checkBtn();
  },
  methods: {
    checkBtn(){
        this.colorForm = this.datosInfo["FormCompletado"] == 1 ? "green darken-2" : "red darken-2";
        this.colorCargados = this.datosInfo["Completado"] == 8 ? "green darken-2" : "red darken-2";
        this.iconDocs = this.datosInfo["Completado"] == 8 ? "mdi mdi-check-circle" : "mdi mdi-clock-alert";
        this.iconForm = this.datosInfo["FormCompletado"] == 1 ? "mdi mdi-check-circle" : "mdi mdi-clock-alert";
    },
    redireccionar() {
      this.$emit("cierraAlertaDocumentos");
      // }
    },
  },
};
</script>
