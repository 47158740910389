    import axios from 'axios'

    export function genRandonString(long) {
        var chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        var charLength = chars.length;
        var result = '';
        for (var i = 0; i < long; i++) {
            result += chars.charAt(Math.floor(Math.random() * charLength));
        }
        return result;
    }

    export function formatDateISO(date) {
        if (!date) return null;

        let [day, month, year] = date.split("/");
        switch (month) {
            case "ENE":
                month = "01";
                break;
            case "FEB":
                month = "02";
                break;
            case "MAR":
                month = "03";
                break;
            case "ABR":
                month = "04";
                break;
            case "MAY":
                month = "05";
                break;
            case "JUN":
                month = "06";
                break;
            case "JUL":
                month = "07";
                break;
            case "AGO":
                month = "08";
                break;
            case "SEP":
                month = "09";
                break;
            case "OCT":
                month = "10";
                break;
            case "NOV":
                month = "11";
                break;
            case "DIC":
                month = "12";
                break;
        }
        return `${year}-${month}-${day}`;
    }

    export function formatDate(date) {
        if (!date) return null;

        let [year, month, day] = date.split("-");
        switch (month) {
            case "01":
                month = "ENE";
                break;
            case "02":
                month = "FEB";
                break;
            case "03":
                month = "MAR";
                break;
            case "04":
                month = "ABR";
                break;
            case "05":
                month = "MAY";
                break;
            case "06":
                month = "JUN";
                break;
            case "07":
                month = "JUL";
                break;
            case "08":
                month = "AGO";
                break;
            case "09":
                month = "SEP";
                break;
            case "10":
                month = "OCT";
                break;
            case "11":
                month = "NOV";
                break;
            case "12":
                month = "DIC";
                break;
        }
        return `${day}/${month}/${year}`;
    }

    export function deleteToken() {
        let params = {
            Opcion: 2,
            token: localStorage.getItem('token')
        }

        let host = "";
        if (window.location.hostname == 'localhost') {
            host = process.env.VUE_APP_API_LOCAL;
        } else {
            host = process.env.VUE_APP_API_SERVER;
        }

        // let hostFront = "";
        // if (window.location.hostname == 'localhost') {
        //     hostFront = process.env.VUE_FRONT_LOCAL;
        // } else {
        //     hostFront = process.env.VUE_FRONT_PROD;
        // }


        axios.post(host + '/Login/ws-login.php', params).then((response) => {
            let data = response.data
            if (data.trim() == "OK") {
                window.location.replace('/#/Login');
            } else {
                alert(data);
            }

        })
    }

    export function saveMonitoreo(pantalla) {
        let params = {
            Opcion: 10,
            pantalla: pantalla,
            token: localStorage.getItem('token')
        }


        let host = "";
        if (window.location.hostname == 'localhost') {
            host = process.env.VUE_APP_API_LOCAL;
        } else {
            host = process.env.VUE_APP_API_SERVER;
        }

        axios
            .post(host + '/General/ws-general.php', params)
            .then((response) => {
                console.log(response.data)
            })
    }

    export async function getTokenLife(token) {
        let params = {
            Opcion: 20,
            token: token,
        };

        let host = "";
        if (window.location.hostname == 'localhost') {
            host = process.env.VUE_APP_API_LOCAL;
        } else {
            host = process.env.VUE_APP_API_SERVER;
        }

        return new Promise((res, rej) => {
            axios
                .post(host + '/General/ws-general.php', params)
                .then((response) => {
                    if (response.data.status == "OK") {
                        res(response.data.tokenLife * 1000);
                        // this.startTimer()
                    } else {
                        rej(0);
                    }
                });
        });
    }

    export async function resetSession(token) {
        let params = {
            tokenActual: token
        };

        const headers = {
            Authorization: "JWT " + localStorage.getItem("token"),
        };

        let host = "";
        if (window.location.hostname == 'localhost') {
            host = process.env.VUE_APP_API_NODE_LOCAL;
        } else {
            host = process.env.VUE_APP_API_NODE_PROD;
        }

        return new Promise((res) => {
            axios
                .post(host + '/api/reset-session', params, { headers: headers })
                .then((response) => {
                    res(response.data);
                });
        });
    }