<template>
  <div>
  <v-list two-line color="transparent pl-4 pr-3">
    <template v-for="(item, index) in items">
      <v-subheader
        v-if="item.header"
        :key="index"
        v-text="$t(item.header)"
      ></v-subheader>

      <!-- <v-divider
        v-else-if="item.divider"
        :key="index"
        :inset="item.inset"
      ></v-divider> -->
      <v-card
        v-else
        class="elevation-0 rounded-xl mb-2"
        :key="index"
        width="100%"
      >
        <v-list-item>
          <v-list-item-avatar>
            <v-icon
              class="grey lighten-2"
              :color="item.color"
              dark
              v-text="item.icon"
            ></v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>

            <v-list-item-subtitle
              v-show="!['Tienes procesos por autorizar'].includes(item.title)"
              class="text-left"
            >
              <b> {{ item.type }} </b>
              - {{ item.subtitle }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action class="py-2">
            <v-list-item-action-text
              v-show="!['Tienes procesos por autorizar'].includes(item.title)"
              v-text="item.action"
            ></v-list-item-action-text>
            <div class="center-item-in-div">
              <v-btn
                v-if="['Tienes procesos por autorizar'].includes(item.title)"
                elevation="0"
                small
                class="rounded-pill"
                color="cyan darken-1"
                dark
                @click="buscaAccion(item.title)"
              >
                <v-icon dark small>mdi-eye</v-icon>
              </v-btn>
              <v-btn
                v-else-if="item.title == 'Pago de PTU'"
                elevation="0"
                small
                class="rounded-pill"
                color="cyan darken-1"
                dark
                @click="verRecuentoPTU = true"
              >
                <v-icon dark small>mdi-eye</v-icon>
              </v-btn>
              <v-btn
                v-else-if="item.title == 'Aclaración de nómina respondida'"
                elevation="0"
                small
                class="rounded-pill"
                color="cyan darken-1"
                dark
                @click="openAdmonAclaraciones = true"
              >
                Evaluar
              </v-btn>
              <v-btn
                v-else-if="item.title == 'Inconformidad'"
                elevation="0"
                small
                class="rounded-pill"
                color="cyan darken-1"
                dark
                @click="openEvaluacionInconformidad = true"
              >
                Evaluar
              </v-btn>
              <v-btn v-else icon color="pink" @click="removeNotification(item)">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
          </v-list-item-action>
        </v-list-item>
      </v-card>
    </template>

    <!-- <v-sheet id="autorizar-incidencias">
      <persona-autorizar-incidencias
        :dialog="autorizarIncidenciasVisible"
        @closed="autorizarIncidenciasVisible = false"
      />
    </v-sheet> -->

    <!-- <PersonaAutorizaVacantes
      v-if="autorizarVacantesVisible"
      :showAuthVacancy="autorizarVacantesVisible"
      @closeAutVacantes="autorizarVacantesVisible = false"
    /> -->

    <PersonaAdministracionAutorizaciones
      v-if="openAdmonAutorizaciones"
      :numIncidencias="1"
      :numVacantes="1"
      :numVisitas="1"
      :muestraAdmAutorizaciones="openAdmonAutorizaciones"
      @cierraDialogAutorizaciones="openAdmonAutorizaciones = false"
    />

    <v-sheet>
      <mi-nomina-admon-aclaraciones
        v-if="openAdmonAclaraciones"
        :dialog="openAdmonAclaraciones"
        @cierraAdmonAclaraciones="openAdmonAclaraciones = false"
      />
    </v-sheet>
    <PersonaRecuentoDiasPTU
      :verRecuentoPTU="verRecuentoPTU"
      @closeRecuentoPTU="verRecuentoPTU = false"
    />
    <lista-inconformidades-colaborador
      v-if="openEvaluacionInconformidad"
      :dialog="openEvaluacionInconformidad"
      ruta="notificacion"
      @cierraReporteInconformidades="cerrarReporte"
    >
    </lista-inconformidades-colaborador>
  </v-list>
  </div>
</template>
<script>
// import PersonaAutorizarIncidencias from "./PersonaAutorizarIncidencias.vue";
import PersonaAdministracionAutorizaciones from "../../../../../components/PersonaAdministracionAutorizaciones.vue";
import PersonaRecuentoDiasPTU from "./PersonaRecuentoDiasPTU.vue";
import MiNominaAdmonAclaraciones from "../../../../../components/componentsNomina/MiNominaAdmonAclaraciones.vue";
import ListaInconformidadesColaborador from '../../../AdmGeneral/Inconformidades/components/ListaInconformidadesColaborador.vue';

export default {
  data: () => ({
    name: "persona-notificaciones",
    autorizarIncidenciasVisible: false,
    autorizarVacantesVisible: false,
    openAdmonAclaraciones: false,
    openAdmonAutorizaciones: false,
    verRecuentoPTU: false,
    openEvaluacionInconformidad: false,
  }),
  components: {
    // PersonaAutorizarIncidencias,
    PersonaAdministracionAutorizaciones,
    PersonaRecuentoDiasPTU,
    MiNominaAdmonAclaraciones,
    ListaInconformidadesColaborador,
  },
  props: ["items"],
  mounted() {},
  methods: {
    cerrarReporte(response) {
      console.log(response);
      this.openEvaluacionInconformidad = false;
    },
    abrirPantallaIncidenciasPorAutorizar() {
      this.autorizarIncidenciasVisible = true;
    },
    buscaAccion(title) {
      switch (title) {
        case "Tienes procesos por autorizar":
          this.openAdmonAutorizaciones = true;
          break;
      }
    },
    removeNotification(item) {
      let indice = this.items.indexOf(item);
      this.items.splice(indice, 1);
    },
  },
};
</script>
