<template>
  <v-dialog v-model="muestraDetalles" max-width="780px" persistent>
    <v-card class="rounded-lg">
      <v-card-title class="primary">
        <span class="subtitle-1 white--text font-weight-bold"
          >Detalle de incidencias</span
        >
        <v-spacer></v-spacer>
        <v-icon dark @click="$emit('cierraTabla')"> mdi-close </v-icon>
      </v-card-title>
      <v-card-text>
        <v-container>
          <!-- <p>{{ alertErrorDosDias }}</p> -->
          <v-data-table
            v-if="!alertErrorDosDias"
            :headers="headers"
            :items="datosTabla"
            :items-per-page="5"
            dense
            class="elevation-1 rounded-lg"
            hide-default-footer
          ></v-data-table>
          <p v-else class="text-justify font-weight-bold">
            Debido a las necesidades de la empresa y para mejorar la
            productividad, únicamente puedes solicitar dos días por semana. Si
            requieres solicitar más días. Te invitamos a solicitar Teletrabajo
            con el equipo de DH, puedes buscar a tu generalista.
          </p>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "muestra-incidencias-cargadas",
  data: () => ({
    headers: [
      {
        text: "Incidencia",
        align: "start",
        value: "descIncidencia",
        class:
          "primary white--text font-weight-bold text-uppercase rounded-tl-lg",
      },
      {
        text: "Día de registro",
        value: "FLARGA",
        class: "primary white--text font-weight-bold text-uppercase",
      },
      {
        text: "Estatus",
        value: "estatusDesc",
        class: "primary white--text font-weight-bold text-uppercase",
      },
      
    ],
    items: [],
  }),
  props: ["muestraDetalles", "datosTabla", "alertErrorDosDias"],
  mounted() {
    if(this.datosTabla[0].descIncidencia !== 'Tiempo por tiempo'){
      this.headers.push({
        text: "Inicio",
        value: "fechaInIncidencia",
        class: "primary white--text font-weight-bold text-uppercase",
      });
      this.headers.push({
        text: "Fin",
        value: "fechaFiIncidencia",
        class:
          "primary white--text font-weight-bold text-uppercase rounded-tr-lg",
      });
    } else {
      this.headers.push({
        text: "Descansa",
        value: "fechaInIncidencia",
        class: "primary white--text font-weight-bold text-uppercase",
      });
      this.headers.push({
        text: "Trabaja",
        value: "fechaFiIncidencia",
        class:
          "primary white--text font-weight-bold text-uppercase rounded-tr-lg",
      });
    }
  },
  methods: {},
};
</script>
