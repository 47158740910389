<template>
  <v-app>
    <template v-if="$route.meta.requiresAuth">
      <persona-noticias-dialog
        v-model="noticiasDialogVisible"
        :noticias="noticiasList"
      />
      <Header @toggle-drawer="$refs.drawer.drawer = !$refs.drawer.drawer" />
      <Navbar ref="drawer" :key="$route.path" />
      <v-tooltip
        left
        color="primary"
        v-if="$vuetify.breakpoint.name != 'xs' && $store.getters.getUser['compania'] === '00100'"
      >
        <template v-slot:activator="{ on, attrs }"
          >'
          <v-btn
            class="flicker alu-position rounded-lg rounded-b-0"
            small
            color="primary"
            v-bind="attrs"
            v-on="on"
            @click="dialogAlu2 = true"
          >
            <v-badge color="pink" content="2" top left>
              <v-icon left>mdi-chat</v-icon>
            </v-badge>
            alu
          </v-btn>
        </template>
        <span>¡Chatea con ALU! {{ String.fromCodePoint(0x1f916) }}</span>
      </v-tooltip>
      <ALU
        v-if="dialogAlu2"
        :verCajaDeAhorro="dialogAlu2"
        @cierraCA="dialogAlu2 = false"
      />
    </template>
    <template v-else>
      <v-main class="overflow-y-auto" id="site-content">
        <router-view />
      </v-main>
    </template>
    <ErrorSnackbar />
  </v-app>
</template>

<script>
import axios from "axios";

import Header from "./components/Header.vue";
import Navbar from "./components/Navbar.vue";
import ErrorSnackbar from "./components/ErrorRequestSnackbar";

import PersonaNoticiasDialog from "./components/PersonaNoticiasDialog.vue";
import ALU from "./components/PersonaCajaDeAhorro.vue";

export default {
  name: "App",
  components: {
    Header,
    Navbar,
    ErrorSnackbar,
    PersonaNoticiasDialog,
    ALU,
  },
  data: () => ({
    noticiasDialogVisible: false,
    noticiasList: [],
    key: 0,
    dialogAlu2: false,
  }),
  mounted() {
    // this.retrieveNotices()
  },
  methods: {
    retrieveNotices() {
      axios.get(`./datasets/notices.json`).then((response) => {
        if (response.data.length > 0) {
          this.noticiasList = response.data;
        }
      });
    },
  },
};
</script>
<style lang="scss">
@import "@/styles/global";
</style>
