var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","max-width":"950"},model:{value:(_vm.verRecuentoPTU),callback:function ($$v) {_vm.verRecuentoPTU=$$v},expression:"verRecuentoPTU"}},[_c('v-card',{staticClass:"rounded-lg"},[_c('v-card-title',{staticClass:"primary white--text pb-5",class:_vm.$vuetify.breakpoint.name === 'xs' ? 'subtitle-2' : 'subtitle-1'},[_vm._v(" Revisión de días para PTU ("+_vm._s(_vm.$store.getters.getUser['Folio'])+") "),_c('v-spacer'),_c('v-icon',{attrs:{"dark":""},on:{"click":function($event){return _vm.$emit('closeRecuentoPTU')}}},[_vm._v(" mdi-close-thick ")])],1),_c('v-card-text',{staticClass:"backgroud-color-card-ptu pt-5"},[_c('v-card',{staticClass:"neumorphism-card-ptu rounded-xl pa-2",attrs:{"width":"100%","min-height":"80"}},[_c('v-data-table',{staticStyle:{"background-color":"transparent"},attrs:{"hide-default-footer":"","headers":_vm.headers,"items":_vm.items,"dense":"","loading":_vm.loadingData,"loading-text":"Obteniendo información...","no-data-text":"Sin información registrada."},scopedSlots:_vm._u([{key:"item.dias_ejercicio",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(item.dias_ejercicio)+" ")])]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"elevation-0",attrs:{"small":"","dark":"","color":"pink darken-3"}},[_vm._v(" "+_vm._s(item.total)+" ")])]}},{key:"item.dias_periodo",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"elevation-0",attrs:{"small":"","dark":"","color":"teal darken-1"}},[_vm._v(" "+_vm._s(item.dias_periodo)+" ")])]}},{key:"item.comentario",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.comentario == '' ? 'Entra a reparto de PTU.' : item.comentario)+" ")]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }