<template>
    <v-dialog v-model="verEncuestaBeneficios" fullscreen persistent>
        <v-card class="rounded-lg">
            <v-toolbar color="primary" dark>
                <v-row>
                    <v-col cols="2" style="margin-top: 12px">
                        <img src="https://desarrollohumano.urrea.com/IMAGENES/chat_urrea.png" width="40px"
                            height="40px" />
                    </v-col>
                    <v-col cols="10" style="margin-top: 5px">
                        <h3>¡Hola!</h3>
                        <p>Contesta esta encuesta.</p>
                    </v-col>
                </v-row>
            </v-toolbar>
            <v-card-text>
                <v-stepper v-model="step">
                    <v-stepper-header>
                        <v-stepper-step :complete="step > 1" step="1">Salud física, mental y social</v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step :complete="step > 2" step="2">Desarrollo personal, profesional y
                            educativo</v-stepper-step>
                    </v-stepper-header>

                    <v-stepper-items>
                        <v-stepper-content step="1">
                            <v-card v-for="(pregunta, index) in preguntas.slice(0, 4)" class="ma-1 pa-3 rounded-lg"
                                :key="pregunta.id" elevation="3">
                                <div class="font-weight-bold" v-html="formatQuestion(preguntas[index].pregunta)"></div>
                                <v-radio-group v-model="selectedAnswers[index]">
                                    <v-radio v-for="respuesta in pregunta.respuestas" :key="respuesta.id"
                                        :label="respuesta.respuesta" :value="respuesta.id">
                                    </v-radio>
                                </v-radio-group>
                            </v-card>
                            <v-btn color="primary" @click="nextStep" :disabled="!isStep1Complete">Continuar</v-btn>
                        </v-stepper-content>

                        <v-stepper-content step="2">
                            <v-card class="ma-2 pa-1 rounded-lg" elevation="3">
                                <div class="font-weight-bold" v-html="formatQuestion(preguntas[4].pregunta)"></div>
                                <v-checkbox class="ma-0" @change="checkSelection" v-model="multipleAnswers5"
                                    :label="preguntas[4].respuestas[0].respuesta"
                                    :value="preguntas[4].respuestas[0].id">
                                </v-checkbox>
                                <v-checkbox class="ma-0" @change="checkSelection" v-model="multipleAnswers5"
                                    :label="preguntas[4].respuestas[1].respuesta"
                                    :value="preguntas[4].respuestas[1].id">
                                </v-checkbox>
                                <v-checkbox class="ma-0" @change="checkSelection" v-model="multipleAnswers5"
                                    :label="preguntas[4].respuestas[2].respuesta"
                                    :value="preguntas[4].respuestas[2].id">
                                </v-checkbox>
                                <v-checkbox class="ma-0" @change="checkSelection" v-model="multipleAnswers5"
                                    :label="preguntas[4].respuestas[3].respuesta"
                                    :value="preguntas[4].respuestas[3].id">
                                </v-checkbox>
                                <v-checkbox class="ma-0" @change="checkSelection" v-model="multipleAnswers5"
                                    :label="preguntas[4].respuestas[4].respuesta"
                                    :value="preguntas[4].respuestas[4].id">
                                </v-checkbox>
                                <v-checkbox class="ma-0" @change="checkSelection" v-model="multipleAnswers5"
                                    :label="preguntas[4].respuestas[5].respuesta"
                                    :value="preguntas[4].respuestas[5].id">
                                </v-checkbox>
                            </v-card>
                            <v-card class="ma-2 pa-3 rounded-lg" elevation="3">
                                <div class="font-weight-bold">{{ preguntas[5].pregunta }}</div>
                                <v-radio-group v-model="selectedAnswers[5]" @change="updateShowCard">
                                    <v-radio v-for="respuesta in preguntas[5].respuestas" :key="respuesta.id"
                                        :label="respuesta.respuesta" :value="respuesta.id">
                                    </v-radio>
                                </v-radio-group>
                            </v-card>
                            <v-card v-if="showCard" class="ma-2 pa-3 rounded-lg" elevation="3">
                                <div class="font-weight-bold">{{ preguntas[6].pregunta }}</div>
                                <v-radio-group v-model="selectedAnswers[6]">
                                    <v-radio v-for="respuesta in preguntas[6].respuestas" :key="respuesta.id"
                                        :label="respuesta.respuesta" :value="respuesta.id">
                                    </v-radio>
                                </v-radio-group>
                            </v-card>
                            <v-card class="ma-2 pa-3 rounded-lg" elevation="3">
                                <div class="font-weight-bold">{{ preguntas[7].pregunta }}</div>
                                <v-checkbox class="ma-0" v-model="multipleAnswers8"
                                    :label="preguntas[7].respuestas[0].respuesta" :value="preguntas[7].respuestas[0].id"
                                    @change="toggleTextField" :disabled="multipleAnswers8.includes(52)">
                                </v-checkbox>
                                <v-checkbox class="ma-0" v-model="multipleAnswers8"
                                    :label="preguntas[7].respuestas[1].respuesta" :value="preguntas[7].respuestas[1].id"
                                    @change="toggleTextField" :disabled="multipleAnswers8.includes(52)">
                                </v-checkbox>
                                <v-checkbox class="ma-0" v-model="multipleAnswers8"
                                    :label="preguntas[7].respuestas[2].respuesta" :value="preguntas[7].respuestas[2].id"
                                    @change="toggleTextField" :disabled="multipleAnswers8.includes(52)">
                                </v-checkbox>
                                <v-checkbox class="ma-0" v-model="multipleAnswers8"
                                    :label="preguntas[7].respuestas[3].respuesta" :value="preguntas[7].respuestas[3].id"
                                    @change="toggleTextField" :disabled="multipleAnswers8.includes(52)">
                                </v-checkbox>
                                <v-checkbox class="ma-0" v-model="multipleAnswers8"
                                    :label="preguntas[7].respuestas[4].respuesta" :value="preguntas[7].respuestas[4].id"
                                    @change="toggleTextField" :disabled="multipleAnswers8.includes(52)">
                                </v-checkbox>
                                <v-checkbox class="ma-0" v-model="multipleAnswers8"
                                    :label="preguntas[7].respuestas[5].respuesta" :value="preguntas[7].respuestas[5].id"
                                    @change="toggleTextField" :disabled="multipleAnswers8.includes(52)">
                                </v-checkbox>
                                <v-checkbox class="ma-0" v-model="multipleAnswers8"
                                    :label="preguntas[7].respuestas[6].respuesta" :value="preguntas[7].respuestas[6].id"
                                    @change="toggleTextField" :disabled="multipleAnswers8.includes(52)">
                                </v-checkbox>
                                <v-checkbox class="ma-0" v-model="multipleAnswers8"
                                    :label="preguntas[7].respuestas[7].respuesta" :value="preguntas[7].respuestas[7].id"
                                    @change="toggleTextField" :disabled="multipleAnswers8.includes(52)">
                                </v-checkbox>
                                <v-checkbox class="ma-0" v-model="multipleAnswers8"
                                    :label="preguntas[7].respuestas[8].respuesta" :value="preguntas[7].respuestas[8].id"
                                    @change="toggleTextField" :disabled="multipleAnswers8.includes(52)">
                                </v-checkbox>
                                <v-checkbox class="ma-0" v-model="multipleAnswers8"
                                    :label="preguntas[7].respuestas[10].respuesta"
                                    :value="preguntas[7].respuestas[10].id" @change="toggleTextField"
                                    :disabled="multipleAnswers8.some(id => [30, 31, 32, 33, 34, 35, 36, 37, 38].includes(id))">
                                </v-checkbox>
                                <v-text-field v-if="showTextField" hint="* Obligatorio" persistent-hint dense
                                    label="¿Por qué sientes que tiene un impacto menor?"
                                    v-model="additionalValue"></v-text-field>
                            </v-card>
                            <v-card class="ma-2 pa-3 rounded-lg" elevation="3">
                                <div class="font-weight-bold">{{ preguntas[8].pregunta }}</div>
                                <v-checkbox class="ma-0" v-model="multipleAnswers9"
                                    :label="preguntas[8].respuestas[0].respuesta"
                                    :value="preguntas[8].respuestas[0].id">
                                </v-checkbox>
                                <v-checkbox class="ma-0" v-model="multipleAnswers9"
                                    :label="preguntas[8].respuestas[1].respuesta"
                                    :value="preguntas[8].respuestas[1].id">
                                </v-checkbox>
                                <v-checkbox class="ma-0" v-model="multipleAnswers9"
                                    :label="preguntas[8].respuestas[2].respuesta"
                                    :value="preguntas[8].respuestas[2].id">
                                </v-checkbox>
                                <v-checkbox class="ma-0" v-model="multipleAnswers9"
                                    :label="preguntas[8].respuestas[3].respuesta"
                                    :value="preguntas[8].respuestas[3].id">
                                </v-checkbox>
                                <v-checkbox class="ma-0" v-model="multipleAnswers9"
                                    :label="preguntas[8].respuestas[4].respuesta"
                                    :value="preguntas[8].respuestas[4].id">
                                </v-checkbox>
                                <v-checkbox class="ma-0" v-model="multipleAnswers9"
                                    :label="preguntas[8].respuestas[5].respuesta"
                                    :value="preguntas[8].respuestas[5].id">
                                </v-checkbox>
                                <v-checkbox class="ma-0" v-model="multipleAnswers9"
                                    :label="preguntas[8].respuestas[6].respuesta"
                                    :value="preguntas[8].respuestas[6].id">
                                </v-checkbox>
                                <v-checkbox class="ma-0" v-model="multipleAnswers9"
                                    :label="preguntas[8].respuestas[7].respuesta"
                                    :value="preguntas[8].respuestas[7].id">
                                </v-checkbox>
                                <v-checkbox class="ma-0" v-model="multipleAnswers9"
                                    :label="preguntas[8].respuestas[8].respuesta"
                                    :value="preguntas[8].respuestas[8].id">
                                </v-checkbox>
                                <v-checkbox class="ma-0" v-model="multipleAnswers9"
                                    :label="preguntas[8].respuestas[9].respuesta"
                                    :value="preguntas[8].respuestas[9].id">
                                </v-checkbox>
                                <v-checkbox class="ma-0" v-model="multipleAnswers9"
                                    :label="preguntas[8].respuestas[10].respuesta"
                                    :value="preguntas[8].respuestas[10].id">
                                </v-checkbox>
                            </v-card>
                            <v-btn color="primary" :loading="datosenviados" :disabled="!isStep2Complete"
                                @click="enviarRespuestas">Enviar</v-btn>
                            <v-btn text @click="prevStep">Regresar</v-btn>
                        </v-stepper-content>
                    </v-stepper-items>
                </v-stepper>
                <v-snackbar v-model="snackbar" :timeout="timeout" :color="color" shaped elevation="9">
                    {{ text }}

                    <template v-slot:action="{ attrs }">
                        <v-btn text v-bind="attrs" @click="snackbar = false, $emit('cierraBF')">
                            Cerrar
                        </v-btn>
                    </template>
                </v-snackbar>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import axios from "axios";

export default {
    name: "formulario-beneficios-flexibles",
    props: ["verEncuestaBeneficios"],
    data() {
        return {
            step: 1,
            preguntas: [],
            selectedAnswers: Array.from({ length: 9 }, () => null),
            multipleAnswers5: [],
            multipleAnswers8: [],
            multipleAnswers9: [],
            isStep1Complete: false, // indicador si el primer paso está completo
            maxSelected: 2,
            selectedCount: 0,
            snackbar: false,
            text: '',
            timeout: 3000,
            color: '',
            showCard: false,
            datosenviados: false,
            showTextField: false,
            additionalValue: null,
        };
    },
    computed: {
        isStep2Complete() {
            const hasSelectedValues = this.multipleAnswers8.some(id => [30, 31, 32, 33, 34, 35, 36, 37, 38].includes(id));
            const isAdditionalValueValid = !hasSelectedValues || (this.additionalValue !== null && this.additionalValue.length > 4);

            return isAdditionalValueValid &&
                this.selectedAnswers[5] !== null &&
                this.multipleAnswers5.length > 0 &&
                this.multipleAnswers8.length > 0 &&
                this.multipleAnswers9.length > 0;
        }

    },
    mounted() {
        this.getPreguntas();
    },
    watch: {
        selectedAnswers: {
            handler: function (newVal) {
                this.isStep1Complete = newVal.slice(0, 4).every(answer => answer !== null);
            },
            deep: true
        },
    },
    created() {
    },
    methods: {
        getPreguntas() {
            let params = {
                Opcion: 9,
                token: localStorage.getItem("token"),
                proyecto: 1,
            };
            axios
                .post(`${this.$store.getters.getHost}/Compensaciones/ws-beneficios-flexibles.php`, params)
                .then((response) => {
                    if (response.data.status == "OK") {
                        const data = response.data;
                        this.preguntas = data.preguntas;
                    }
                    else {
                        console.log('No cargo preguntas');
                    }
                })
                .catch(function (e) {
                    //
                    console.log(e);
                });
        },
        enviarRespuestas() {
            this.datosenviados = true;

            let params = {
                proyecto: 1,
                Opcion: 10,
                token: localStorage.getItem("token"),
                radios: this.selectedAnswers,
                ask5: this.multipleAnswers5,
                ask8: this.multipleAnswers8,
                ask9: this.multipleAnswers9,
                abierta: this.additionalValue,
            };
            axios
                .post(`${this.$store.getters.getHost}/Compensaciones/ws-beneficios-flexibles.php`, params)
                .then((response) => {
                    this.datosenviados = false;

                    if (response.data.status == "OK") {
                        this.snackbar = true;
                        this.text = 'Se ah registrado sus respuestas.';
                        this.color = "success"
                        setTimeout(() => {
                            this.$emit("cierraBF");
                        }, 3000); // 3 segundos
                    }
                    else {
                        this.snackbar = true;
                        this.text = 'No se ah registrado sus respuestas.';
                        this.color = "red accent-2";
                    }
                })
                .catch(function (e) {
                    //
                    console.log(e);
                });
        },
        nextStep() {
            this.step++;
        },
        prevStep() {
            this.step--;
        },
        formatQuestion(text) {
            return text.replace(/\*/g, '<br>');
        },
        updateShowCard() {
            this.showCard = this.selectedAnswers[5] === 23;
            if (this.selectedAnswers[5] === 24) {
                this.selectedAnswers[6] = null;
            }
        },
        checkSelection() {
            if (this.multipleAnswers5.length > this.maxSelected) {
                this.selectedCount = this.maxSelected;
                this.multipleAnswers5 = this.multipleAnswers5.slice(0, this.maxSelected);
                alert('Solo puedes seleccionar hasta dos respuestas');
            } else {
                this.selectedCount = this.multipleAnswers5.length;
            }

        },
        toggleTextField() {
            if (this.multipleAnswers8.includes(52) || this.multipleAnswers8.length == 0) {
                // Si se selecciona la opción específica, oculta el campo de texto y limpia su valor
                this.showTextField = false;
                this.additionalValue = null;
            } else if (!this.multipleAnswers8.includes(52) && this.multipleAnswers8.length > 0) {
                // Si se selecciona otra opción, muestra el campo de texto
                this.showTextField = true;
            }
        }

    }
}
</script>

<style scoped>
.containerChat {
    background: white;
    height: 65vh;
    overflow: auto;
    transition: all 1.5s;
}

.v-dialog__content {
    align-items: end !important;
    display: flex;
    height: 100%;
    justify-content: end !important;
    left: 0;
    pointer-events: none;
    position: fixed;
    top: 0;
    transition: 0.2s cubic-bezier(0.25, 0.8, 0.25, 1), z-index 1ms;
    width: 100%;
    z-index: 6;
    outline: none;
}

.mi-boton .v-btn__content {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 768px) {
    .containerChat {
        background: white;
        height: calc(100vh - 120px);
        overflow: auto;
    }
}
</style>