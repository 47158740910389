<template>
  <div>
    <!-- red accent-2 -->
    <v-snackbar
      v-model="muestraBarra"
      :color="color"
      rounded="lg"
      elevation="24"
    >
      <div class="caption font-weight-bold mb-2">{{ texto }}</div>
      <v-progress-linear rounded :value="valor" color="white"></v-progress-linear>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  name: "snack-bar-error-incidencias",
  data: () => ({
    valor: 0
  }),
  props: ["muestraBarra", "texto", "color"],
  computed: {},
  mounted() {
    if (this.muestraBarra) {
      this.actualizarValor();
    }
  },
  watch: {
    muestraBarra(nuevoValor) {
      if (nuevoValor) {
        this.actualizarValor();
      }
    },
  },
  methods: {
    actualizarValor() {
      const totalTiempo = 2000; // tiempo total en milisegundos
      const intervaloTiempo = 100; // intervalo de tiempo entre iteraciones en milisegundos
      const incremento = (100 / totalTiempo) * intervaloTiempo; // incremento por iteración
      
      if (this.valor <= 100) {
        setTimeout(() => {
          this.valor += incremento;
          this.actualizarValor();
        }, intervaloTiempo);
      } else {
        this.$emit('cierraBarra');
      }
    },
  },
};
</script>


<style>
.back-button {
  background: rgb(15, 89, 136);
  background: radial-gradient(
    circle,
    rgba(15, 89, 136, 1) 0%,
    rgba(17, 43, 83, 0.8211485277704832) 100%
  );
}
</style>
