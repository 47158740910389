import { tokenFreePaths } from '../config/request.js'

export function initialize(axios) {

    axios.interceptors.request.use(
        function(request) {
            let backendRequest = request.url.includes(':8000');
            let tokenFree = tokenFreePaths.some(path => request.url.includes(path))

            if (backendRequest && !tokenFree) {
                request.headers['X-AUTH-TOKEN'] = localStorage.getItem('token');
            }
            return request;
        }
    );

}

export function errors(axios, store) {

    axios.interceptors.request.use(
        function(request) {
            return request;
        },
        function(error) {
            if (error.response) store.commit('setSnackError', error.response.data.error.message);
            return Promise.reject(error);
        }
    );
    axios.interceptors.request.use(
        function(config) {
            return config;
        },
        function(error) {
            if (error.response) store.commit('setSnackError', error.response.data.error.message);
            return Promise.reject(error);
        }
    );
    axios.interceptors.response.use(
        function(response) {
            return response;
        },
        // function(error) {
        //     if (error.response) store.commit('setSnackError', error.response.data.error.message);
        //     return Promise.reject(error);
        // }
    );

}